import * as React from 'react';
import { OrderService } from 'services';
import { Order } from 'interfaces';
import { SnackbarContext } from 'components';
import { format } from 'date-fns';
import { parseArgsDate } from 'lib';
import { DateTimeFormat } from 'config';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    border: '1px solid black',
    paddingLeft: theme.spacing(4),
  },
  bar: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(4),
  },

  space: {
    padding: theme.spacing(4),
  },
  tableCell: {
    padding: '0px',
  },
  textAlign: {
    textAlign: 'center',
    padding: theme.spacing(1.2),
  },
}));

interface Props {
  orderId?: number;
}

export const PrintOrderReceipt: React.FC<Props> = ({ orderId }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>();
  const snackbar = React.useContext(SnackbarContext);

  const loadOrders = React.useCallback(
    async function loadOrders(id: string) {
      try {
        const response = await OrderService.getById(id);
        setOrder(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    if (orderId) {
      loadOrders(String(orderId));
    }
  }, [orderId, loadOrders]);

  const tableData = order?.orderItems.map((oItem, i) => (
    <TableRow key={i}>
      <TableCell className={classes.tableCell}>
        {oItem.menuItem.title} <br /> {oItem.notes}
      </TableCell>
      <TableCell align="right" className={classes.tableCell}>
        {oItem.quantity}
      </TableCell>
      <TableCell align="right" className={classes.tableCell}>
        {parseFloat(
          (oItem.itemCostIncludingVat - oItem.vat).toString(),
        ).toFixed(2)}
      </TableCell>
    </TableRow>
  ));

  return (
    <div className={classes.space}>
      <div className={classes.textAlign}>
        <Typography variant="h5">
          Order - {order?.orderNumber ?? 'N/A'}
        </Typography>
      </div>
      <div className={classes.bar}>
        <Typography variant="h6">
          {order?.futureOrder
            ? 'SCHEDULE - ' +
              format(
                parseArgsDate(order?.futureOrder.deliveryAt),
                DateTimeFormat.LONG,
              )
            : 'ASAP'}
        </Typography>
      </div>
      <br />
      <div className={classes.bar}>
        <Typography variant="h6">
          {order?.pickUp ? 'Pickup' : 'Delivery'}
        </Typography>
      </div>

      <div className={classes.box}>
        <Typography variant="subtitle1">
          {order?.pickUp ? order?.pickUpComments : order?.address}
        </Typography>
      </div>

      <Typography variant="h6">Client info:</Typography>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tableCell}>First name</TableCell>
            <TableCell align="right" className={classes.tableCell}>
              {order?.customer?.firstName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Last name</TableCell>
            <TableCell align="right" className={classes.tableCell}>
              {order?.customer?.lastName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Email</TableCell>
            <TableCell align="right" className={classes.tableCell}>
              {order?.customer?.emailAddress}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCell}>Phone</TableCell>
            <TableCell align="right" className={classes.tableCell}>
              {order?.customer?.phoneNumber}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <br />
      <Typography variant="h6">Items:</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData}
          <TableRow>
            <TableCell rowSpan={3} className={classes.tableCell} />
            <TableCell colSpan={1} className={classes.tableCell}>
              Subtotal
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
              {order?.totalCostExcludingVat}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} className={classes.tableCell}>
              Tax 18%
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
              {order?.vat}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} className={classes.tableCell}>
              Total in EUR
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
              {(
                parseFloat((order?.totalCostExcludingVat ?? 0).toString()) +
                parseFloat((order?.vat ?? 0).toString())
              ).toFixed(2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <div className={classes.box}>
        <Typography variant="subtitle1">
          Order online: pekingmalta.com
        </Typography>
      </div>

      <br />
      <div className={classes.bar}>
        <Typography variant="h6">CASH</Typography>
      </div>

      <Typography variant="subtitle1">
        Peking Restaurant, Triq it turisti, Qawra, QWR0005, +35621580696
      </Typography>
    </div>
  );
};
