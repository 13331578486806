import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppTabs } from '../../components';
import { MenuTab } from './components';
import { SetMenuTab } from './components/SetMenuTab';

export enum MenuTabList {
  MENU = 'menu',
  SET_MENU = 'set_menu',
}

export const Menus: React.FC<RouteComponentProps<{ id?: string }>> = (
  props,
) => {
  const tabs = [
    { label: 'Menu', value: MenuTabList.MENU },
    { label: 'Set Menu', value: MenuTabList.SET_MENU },
  ];

  return (
    <AppTabs tabs={tabs}>
      {(activeTab) => {
        switch (activeTab) {
          case MenuTabList.MENU:
            return <MenuTab {...props} />;
          case MenuTabList.SET_MENU:
            return <SetMenuTab {...props} />;
          default:
        }
      }}
    </AppTabs>
  );
};
