import { Typography, Paper, Toolbar, Button } from '@material-ui/core';
import * as React from 'react';
import { Shop } from 'interfaces';
import { DelayedLinearProgress, SortableTable, RouterLink, TableControls, SnackbarContext } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { ShopService } from 'services';
import { SaveShopOpeningHoursDialog } from '../components/SaveShopOpeningHoursDialog';

const columns: SortableTableHeader[] = [
  { key: 'name', label: 'Shop' },
  { key: 'controls', sortable: false },
];

export const ShopsView: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [shops, setShops] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedShop, setSelectedShop] = React.useState<Shop>();
  const snackbar = React.useContext(SnackbarContext);

  const loadShops = React.useCallback(
    async function loadShops() {
      try {
        setLoading(true);
        const response = await ShopService.getShops()
        setShops(response);
      } catch (error) {
        snackbar.error(error);
      } finally {
        setLoading(false);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadShops();
  }, [loadShops]);

  const rows = shops.map((shop: Shop) => ({
    key: shop.id.toString(),
    cells: [
      {
        key: 'title',
        display: (
          <Button
            component={RouterLink}
            to={`/shops/${shop.id.toString()}/locations`}
            variant="text"
            size="small"
            color="secondary"
          >
            {shop.title}
          </Button>
        ),
        sortValue: shop.title,
      },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={(e) => {
              setModalOpen(true);
              setSelectedShop(shop);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      <DelayedLinearProgress loading={loading} />
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Shops Table</Typography>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          loading={loading}
          emptyTableText="No shops available yet."
          disablePagination
        />

        {selectedShop ?<SaveShopOpeningHoursDialog
          shop={selectedShop}
          refresh={() => loadShops()}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'md',
          }}
        /> : null}
      </Paper>
    </React.Fragment>
  );
};
