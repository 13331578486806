import * as React from 'react';
import { AppTabs } from '../../components';
import { GalleryTab } from './components';

interface Props {}

export enum WebsiteTabList {
  GALLERY = 'gallery',
}

export const Website: React.FC<Props> = () => {
  const tabs = [{ label: 'Gallery', value: WebsiteTabList.GALLERY }];

  return (
    <AppTabs tabs={tabs}>
      {(activeTab) => {
        switch (activeTab) {
          case WebsiteTabList.GALLERY:
            return <GalleryTab />;
          default:
        }
      }}
    </AppTabs>
  );
};
