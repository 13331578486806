import { Typography, Paper, Toolbar, Button } from '@material-ui/core';
import * as React from 'react';
import { Report } from 'interfaces';
import { DelayedLinearProgress, SortableTable, RouterLink } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { ReportService } from 'services';

const columns: SortableTableHeader[] = [
  { key: 'title', label: 'Report Title' },
];

export const ReportListView: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [reports, setReports] = React.useState<Report[]>([]);

  React.useEffect(() => {
    ReportService.getAllReports().then((response) => {
      setReports(response);
      setLoading(false);
    });
  }, []);

  const rows = reports.map((report: Report) => ({
    key: report.id.toString(),
    cells: [
      {
        key: 'title',
        display: (
          <Button
            component={RouterLink}
            to={`/report/${report.title.toLowerCase().replace(/ /g, '-')}`}
            variant="text"
            size="small"
            color="secondary"
            style={{ minWidth: 'auto' }}
          >
            {report.title}
          </Button>
        ),
        sortValue: report.title,
      },
    ],
  }));

  return (
    <React.Fragment>
      <DelayedLinearProgress loading={loading} />
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Reports Table</Typography>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          loading={loading}
          emptyTableText="No reports available yet."
          disablePagination
        />
      </Paper>
    </React.Fragment>
  );
};
