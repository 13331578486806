import { ApiClient } from 'ApiClient';
import { FutureOrdersResponse, Order, PendingItem } from 'interfaces';

class OrderService {
  public async getAll(): Promise<Order[]> {
    return ApiClient.get<Order[]>('orders');
  }

  public async getById(id: string): Promise<Order> {
    return ApiClient.get<Order>(`orders/${id}`);
  }

  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.put(`orders/${id}`, data);
    }

    return ApiClient.post('orders', data);
  }

  public delete(id: number) {
    return ApiClient.delete(`orders/${id}`);
  }

  public canOrderBeUpdated(id?: number) {
    return ApiClient.get(`orders/can-order-be-updated/${id}`);
  }

  public setPickUp(id: number) {
    return ApiClient.put(`orders/set-pick-up/${id}`);
  }

  public cancelJob(id: number) {
    return ApiClient.put(`orders/cancel-job/${id}`);
  }

  public packOrderItem(data: {
    action: string;
    orderId: number;
    menuItemId: number;
  }) {
    return ApiClient.post('orders/pack-order-item', data);
  }

  public getOrdersWaitingForDelivery(): Promise<Order[]> {
    return ApiClient.get<Order[]>('orders/waiting-for-delivery');
  }

  public getOrdersForPickUp(): Promise<Order[]> {
    return ApiClient.get<Order[]>('orders/items-for-pickup');
  }

  public getOrderItemsForPrepartion(): Promise<PendingItem[]> {
    return ApiClient.get<PendingItem[]>('orders/items-not-picked');
  }

  public generateReport(id: number): Promise<Order> {
    return ApiClient.get<Order>(`orders/generate-report/${id}`);
  }

  public prepareNumberOfBags(id: number, data: any) {
    return ApiClient.put(`orders/prepare-order-bags/${id}`, data);
  }

  public getFutureOrdersCount(scheduledData: Date) {
    const month =
      scheduledData.getMonth() + 1 < 10
        ? `0${scheduledData.getMonth() + 1}`
        : scheduledData.getMonth() + 1;

    const date =
      scheduledData.getDate() < 10
        ? `0${scheduledData.getDate()}`
        : scheduledData.getDate();

    const hour =
      scheduledData.getHours() < 10
        ? `0${scheduledData.getHours()}`
        : scheduledData.getHours();

    const minutes =
      scheduledData.getMinutes() < 10
        ? `0${scheduledData.getMinutes()}`
        : scheduledData.getMinutes();

    return ApiClient.get<FutureOrdersResponse>(
      `orders/get-future-orders/${scheduledData.getFullYear()}/${month}/${date}/${hour}/${minutes}`,
    );
  }

  public getFutureOrders() {
    return ApiClient.get<Order[]>(`orders/get-future-orders`);
  }
}

const singleton = new OrderService();

export { singleton as OrderService };
