import {
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import { DelayedLinearProgress, ResponsiveIconButton } from 'components';
import { useLocalStorageSettings } from 'hooks';
import { PendingItem } from 'interfaces';
import * as React from 'react';
import { OrderService } from 'services';
import { PendingOrderCard } from './components';
import RefreshIcon from '@material-ui/icons/Refresh';

export const PendingItemView: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [starredOnly, setStarredOnly] = React.useState(false);
  const [items, setItems] = React.useState<
    { orderId: number; starred: boolean; items: PendingItem[] }[]
  >([]);
  const [savedStarred, setSavedStarred] = useLocalStorageSettings<number[]>(
    'starred_orders',
    [],
  );

  const loadOrderItems = React.useCallback(
    function loadOrderItems() {
      setLoading(true);
      Promise.all([OrderService.getOrderItemsForPrepartion()])
        .then(([orderResponse]) => {
          const orders = orderResponse.reduce<
            { orderId: number; starred: boolean; items: PendingItem[] }[]
          >((prev, curr) => {
            let orderIndex = prev.findIndex((p) => p.orderId === curr.orderId);

            const splitItems =
              curr.quantity > 1
                ? Array.from(Array(curr.quantity).keys()).map((c) => ({
                    ...curr,
                    quantity: 1,
                  }))
                : [curr];

            if (orderIndex === -1) {
              return [
                ...prev,
                {
                  orderId: curr.orderId,
                  starred: savedStarred.includes(curr.orderId),
                  items: [...splitItems],
                },
              ];
            } else {
              prev[orderIndex].items.push(...splitItems);
            }

            return prev;
          }, []);

          setItems(orders);
        })
        .finally(() => setLoading(false));
    },
    [savedStarred],
  );

  React.useEffect(() => {
    loadOrderItems();
  }, [loadOrderItems]);

  return (
    <React.Fragment>
      <Paper
        style={{
          padding: 16,
          marginBottom: 16,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ResponsiveIconButton
          onClick={(e) => {
            loadOrderItems();
          }}
          color="primary"
          icon={RefreshIcon}
        >
          Refresh Orders
        </ResponsiveIconButton>

        <Typography variant="subtitle2" color="inherit">
          Number of orders to pack: {items.length}
        </Typography>
        <FormControlLabel
          control={
            <Switch
              value="starredOnly"
              checked={starredOnly}
              onChange={(evt, checked) => setStarredOnly(checked)}
            />
          }
          label="Show Starred Only"
        />
      </Paper>

      {loading && <DelayedLinearProgress />}
      <Grid container>
        {items
          .filter((i) => !starredOnly || i.starred === starredOnly)
          .map((order, index) => (
            <PendingOrderCard
              key={order.orderId}
              order={order}
              onPackOrder={(orderId: number) => {
                let newVal = items.slice();

                newVal = newVal.filter((v) => v.orderId !== orderId);

                setItems(newVal);
              }}
              toggleStarred={(orderId: number) => {
                const newVal = items.slice();
                const orderIndex = newVal.findIndex(
                  (v) => v.orderId === orderId,
                );

                if (orderIndex !== -1) {
                  newVal[orderIndex].starred = !newVal[orderIndex].starred;
                }

                setItems(newVal);

                setSavedStarred(
                  newVal.filter((v) => v.starred).map((v) => v.orderId),
                );
              }}
            />
          ))}
      </Grid>
    </React.Fragment>
  );
};
