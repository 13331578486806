import { useGlobalState } from 'App.state';
import { AppLayout, AuthLayout, MenuLayout } from 'layouts';
import * as React from 'react';

export const LayoutSwitcher: React.FC = () => {
  const [authUser] = useGlobalState('authUser');

  const isDisplayMenuPage =
    window.location.pathname.indexOf('/menu/display/') !== -1;

  return authUser !== null ? (
    isDisplayMenuPage ? (
      <MenuLayout />
    ) : (
      <AppLayout />
    )
  ) : (
    <AuthLayout />
  );
};
