import { Typography, Paper, Toolbar } from '@material-ui/core';
import * as React from 'react';
import { User } from 'interfaces';
import {
  DelayedLinearProgress,
  AddUserDialog,
  ConfirmDialog,
  TableControls,
  ResponsiveIconButton,
  SaveDriverCashFlowDialog,
  SortableTable,
} from 'components';
import AddIcon from '@material-ui/icons/Add';
import { UserService } from 'services/UserService';
import { SortableTableHeader } from 'components/SortableTable/components';
import { UserTypes } from 'enums';

const columns: SortableTableHeader[] = [
  { key: 'name', label: 'Name' },
  { key: 'surname', label: 'Surname' },
  { key: 'email_address', label: 'Email' },
  { key: 'user_type', label: 'User Type' },
  { key: 'constrols', sortable: false },
];

export const Users: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDriverCashFlow, setDriverCashFlow] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [editUser, setEditUser] = React.useState<User>();
  const [deleteUser, setDeleteUser] = React.useState<User>();
  const [selectedUser, setSelectedUser] = React.useState<User>();

  React.useEffect(() => {
    getUsers();
  }, []);

  const rows = users.map((user: User) => ({
    key: user.id.toString(),
    cells: [
      { key: 'name', display: user.name },
      { key: 'surname', display: user.surname },
      { key: 'email_address', display: user.emailAddress },
      { key: 'user_type', display: user.userType.name },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={() => {
              setEditUser(user);
              setOpenEdit(true);
            }}
            onDelete={() => {
              setOpenConfirm(true);
              setDeleteUser(user);
            }}
            onDriverCashFlow={
              user.userType && user.userType.id === UserTypes.DRIVER
                ? () => {
                    setSelectedUser(user);
                    setDriverCashFlow(true);
                  }
                : undefined
            }
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      <DelayedLinearProgress loading={loading} />
      <Paper style={{ overflow: 'auto' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Users</Typography>
          <ResponsiveIconButton
            onClick={() => setOpen(true)}
            color="primary"
            icon={AddIcon}
          >
            Add User
          </ResponsiveIconButton>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          // loading={queryLoading}
          emptyTableText="No users available yet."
          emptyTableCreateAction={() => setOpen(true)}
          disablePagination
        />
      </Paper>
      <AddUserDialog
        loading={loading}
        dialogProps={{ open: open, onClose: () => setOpen(false) }}
        refresh={() => getUsers()}
        users={users}
      />
      <AddUserDialog
        loading={loading}
        dialogProps={{ open: openEdit, onClose: () => setOpenEdit(false) }}
        user={editUser}
        refresh={() => getUsers()}
        users={users}
      />
      <SaveDriverCashFlowDialog
        user={selectedUser}
        driverCashFlow={selectedUser?.driverCashFlow}
        dialogProps={{
          open: openDriverCashFlow,
          onClose: () => setDriverCashFlow(false),
          fullWidth: true,
          maxWidth: 'sm',
        }}
        loading={loading}
        refresh={() => getUsers()}
      />
      <ConfirmDialog
        title="Are you sure you want to delete this user?"
        dialogProps={{
          open: openConfirm,
          onClose: () => {
            setOpenConfirm(false);
            setDeleteUser(undefined);
          },
        }}
        onConfirm={() => {
          if (!deleteUser) return;
          UserService.deleteUser(deleteUser.id);
          getUsers();
        }}
      />
    </React.Fragment>
  );

  function getUsers() {
    UserService.getUsers().then((response) => {
      setUsers(response);
      setLoading(false);
    });
  }
};
