import { DialogProps, Grid, TextField } from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { MenuCategory } from 'interfaces';
import * as React from 'react';
import { MenuCategoryService } from 'services';

interface Props {
  dialogProps: DialogProps;
  menuCategory?: MenuCategory;
  categories: MenuCategory[];
  menuSectionId: number;
  refresh: () => void;
}

interface MenuCategoryForm {
  name?: string;
  rank?: number;
  menuSectionId: number;
}

export const SaveMenuCategoryDialog: React.FC<Props> = ({
  dialogProps,
  menuCategory,
  categories,
  menuSectionId,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);

  const getNextRank = React.useCallback(
    function getNextRank() {
      return (
        categories.reduce((prev, curr) => {
          if (curr.rank > prev) {
            return curr.rank;
          }

          return prev;
        }, 0) + 1
      );
    },
    [categories],
  );

  const getForm = React.useCallback(
    function getForm(menuCategory?: MenuCategory): MenuCategoryForm {
      return {
        name: menuCategory?.name ?? undefined,
        rank: menuCategory?.rank ?? getNextRank(),
        menuSectionId,
      };
    },
    [menuSectionId, getNextRank],
  );

  const [form, setForm] = React.useState(getForm(menuCategory));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(menuCategory));
    }
  }, [getForm, menuCategory, justOpened]);

  return (
    <FormDialog
      title={`${menuCategory ? 'Edit' : 'Add'} Menu Category`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Rank"
            type="number"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="rank"
            value={form.rank || ''}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const response = await MenuCategoryService.save({
        id: menuCategory?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Menu category has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
