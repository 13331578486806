import {
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { DialogControlProps, DialogControls } from 'components/DialogControls';
import { addMinutes, format, isAfter, isBefore, parse } from 'date-fns';
import { Shops } from 'enums';
import * as React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { DateFormat, TimeFormat } from 'config/DateFormat';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FutureOrdersResponse, OpeningHour } from 'interfaces';
import { Autocomplete } from '@material-ui/lab';
import { OrderService } from 'services';
import { OrderForm } from './SaveOrderDialog';

interface Props extends DialogControlProps {
  shopId?: number;
  processNow: boolean;
  pickUp: boolean;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  handleProcessNowChange: React.Dispatch<React.SetStateAction<boolean>>;
  handlePickUpChange: React.Dispatch<React.SetStateAction<boolean>>;
  openingHours: OpeningHour[];
  readonly?: boolean;
  deliveryAt?: Date;
  form: OrderForm;
  setForm: React.Dispatch<React.SetStateAction<OrderForm>>;
}

const MINIMUM_ADD_TIME = 90;
const MINUTES_GAP = 15;

export const ScheduleStep: React.FC<Props> = ({
  shopId,
  processNow,
  handleProcessNowChange,
  pickUp,
  handlePickUpChange,
  setDate,
  openingHours,
  readonly,
  deliveryAt,
  form,
  setForm,
  ...dialogControlProps
}) => {
  const [futureOrders, setFutureOrders] = React.useState<
    FutureOrdersResponse
  >();

  const [scheduledDate, setScheduledDate] = React.useState(
    getRoundedDate(MINUTES_GAP, addMinutes(new Date(), MINIMUM_ADD_TIME)),
  );
  setDate(scheduledDate);

  const [hour, setHour] = React.useState<number>(scheduledDate.getHours());
  const [minute, setMinute] = React.useState<number>(
    scheduledDate.getMinutes(),
  );

  const [hours, setHours] = React.useState<number[]>([]);
  const [minutes, setMinutes] = React.useState<number[]>([]);
  const [text, setText] = React.useState('Order was delivered at');

  const minimum = scheduledDate;

  React.useEffect(() => {
    if (processNow || readonly) {
      return;
    }

    let day = scheduledDate.getDay();

    if (day === 0) {
      // SUNDAY is 7 in ENUM but javascript getDay return 0, ENUM is used in dropdown, the value of  0 makes the dropdown have no value.
      day = 7;
    }

    const dayWithHours = openingHours.find((oh) => oh.dayOfWeek === day);

    if (!dayWithHours) {
      setHours([]);
      return;
    }

    const { fromTime, toTime } = dayWithHours;

    if (!fromTime || !toTime) {
      setHours([]);
      return;
    }

    const fromDate = getRoundedDate(
      MINUTES_GAP,
      addMinutes(
        parse(fromTime, TimeFormat.MYSQL_TIME, scheduledDate),
        MINIMUM_ADD_TIME,
      ),
    );

    const nextAvailableDelivery = getRoundedDate(
      MINUTES_GAP,
      addMinutes(new Date(), MINIMUM_ADD_TIME),
    );

    const toDate = parse(toTime, TimeFormat.MYSQL_TIME, scheduledDate);
    let startTime = isBefore(minimum, fromDate) ? fromDate : minimum;
    startTime = isBefore(nextAvailableDelivery, startTime)
      ? startTime
      : nextAvailableDelivery;
    const firstHour = startTime.getHours();
    const lastHour = toDate.getHours();

    const minutes = startTime.getMinutes();

    const newHours: number[] = [];
    const newMinutes: number[] = [];

    setHour(firstHour);
    setMinute(minutes);

    for (let index = firstHour; index <= lastHour; index++) {
      newHours.push(index);
    }

    for (let index = minutes; index <= 60 - MINUTES_GAP; index += MINUTES_GAP) {
      newMinutes.push(index);
    }

    setHours(newHours);
    setMinutes(newMinutes);
  }, [scheduledDate, minimum, openingHours, processNow, readonly]);

  React.useEffect(() => {
    if (processNow || readonly) {
      return;
    }

    const nextAvailableDelivery = getRoundedDate(
      MINUTES_GAP,
      addMinutes(new Date(), MINIMUM_ADD_TIME),
    );

    const newDay = scheduledDate;
    newDay.setHours(hour);
    newDay.setMinutes(0);

    const dayWithHours = openingHours.find(
      (oh) => oh.dayOfWeek === (newDay.getDay() === 0 ? 7 : newDay.getDay()),
    );

    if (!dayWithHours) {
      setMinutes([]);
      return;
    }

    const { fromTime, toTime } = dayWithHours;

    if (!fromTime || !toTime) {
      setMinutes([]);
      return;
    }

    const fromDate = getRoundedDate(
      MINUTES_GAP,
      addMinutes(
        parse(fromTime, TimeFormat.MYSQL_TIME, newDay),
        MINIMUM_ADD_TIME,
      ),
    );

    const toDate = parse(toTime, TimeFormat.MYSQL_TIME, newDay);
    let startTime = isBefore(newDay, fromDate) ? fromDate : newDay;
    startTime = isBefore(nextAvailableDelivery, startTime)
      ? startTime
      : nextAvailableDelivery;

    const firstMinutes = startTime.getMinutes();
    const lastHour = toDate.getHours();

    const newMinutes: number[] = [];

    setMinute(firstMinutes);

    const lastMinutes =
      lastHour !== hour ? 60 - MINUTES_GAP : toDate.getMinutes;

    for (let index = firstMinutes; index <= lastMinutes; index += MINUTES_GAP) {
      newMinutes.push(index);
    }

    setMinutes(newMinutes);
  }, [hour, minimum, openingHours, processNow, readonly, scheduledDate]);

  React.useEffect(() => {
    if (processNow || readonly) {
      return;
    }

    const postDate = scheduledDate;
    postDate.setHours(hour);
    postDate.setMinutes(minute);

    OrderService.getFutureOrdersCount(postDate).then((response) => {
      setFutureOrders(response);
    });
  }, [scheduledDate, hour, minute, processNow, readonly]);

  React.useEffect(() => {
    if (!readonly) {
      return;
    }

    if (!deliveryAt) {
      setText('Order is still being processed');
      return;
    }

    if (isAfter(deliveryAt, new Date())) {
      setText('Order will be delivered at');
    }

    setScheduledDate(deliveryAt);
    setHour(deliveryAt.getHours());
    setMinute(deliveryAt.getMinutes());
  }, [deliveryAt, readonly]);

  const workingDays = openingHours.map((oh) => oh.dayOfWeek);

  return (
    <React.Fragment>
      <DialogContent>
        <FormGroup row>
          {!readonly && (
            <FormControlLabel
              control={
                <Switch
                  disabled={shopId === Shops.STREET_FOOD}
                  checked={processNow}
                  onChange={(e, checked) => handleProcessNowChange(checked)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Process Order Now"
            />
          )}
          {!readonly && (
            <FormControlLabel
              control={
                <Switch
                  disabled={shopId === Shops.STREET_FOOD}
                  checked={pickUp}
                  onChange={(e, checked) => handlePickUpChange(checked)}
                  name="checkedPickUp"
                  color="primary"
                />
              }
              label="Pick Up"
            />
          )}

          {readonly && (
            <Grid container>
              <Grid item md={12}>
                <Typography variant="h6">{text}</Typography>
              </Grid>
            </Grid>
          )}

          {pickUp && (
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label="Pick Up Comments"
                  type="text"
                  fullWidth
                  required
                  onChange={(e) => handleChange(e)}
                  name="pickUpComments"
                  value={form.pickUpComments || ''}
                />
              </Grid>
            </Grid>
          )}

          {(!processNow || (readonly && deliveryAt)) && (
            <React.Fragment>
              <Grid container>
                {!readonly && (
                  <Grid item md={12}>
                    <Typography variant="h6">Schedule Order</Typography>
                  </Grid>
                )}
                <Grid item md={6}>
                  <DatePicker
                    label="Date"
                    required
                    disabled={readonly}
                    value={scheduledDate}
                    onChange={(e) => {
                      if (!e) {
                        setScheduledDate(minimum);
                        return;
                      }

                      e.setHours(0);
                      setScheduledDate(e);
                      setDate(e);
                    }}
                    format={DateFormat.SHORT}
                    disablePast
                    shouldDisableDate={(date) => {
                      if (!date) {
                        return false;
                      }

                      let day = date.getDay();
                      if (day === 0) {
                        day = 7;
                      }

                      return !workingDays.includes(day);
                    }}
                    InputProps={{
                      inputProps: {
                        min: format(minimum, DateFormat.INPUT),
                      },
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <Grid container>
                    <Grid item md={6}>
                      <Autocomplete
                        value={hour}
                        disabled={readonly}
                        options={hours}
                        getOptionLabel={(hour) => hour.toString()}
                        onChange={(
                          e: React.ChangeEvent<{}>,
                          hour: number | null,
                        ) => {
                          if (!hour) {
                            return null;
                          }

                          setHour(hour);
                          scheduledDate.setHours(hour);
                          setScheduledDate(scheduledDate);
                          setDate(scheduledDate);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="hour"
                            variant="standard"
                            label="Hour"
                            placeholder="Hour"
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        value={minute}
                        options={minutes}
                        disabled={readonly}
                        getOptionLabel={(minutes) =>
                          minutes === 0 ? '00' : minutes.toString()
                        }
                        onChange={(
                          e: React.ChangeEvent<{}>,
                          minute: number | null,
                        ) => {
                          if (!minute) {
                            return null;
                          }

                          setMinute(minute);
                          scheduledDate.setMinutes(minute);
                          setScheduledDate(scheduledDate);
                          setDate(scheduledDate);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="minutes"
                            variant="standard"
                            label="Minutes"
                            placeholder="Minutes"
                            required
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {futureOrders && (
                  <Grid container spacing={0}>
                    {futureOrders.ordersCountPlusRange !== null &&
                      futureOrders.ordersCountPlusRange !== undefined && (
                        <Grid item spacing={0} md={12}>
                          <Typography variant="subtitle2" display="inline">
                            There
                            {futureOrders.ordersAtCurrentDate !== 1
                              ? ` are ${futureOrders.ordersAtCurrentDate} orders `
                              : ' is 1 order '}
                            on the selected date and time
                          </Typography>
                        </Grid>
                      )}

                    {futureOrders.ordersCountPlusRange !== null &&
                      futureOrders.ordersCountPlusRange !== undefined && (
                        <Grid item spacing={0} md={12}>
                          <Typography variant="subtitle2" display="inline">
                            There
                            {futureOrders.ordersCountPlusRange !== 1
                              ? ` are ${futureOrders.ordersCountPlusRange} orders `
                              : ' is 1 order '}
                            15 minute <strong>past</strong> the selected date
                            and time
                          </Typography>
                        </Grid>
                      )}

                    {futureOrders.ordersCountMinusRange !== null &&
                      futureOrders.ordersCountMinusRange !== undefined && (
                        <Grid item spacing={0} md={12}>
                          <Typography variant="subtitle2" display="inline">
                            There
                            {futureOrders.ordersCountMinusRange !== 1
                              ? ` are ${futureOrders.ordersCountMinusRange} orders `
                              : ' is 1 order '}
                            15 minute <strong>before</strong> the selected date
                            and time
                          </Typography>
                        </Grid>
                      )}

                    {futureOrders.deliveryTime && (
                      <Grid item spacing={0} md={12}>
                        <Typography variant="subtitle2" display="inline">
                          The current delivery time is{' '}
                          {futureOrders.deliveryTime} minutes
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
        </FormGroup>
      </DialogContent>
      <DialogControls {...dialogControlProps} />
    </React.Fragment>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }
};

function getRoundedDate(minutes: number, date: MaterialUiPickersDate | Date) {
  if (!date) {
    date = addMinutes(new Date(), MINIMUM_ADD_TIME);
  }

  const ms = 1000 * 60 * minutes; // convert minutes to ms
  return new Date(Math.ceil(date.getTime() / ms) * ms);
}
