import { Setting } from 'interfaces';
import { ApiClient } from 'ApiClient';

class SettingsService {
  public async getSettings() {
    return ApiClient.get<Setting[]>('settings');
  }

  public async getSetting(settingId: number): Promise<Setting> {
    return ApiClient.get<Setting>(`settings/${settingId}`);
  }

  public async editSetting(id: number, formData: any) {
    return ApiClient.put(`settings/${id}`, formData);
  }
}

const singleton = new SettingsService();

export { singleton as SettingsService };
