import {
  Button,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  DelayedLinearProgress,
  ResponsiveIconButton,
  RouterLink,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { SetMenu, Shop } from 'interfaces';
import * as React from 'react';
import { SetMenuService, ShopService } from 'services';
import { SaveSetMenuDialog } from '../components/SaveSetMenuDialog';

export const SetMenuView: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [shops, setShops] = React.useState<Shop[]>([]);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [menus, setMenus] = React.useState<SetMenu[]>([]);
  const [selectedMenu, setSelectedMenu] = React.useState<SetMenu>();
  const [shopId, setShopId] = React.useState<number | null>(null);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([SetMenuService.get(), ShopService.getShops()])
      .then(([setMenuResponse, shopsResponse]) => {
        setMenus(setMenuResponse);
        setShops(shopsResponse);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  const columns: SortableTableHeader[] = [
    { key: 'title', label: 'Title' },
    { key: 'shop', label: 'Shop' },
    { key: 'controls', sortable: false },
  ];

  const rows = menus
    .filter((menu) => !shopId || menu.shop.id.toString() === shopId.toString())
    .map((menu) => ({
      key: menu.id ? menu.id.toString() : '',
      cells: [
        {
          key: 'title',
          display: (
            <Button
              component={RouterLink}
              to={`/menu-sections/${menu.id.toString()}/set-menu#set_menu`}
              variant="text"
              size="small"
              style={{ minWidth: 'auto' }}
              color="secondary"
            >
              {menu.name}
            </Button>
          ),
          sortValue: menu.name,
        },
        { key: 'shop', display: menu.shop.title },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedMenu(menu);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedMenu(menu);
              }}
            />
          ),
        },
      ],
    }));

  return (
    <Paper>
      {loading && <DelayedLinearProgress />}
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Set Menus</Typography>

        <div style={{ display: 'flex' }}>
          <TextField
            select
            SelectProps={{ native: true }}
            style={{ flex: 1, marginRight: 8, width: 120 }}
            onChange={(e: any) => setShopId(e.target.value)}
          >
            <option value="">All Shops</option>

            {shops.map((d) => (
              <option value={d.id} key={d.id}>
                {d.title}
              </option>
            ))}
          </TextField>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedMenu(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Set Menu
          </ResponsiveIconButton>
        </div>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No set menus available yet."
        emptyTableCreateAction={(e) => setModalOpen(true)}
        disablePagination
      />

      <SaveSetMenuDialog
        setMenu={selectedMenu}
        refresh={() => setRefresh(!refresh)}
        dialogProps={{
          open: modalOpen,
          onClose: (e) => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'xs',
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this set menu?"
        onConfirm={deleteMenuSection}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedMenu(undefined);
          },
        }}
      />
    </Paper>
  );

  async function deleteMenuSection() {
    try {
      if (!selectedMenu) {
        return;
      }

      const response = await SetMenuService.delete(selectedMenu.id);

      if (!response) {
        return;
      }

      setRefresh(!refresh);
      snackbar.open('Set menu has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
