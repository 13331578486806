import { DialogProps, Grid, TextField } from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Screen } from 'interfaces';
import * as React from 'react';
import { ScreenService } from 'services';
import { Config } from 'config';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  dialogProps: DialogProps;
  screen?: Screen;
  refresh: () => void;
}

interface ImagePosition {
  key: string;
  label: string;
}

interface ScreenForm {
  name?: string;
  image?: File;
  imagePosition?: string;
  screenNumber?: number;
}

export const SaveScreenDialog: React.FC<Props> = ({
  dialogProps,
  screen,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const getForm = React.useCallback(function getForm(
    screen?: Screen,
  ): ScreenForm {
    return {
      name: screen?.name ?? undefined,
      screenNumber: screen?.screenNumber ?? undefined,
      imagePosition: screen?.imagePosition ?? 'left',
    };
  },
  []);

  const imagePositions: ImagePosition[] = [
    { key: 'left', label: 'Left' },
    { key: 'right', label: 'Right' },
  ];

  const [form, setForm] = React.useState(getForm(screen));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(screen));
    }
  }, [getForm, screen, justOpened]);

  return (
    <FormDialog
      title={`${screen ? 'Edit' : 'Add'} Screen`}
      submitText="Save"
      onSubmit={submit}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Screen Number"
            type="number"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="screenNumber"
            value={form.screenNumber || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={
              imagePositions.find((s) => s.key === form.imagePosition) || null
            }
            options={imagePositions}
            getOptionLabel={(option: ImagePosition) => option?.label ?? ''}
            onChange={(
              e: React.ChangeEvent<{}>,
              position: ImagePosition | null,
            ) =>
              handleAutocompleteChange(position?.key ?? null, 'imagePosition')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="imagePosition"
                variant="standard"
                label="Image Position"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {screen && screen.imageUrl && (
            <img
              src={`${Config.IMAGE_CDN_URL}${screen.imageUrl}`}
              alt=""
              style={{ height: 100, display: 'block' }}
            />
          )}
          <input
            id="icon-button-file"
            type="file"
            accept="image/*"
            onChange={onFileChange}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { currentTarget } = e;
    const { files } = currentTarget;

    if (!files || !files.length) {
      throw new Error('Select a file.');
    }

    const file = files[0];

    setForm((f) => ({ ...f, image: file }));
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      const response = await ScreenService.save({
        id: screen?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Screen has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
