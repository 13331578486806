import { JobType, NameForm } from 'interfaces';
import { ApiClient } from 'ApiClient';

class JobService {
  public async getJobTypes() {
    return ApiClient.get<JobType[]>('job-types');
  }

  public async postNewJobType(formData: NameForm) {
    return ApiClient.post('job-types', formData);
  }

  public async editNewJobType(id: number, formData: NameForm) {
    return ApiClient.put(`job-types/${id}`, formData);
  }

  public async deleteJobType(id: number) {
    return ApiClient.delete(`job-types/${id}`);
  }
}

const singleton = new JobService();

export { singleton as JobService };
