import * as React from 'react';
import {
  DialogProps,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { Location, ShopLocation } from 'interfaces';
import { ShopLocationsService } from 'services';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  shopId: number;
  shopLocationList: ShopLocation[];
  locationList: Location[];
  refresh: () => void;
}

export const AddShopLocationDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  shopId,
  shopLocationList,
  locationList,
  refresh,
}) => {
  const [locationId, setLocation] = React.useState<number>();
  const snackbar = React.useContext(SnackbarContext);

  const list =
    shopLocationList.length > 0
      ? locationList
          .filter(
            (type) =>
              !shopLocationList.some((obj) => obj.location.id === type.id),
          )
          .map((type) => type)
      : locationList;

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Add Location to this Shop</Typography>
        </div>
      }
      submitText={'Add Location'}
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            select
            label="Location"
            helperText="Please select location"
            fullWidth
            required
            onChange={(e) => {
              setLocation(+e.target.value);
            }}
            name="locationId"
            value={locationId}
          >
            {list.map((type: Location, key) => (
              <MenuItem key={key} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </FormDialog>
  );

  function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      if (!locationId) throw new Error('Empty Shop id');
      ShopLocationsService.postNewShopLocation(locationId, shopId);

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Location for this Shop has been linked successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
