import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import { PendingItem } from 'interfaces';
import * as React from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { OrderPreparationDialog } from 'views/Orders/components/OrderTab/components/OrderPreparationDialog';
import { OrderService } from 'services';
import { useReactToPrint } from 'react-to-print';
import { PrintOrderReceipt } from 'components';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 350,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  cardHeader: {
    background: theme.palette.primary.light,
    padding: theme.spacing(1, 2),
    color: 'white',
  },
  starredHeader: {
    background: theme.palette.secondary.main,
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: 4,
    marginTop: 6,
    marginLeft: 6,
    color: 'white',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cutOut: {
    textDecoration: 'line-through',
  },
}));

interface Props {
  order: {
    orderId: number;
    starred: boolean;
    items: PendingItem[];
  };
  readonly?: boolean;
  toggleStarred?: (orderId: number) => void;
  onPackOrder?: (orderId: number) => void;
}

export const PendingOrderCard = React.memo<Props>(
  ({ order, readonly = false, toggleStarred, onPackOrder }) => {
    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const classes = useStyles();
    const [expand, setExpand] = React.useState(true);
    const [orderPreparationOpen, setOrderPreparationOpen] = React.useState(
      false,
    );

    const getCheckedItems = React.useCallback(
      function getCheckedItems() {
        return order.items.reduce<string[]>((prev, curr) => {
          const packedItemsCount = parseInt(curr.packedItemsCount, 10);
          const packedItemsCountUsed = prev.filter(
            (p) => p.indexOf(`${curr.menuItemId}-`) !== -1,
          ).length;

          if (readonly || packedItemsCountUsed < packedItemsCount) {
            const itemIndex = order.items.indexOf(curr);

            const key = `${curr.menuItemId}-${itemIndex}`;

            return [...prev, key];
          }

          return prev;
        }, []);
      },
      [order, readonly],
    );

    const [checkedItems, setCheckedItems] = React.useState<string[]>(
      getCheckedItems(),
    );

    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card>
          <CardHeader
            className={classNames(classes.cardHeader, {
              [classes.starredHeader]: order.starred,
            })}
            title={
              <Typography
                variant="subtitle2"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span>
                  {`${order.orderId}`}
                  {' ('}
                  {`${order.items[0].orderNumber ?? 'N/A'}`}
                  {')'}
                </span>
                <span>{`Batch ${order.items[0].batchNumber}`}</span>
                <span>
                  {checkedItems.length} / {order.items.length}
                </span>
              </Typography>
            }
            action={
              <React.Fragment>
                {!readonly && (
                  <IconButton
                    className={classes.expand}
                    onClick={() =>
                      toggleStarred && toggleStarred(order.orderId)
                    }
                  >
                    {order.starred ? <StarIcon /> : <StarBorderIcon />}
                  </IconButton>
                )}
                <IconButton
                  onClick={() => setExpand(!expand)}
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: expand,
                  })}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </React.Fragment>
            }
          />
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <CardContent>
              <List dense className={classes.root}>
                {order.items.map((item, index) => (
                  <ListItem
                    key={index}
                    style={{ cursor: 'pointer', paddingLeft: 0 }}
                    className={classNames({
                      [classes.cutOut]:
                        readonly ||
                        checkedItems.includes(`${item.menuItemId}-${index}`),
                    })}
                    onClick={() => handleCheckChange(item, index)}
                  >
                    <ListItemText
                      primary={item.menuItem}
                      secondary={item.notes}
                    />
                    <ListItemSecondaryAction style={{ right: 0 }}>
                      <Checkbox
                        edge="end"
                        onChange={(value) => handleCheckChange(item, index)}
                        checked={
                          readonly ||
                          checkedItems.includes(`${item.menuItemId}-${index}`)
                        }
                        disabled={readonly}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {order.items.length > 0 && order.items[0].pickupComments ? (
                <React.Fragment>
                  <Typography variant="subtitle1">Pickup Comments</Typography>
                  <Typography variant="subtitle2">
                    {order.items[0].pickupComments}
                  </Typography>
                  <br />
                </React.Fragment>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 8 }}
                onClick={() => handlePrint && handlePrint()}
              >
                Print Receipt
              </Button>
              {!readonly && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOrderPreparationOpen(true)}
                  disabled={checkedItems.length !== order.items.length}
                >
                  Pack Order
                </Button>
              )}
            </CardContent>
          </Collapse>
          <LinearProgress
            variant="determinate"
            value={
              readonly ? 100 : (checkedItems.length / order.items.length) * 100
            }
          />
        </Card>

        <div hidden>
          <div ref={componentRef}>
            <PrintOrderReceipt orderId={order.orderId} />
          </div>
        </div>

        <OrderPreparationDialog
          refresh={() => onPackOrder && onPackOrder(order.orderId)}
          orderId={order.orderId}
          dialogProps={{
            open: orderPreparationOpen,
            onClose: () => setOrderPreparationOpen(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />
      </Grid>
    );

    function handleCheckChange(item: PendingItem, index: number) {
      if (readonly) {
        return;
      }

      const newVal = checkedItems.slice();
      const key = `${item.menuItemId}-${index}`;

      if (newVal.includes(key)) {
        setCheckedItems(newVal.filter((i) => i !== key));
      } else {
        setCheckedItems([...newVal, key]);
      }

      OrderService.packOrderItem({
        action: !newVal.includes(key) ? 'checked' : 'unchecked',
        orderId: item.orderId,
        menuItemId: item.menuItemId,
      });
    }
  },
);
