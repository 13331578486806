import { Grid, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  DelayedLinearProgress,
  ResponsiveIconButton,
  SnackbarContext,
  EmptyView,
} from 'components';
import { DeliveryPackage, DeliveryPackageOrder } from 'interfaces';
import * as React from 'react';
import { useListVals } from 'react-firebase-hooks/database';
import { DeliveryPackageService } from 'services';
import { FirebaseService } from 'services/FirebaseService';
import { DeliveryPackageCard } from '../DeliveryPackageCard';
import { AssignPackageDialog, SaveDeliveryPackageDialog } from './components';

export const Deliveries: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [deliveryPackages, loading] = useListVals<DeliveryPackage>(
    FirebaseService.getDatabase().ref('deliverypackages'),
    { keyField: 'id' },
  );

  const [refresh, setRefresh] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [assignModalOpen, setAssignModalOpen] = React.useState(false);
  const [removeOrderConfirmOpen, setRemoveOrderConfirmOpen] = React.useState(
    false,
  );
  const [selectedOrder, setSelectedOrder] = React.useState<
    DeliveryPackageOrder
  >();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedDeliveryPackage, setSelectedDeliveryPackage] = React.useState<
    DeliveryPackage
  >();

  return (
    <React.Fragment>
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Delivery Packages</Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedDeliveryPackage(undefined);
              setSelectedOrder(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Delivery Package
          </ResponsiveIconButton>
        </Toolbar>
      </Paper>
      {loading && <DelayedLinearProgress />}
      <br />
      <Grid container>
        {deliveryPackages?.length ? (
          deliveryPackages?.map((deliveryPackage) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={deliveryPackage.id}>
                <DeliveryPackageCard
                  deliveryPackage={deliveryPackage}
                  onEdit={() => {
                    setModalOpen(true);
                    setSelectedDeliveryPackage(deliveryPackage);
                  }}
                  onDelete={() => {
                    setConfirmOpen(true);
                    setSelectedDeliveryPackage(deliveryPackage);
                  }}
                  onAssign={() => {
                    setAssignModalOpen(true);
                    setSelectedDeliveryPackage(deliveryPackage);
                  }}
                  onRemoveOrder={(order: DeliveryPackageOrder) => {
                    setRemoveOrderConfirmOpen(true);
                    setSelectedDeliveryPackage(deliveryPackage);
                    setSelectedOrder(order);
                  }}
                />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Paper>
              <EmptyView />
            </Paper>
          </Grid>
        )}
      </Grid>

      <SaveDeliveryPackageDialog
        deliveryPackage={selectedDeliveryPackage}
        dialogProps={{
          open: modalOpen,
          onClose: (e) => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'sm',
        }}
      />

      {selectedDeliveryPackage && (
        <AssignPackageDialog
          deliveryPackage={selectedDeliveryPackage}
          dialogProps={{
            open: assignModalOpen,
            onClose: (e) => setAssignModalOpen(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />
      )}

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this delivery package?"
        onConfirm={deleteDeliveryPackage}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedDeliveryPackage(undefined);
          },
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to remove this order from this delivery package?"
        onConfirm={removeOrder}
        dialogProps={{
          open: removeOrderConfirmOpen,
          onClose: (e) => {
            setRemoveOrderConfirmOpen(false);
            setSelectedDeliveryPackage(undefined);
            setSelectedOrder(undefined);
          },
        }}
      />
    </React.Fragment>
  );

  async function removeOrder() {
    try {
      if (!selectedOrder || !selectedDeliveryPackage) {
        return;
      }

      const response = await DeliveryPackageService.removeOrderFromPackage({
        packageId: selectedDeliveryPackage.id,
        orderId: selectedOrder.id,
      });

      if (!response) {
        return;
      }

      setRefresh(!refresh);
      snackbar.open('Order has been remove from delivery package successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }

  async function deleteDeliveryPackage() {
    try {
      if (!selectedDeliveryPackage) {
        return;
      }

      const response = await DeliveryPackageService.delete(
        selectedDeliveryPackage.id,
      );

      if (!response) {
        return;
      }

      setRefresh(!refresh);
      snackbar.open('Delivery package has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
