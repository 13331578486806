import { ApiClient } from 'ApiClient';
import { UserType, User, NameForm } from 'interfaces';

class UserService {
  public async getUsers() {
    return ApiClient.get<User[]>('users');
  }

  public async getUserTypes() {
    return ApiClient.get<UserType[]>('user-types');
  }

  public async postNewUser(formData: any) {
    return ApiClient.post('users', formData);
  }

  public async editNewUser(id: number, formData: any) {
    return ApiClient.put(`users/${id}`, formData);
  }

  public async postNewUserType(formData: NameForm) {
    return ApiClient.post('user-types', formData);
  }

  public async editNewUserType(id: number, formData: NameForm) {
    return ApiClient.put(`user-types/${id}`, formData);
  }

  public async deleteUserType(id: number) {
    return ApiClient.delete(`user-types/${id}`);
  }

  public async deleteUser(id: number) {
    return ApiClient.delete(`users/${id}`);
  }

  public forgotPassword(data: any) {
    return ApiClient.post('users/forgot-password', data);
  }

  public resetPassword(data: any) {
    return ApiClient.put('users/reset-password', data);
  }

  public getAllOnDuty(): Promise<User[]> {
    return ApiClient.get<User[]>('users/on-duty');
  }
}

const singleton = new UserService();

export { singleton as UserService };
