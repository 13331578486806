import * as React from 'react';
import { DialogProps, Typography, Grid, TextField} from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { Locality, Zone } from 'interfaces';
import { usePrevious } from 'hooks';
import { LocalityService } from 'services';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  locality: Locality;
  zones: Zone[]
  refresh: () => void;
}

interface LocationForm {
  title: string;
  minimumOrder?: number;
  zoneId?: number;
  disableDeliveries: number;
}

export const EditLocalityDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  locality,
  zones,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const getForm = React.useCallback(function getForm(
    locality: Locality,
  ): LocationForm {
    return {
      title: locality.title,
      minimumOrder: locality.minimumOrder ?? undefined,
      zoneId: locality.zoneId ?? undefined,
      disableDeliveries: !locality.disableDeliveries ? 0 : 1
    };
  },
  []);

  interface DisableDelivery {
    key: number;
    label: string;
  }

  const disableDeliveryOptions: DisableDelivery[] = [
    { key: 0, label: 'No' },
    { key: 1, label: 'Yes' },
  ];

  const [form, setLocalityForm] = React.useState(getForm(locality));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setLocalityForm(getForm(locality));
    }
  }, [getForm, justOpened, locality]);

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
            Edit Locality
          </Typography>
        </div>
      }
      submitText={`Edit Locality`}
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={12} xs={12}>
          <TextField
            label="Title"
            type="text"
            placeholder="Enter title"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="title"
            value={form.title}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            label="Minimum Order"
            type="number"
            placeholder="Enter minimum order"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="minimumOrder"
            value={form.minimumOrder}
          />
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
          value={
            zones.find((jt) => jt.id === form.zoneId) || null
          }
          options={zones}
          getOptionLabel={(option: Zone) =>
            option.name
          }
          onChange={(
            e: React.ChangeEvent<{}>,
            zone: Zone | null,
          ) =>
            handleAutocompleteChange(zone?.id ?? null, 'zoneId')
          }
          renderInput={(params) => (
            <TextField
                {...params}
                name="zoneId"
                variant="standard"
                label="Zones"
                placeholder="Zones"
              />
          )}
        />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={
              disableDeliveryOptions.find((s) => s.key === form.disableDeliveries) || null
            }
            options={disableDeliveryOptions}
            getOptionLabel={(option: DisableDelivery) => option?.label ?? ''}
            onChange={(
              e: React.ChangeEvent<{}>,
              disableDelivery: DisableDelivery | null,
            ) =>
              handleAutocompleteChange(disableDelivery?.key ?? null, 'disableDeliveries')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="disableDeliveries"
                variant="standard"
                label="Disable Delivery"
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void {
    let key = e.currentTarget.name as keyof LocationForm;
    let value = e.currentTarget.value;

    if (!key) {
      key = e.target.name as keyof LocationForm;
      value = e.target.value;
    }

    setLocalityForm((form) => ({ ...form, [key]: value }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setLocalityForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      const response = await LocalityService.save({
        id: locality.id,
        data: form,
      });

      if (!response) {
        return;
      }

      refresh();
      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      snackbar.open('Location has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
