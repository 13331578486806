import { ApiClient } from 'ApiClient';
import { Preparation, NameForm } from 'interfaces';

class PreparationService {
  public async getAll(): Promise<Preparation[]> {
    return ApiClient.get<Preparation[]>('preparations');
  }

  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.post(`preparations/update/${id}`, data);
    }

    return ApiClient.post('preparations', data);
  }

  public delete(id: number) {
    return ApiClient.delete(`preparations/${id}`);
  }

  public getPreparationItems() {
    return ApiClient.get(`preparation-items`);
  }

  public addNewPreparationItem(data: NameForm) {
    return ApiClient.post(`preparation-items`, data);
  }
}

const singleton = new PreparationService();

export { singleton as PreparationService };
