import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { OrderView } from './OrderView';
import { OrderJobsView } from './OrderJobsView';
interface Props<Params extends { [K in keyof Params]?: string } = {}>
  extends RouteComponentProps {
  isFuture?: boolean;
}

export const OrderSwitch: React.FC<Props<{ id?: string }>> = ({
  match: { url, path },
  isFuture,
}) => (
  <React.Fragment>
    <BreadcrumbsItem to={url}>Orders</BreadcrumbsItem>

    <Switch>
      <Route exact path={path}>
        <OrderView isFuture={isFuture} />
      </Route>

      <Route path={`${path}/:id([0-9]+)/jobs`}>
        <OrderJobsView />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  </React.Fragment>
);
