import * as React from 'react';
import { Paper, Toolbar, Typography } from '@material-ui/core';
import { SortableTable, TableControls, ResponsiveIconButton } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Gallery, GallerySection } from 'interfaces';
import { Config } from 'config';
import { GalleryService } from 'services/GalleryService';
import { SaveGalleryDialog } from './components/SaveGalleryDialog';
import AddIcon from '@material-ui/icons/Add';
import { useLocation } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

export const GalleryView: React.FC = () => {
  const loc = useLocation();
  const hashResponse = loc.hash.match(/\d+/);
  const gallerySectionId = hashResponse != null ? hashResponse[0] : '';

  const [modalOpen, setModalOpen] = React.useState(false);
  const [gallerySection, setGallerySection] = React.useState<GallerySection>();
  const [image, setImage] = React.useState<Gallery>();

  React.useEffect(() => {
    loadGallery(gallerySectionId);
  }, [gallerySectionId]);

  const columns: SortableTableHeader[] = [
    { key: 'title', label: 'Title' },
    { key: 'image_url', label: 'Image' },
    { key: 'rank', label: 'Rank' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    gallerySection?.galleries?.map((image) => ({
      key: image.id.toString(),
      cells: [
        { key: 'title', display: image.title },
        {
          key: 'image_url',
          display: image.imageUrl ? (
            <img
              src={`${Config.IMAGE_CDN_URL}${image.imageUrl}`}
              alt=""
              style={{ height: 80 }}
            />
          ) : (
            'N/A'
          ),
        },
        { key: 'rank', display: image.rank },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={() => {
                setModalOpen(true);
                setImage(image);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {loc && (
        <React.Fragment>
          <BreadcrumbsItem
            to={`website#gallery-section/${gallerySectionId}/gallery`}
          >
            Gallery
          </BreadcrumbsItem>
          <BreadcrumbsItem to={loc.pathname}>Gallery Sections</BreadcrumbsItem>
        </React.Fragment>
      )}
      <Paper style={{ overflow: 'auto' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Gallery</Typography>
          <ResponsiveIconButton
            onClick={() => {
              setImage(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Image to gallery
          </ResponsiveIconButton>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No images uploaded yet"
          disablePagination
        />
        <SaveGalleryDialog
          image={image}
          refresh={() => loadGallery(gallerySectionId)}
          gallerySectionId={parseInt(gallerySectionId, 10)}
          dialogProps={{
            open: modalOpen,
            onClose: () => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'xs',
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function loadGallery(id: string) {
    const response = await GalleryService.getBySection(id);
    setGallerySection(response ?? []);
  }
};
