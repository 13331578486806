import * as React from 'react';
import {
  DialogProps,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { JobType, LocationJobType } from 'interfaces';
import { LocationService } from 'services/LocationService';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  locationId: number;
  locationJobTypeList: LocationJobType[];
  jobTypeList: JobType[];
  refresh: () => void;
}

export const AddLocationJobTypeDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  locationId,
  locationJobTypeList,
  jobTypeList,
  refresh,
}) => {
  const [jobTypeId, setJobTypeId] = React.useState<number>();
  const snackbar = React.useContext(SnackbarContext);

  const list =
    locationJobTypeList.length > 0
      ? jobTypeList
          .filter(
            (type) =>
              !locationJobTypeList.some((obj) => obj.jobType?.id === type.id),
          )
          .map((type) => type)
      : jobTypeList;

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Add Job Type to Location</Typography>
        </div>
      }
      submitText="Add Job Type"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            select
            label="Type"
            helperText="Please select location"
            fullWidth
            required
            onChange={(e) => {
              setJobTypeId(+e.target.value);
            }}
            name="locationId"
            value={jobTypeId}
          >
            {list.map((type: JobType, key) => (
              <MenuItem key={key} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </FormDialog>
  );

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      if (!jobTypeId) throw new Error('Empty Jop Type id');
      const response = await LocationService.postNewLocationJobType(
        locationId,
        jobTypeId,
      );

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      if (response) {
        refresh();
        snackbar.open(
          'Job Type for this locaton has been linked successfully.',
        );
      }
    } catch (error) {
      snackbar.error(error);
    }
  }
};
