import { Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Screen, ScreenSection } from 'interfaces';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router-dom';
import { ScreenSectionService } from 'services';
import { SaveScreenSectionDialog } from '../components/SaveScreenSectionDialog/SaveScreenSectionDialog';

interface Params {
  id: string;
}

export const MenuScreenSectionView: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id: screenId } = useParams<Params>();

  const snackbar = React.useContext(SnackbarContext);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [screen, setScreen] = React.useState<Screen>();
  const [selectedSection, setSelectedSection] = React.useState<ScreenSection>();

  const loadScreens = React.useCallback(
    async function loadScreens(id: string) {
      try {
        const response = await ScreenSectionService.get(id);
        setScreen(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadScreens(screenId);
  }, [screenId, loadScreens]);

  const columns: SortableTableHeader[] = [
    { key: 'title', label: 'Title' },
    { key: 'rank', label: 'Rank' },
    { key: 'numberOfMenuItems', label: 'No. of Menu Items' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    screen?.screenSections.map((section) => ({
      key: section.id.toString(),
      cells: [
        {
          key: 'title',
          display: section.name,
        },
        { key: 'rank', display: section.rank },
        {
          key: 'numberOfMenuItems',
          display: section.screenSectionMenuItems.length,
        },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedSection(section);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedSection(section);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && <BreadcrumbsItem to={match.url}> Sections</BreadcrumbsItem>}
      <Paper style={{ overflow: 'auto' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">
            {screen ? `${screen.name} - ` : ''} Sections
          </Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedSection(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Section
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No section available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination
        />

        <SaveScreenSectionDialog
          screenSection={selectedSection}
          sections={screen?.screenSections ?? []}
          screenId={+screenId}
          refresh={() => loadScreens(screenId)}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this section?"
          onConfirm={deleteSection}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedSection(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deleteSection() {
    try {
      if (!selectedSection) {
        return;
      }

      const response = await ScreenSectionService.delete(selectedSection.id);

      if (!response) {
        return;
      }

      loadScreens(screenId);
      snackbar.open('Section has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
