import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Config } from 'config';
import Cookies from 'js-cookie';
import { objectToUrlParams, pascalToUnderscoreCase, logout } from 'lib';
import camelcaseKeys from 'camelcase-keys';

type AxiosJSONResponse<T> = AxiosResponse & {
  success: boolean;
  code?: number;
  error?: string;
  data: {
    data: T;
  };
};

class APIClient {
  private api: AxiosInstance;
  private token: string = '';

  constructor() {
    axios.defaults.headers = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    };

    this.api = axios.create();
  }

  public async get<T>(
    path: string,
    queryStringData?: { [key: string]: string | number },
  ) {
    this.token = await this.getToken();
    this.api.defaults.headers = {
      Authorization: 'Bearer ' + this.token,
      Accept: 'application/json',
    };

    const url = `${path}.json${objectToUrlParams(queryStringData)}`;

    const response = await this.api.get<AxiosJSONResponse<T>>(
      `${Config.API_BASE_URL}${url}`,
    );

    if (!response.data.success && response.data.code === 401) {
      logout();
    }

    return camelcaseKeys(response.data.data, { deep: true });
  }

  public async delete<T>(path: string) {
    this.token = await this.getToken();
    this.api.defaults.headers = {
      Authorization: 'Bearer ' + this.token,
    };

    const response = await this.api.delete<AxiosJSONResponse<T>>(
      `${Config.API_BASE_URL}${path}.json`,
    );

    if (!response.data.success && response.data.code === 401) {
      logout();
    }

    if (!response.data.success) {
      throw new Error(response.data.error);
    }

    return camelcaseKeys(response.data.data, { deep: true });
  }

  public async post<U, T>(
    path: string,
    data?: any,
    auth: boolean = true,
    config?: AxiosRequestConfig | undefined,
  ) {
    if (auth) {
      this.token = await this.getToken();
      this.api.defaults.headers = {
        Authorization: 'Bearer ' + this.token,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      };
    } else {
      this.api.defaults.headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      };
    }

    const bodyFormData = this.prepareData('POST', data);

    const response = await this.api.post<AxiosJSONResponse<T>>(
      `${Config.API_BASE_URL}${path}.json`,
      bodyFormData,
      config,
    );

    if (!response.data.success && response.data.code === 401) {
      logout();
    }

    if (!response.data.success) {
      throw new Error(response.data.error);
    }

    return camelcaseKeys(response.data.data, { deep: true });
  }

  public async put<U, T>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig | undefined,
  ) {
    this.token = await this.getToken();
    this.api.defaults.headers = {
      Authorization: 'Bearer ' + this.token,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const params = this.prepareData('PUT', data);

    const response = await this.api.put<AxiosJSONResponse<T>>(
      `${Config.API_BASE_URL}${path}.json`,
      params,
      config,
    );

    if (!response.data.success && response.data.code === 401) {
      logout();
    }

    if (!response.data.success) {
      throw new Error(response.data.error);
    }

    return camelcaseKeys(response.data.data, { deep: true });
  }

  private async getToken() {
    return Cookies.get('authUser') ?? '';
  }

  private prepareData(method: 'POST' | 'PUT', data: any) {
    const formData = method === 'PUT' ? new URLSearchParams() : new FormData();

    if (!data) {
      return formData;
    }

    Object.keys(data).forEach((k) => {
      const key = pascalToUnderscoreCase(k);

      if (typeof data[k] === 'undefined') {
        return;
      }

      if (Array.isArray(data[k])) {
        data[k].forEach((value: any, index: number) => {
          if (typeof value === 'object') {
            Object.keys(value).forEach((objKey: any) => {
              formData.append(
                `${key}[${index}][${pascalToUnderscoreCase(objKey)}]`,
                value[objKey],
              );
            });
          } else {
            formData.append(`${key}[]`, value);
          }
        });
      } else {
        formData.set(key, data[k]);
      }
    });

    return formData;
  }
}

const singleton = new APIClient();

export { singleton as ApiClient };
