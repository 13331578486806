import { DialogProps, TextField } from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import * as React from 'react';
import { OrderService } from 'services';

interface Props {
  dialogProps: DialogProps;
  orderId: number;
  refresh: () => void;
}

interface CustomerForm {
  numberOfBags: number;
}

export const OrderPreparationDialog: React.FC<Props> = ({
  dialogProps,
  orderId,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const getForm = React.useCallback(function getForm(): CustomerForm {
    return {
      numberOfBags: 0,
    };
  }, []);

  const [form, setForm] = React.useState(getForm());

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm());
    }
  }, [getForm, justOpened]);

  return (
    <FormDialog
      title="Order Preparation"
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <TextField
        label="Number of bags"
        type="number"
        fullWidth
        required
        onChange={(e) => handleChange(e)}
        name="numberOfBags"
        value={form.numberOfBags || ''}
      />
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const response = await OrderService.prepareNumberOfBags(orderId, form);

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Order has been prepared successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
