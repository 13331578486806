import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppTabs } from '../../components';
import { OrderHistoryTab, OrderTab, OrderPickup } from './components';
import { PendingItemsTab } from './components/PendingItemsTab';

export enum OrderTabList {
  ORDER = 'order',
  ORDERS_FOR_PACKING = 'order_for_packing',
  FUTURE_ORDERS = 'future_orders',
  ORDER_HISTORY = 'order_history',
  ORDER_PICKUP = 'order_pickup'
}

export const Orders: React.FC<RouteComponentProps<{ id?: string }>> = (
  props,
) => {
  const tabs = [
    { label: 'Order', value: OrderTabList.ORDER },
    { label: 'Future Orders', value: OrderTabList.FUTURE_ORDERS },
    { label: 'Orders for Packing', value: OrderTabList.ORDERS_FOR_PACKING },
    { label: 'Order History', value: OrderTabList.ORDER_HISTORY },
    { label: 'Order for PickUp', value: OrderTabList.ORDER_PICKUP },
  ];

  return (
    <AppTabs tabs={tabs}>
      {(activeTab) => {
        switch (activeTab) {
          case OrderTabList.ORDER:
            return <OrderTab {...props} />;
          case OrderTabList.FUTURE_ORDERS:
            return <OrderTab isFuture {...props} />;
          case OrderTabList.ORDERS_FOR_PACKING:
            return <PendingItemsTab {...props} />;
          case OrderTabList.ORDER_HISTORY:
            return <OrderHistoryTab {...props} />;
          case OrderTabList.ORDER_PICKUP:
            return <OrderPickup {...props} />;
          default:
        }
      }}
    </AppTabs>
  );
};
