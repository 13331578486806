import {
  DialogProps,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { Autocomplete } from '@material-ui/lab';
import {
  EmptyView,
  FormDialog,
  ResponsiveIconButton,
  SnackbarContext,
} from 'components';
import { Config } from 'config';
import { usePrevious } from 'hooks';
import { JobType, MenuItem } from 'interfaces';
import * as React from 'react';
import { MenuItemService } from 'services';
import { JobService } from 'services/JobService';
import { useStyles } from './styles';

interface Props {
  dialogProps: DialogProps;
  menuItem?: MenuItem;
  menuItems: MenuItem[];
  menuCategoryId: number;
  refresh: () => void;
}

interface MenuItemJobForm {
  jobTypeId: number | null;
  name: string | null;
  description: string | null;
  expectedDuration: number | null;
  rank: number | null;
}

interface MenuItemForm {
  title?: string;
  description?: string;
  price?: number;
  rank?: number;
  image?: File;
  menuCategoryId: number;
  jobs: MenuItemJobForm[];
}

export const SaveMenuItemDialog: React.FC<Props> = ({
  dialogProps,
  menuItem,
  menuItems,
  menuCategoryId,
  refresh,
}) => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [jobTypes, setJobTypes] = React.useState<JobType[]>([]);

  const getNextRank = React.useCallback(
    function getNextRank() {
      return (
        menuItems.reduce((prev, curr) => {
          if (curr.rank > prev) {
            return curr.rank;
          }

          return prev;
        }, 0) + 1
      );
    },
    [menuItems],
  );

  const getForm = React.useCallback(
    function getForm(menuItem?: MenuItem): MenuItemForm {
      return {
        title: menuItem?.title ?? undefined,
        description: menuItem?.description ?? undefined,
        price: menuItem?.price ?? undefined,
        rank: menuItem?.rank ?? getNextRank(),
        menuCategoryId,
        jobs:
          menuItem?.menuItemJobs.map(
            ({ rank, jobTypeId, name, description, expectedDuration }) => ({
              rank,
              jobTypeId,
              name,
              description,
              expectedDuration,
            }),
          ) ?? [],
      };
    },
    [menuCategoryId, getNextRank],
  );

  const [form, setForm] = React.useState(getForm(menuItem));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    JobService.getJobTypes().then((response) => {
      setJobTypes(response);
    });

    if (justOpened) {
      setForm(getForm(menuItem));
    }
  }, [getForm, menuItem, justOpened]);

  return (
    <FormDialog
      title={`${menuItem ? 'Edit' : 'Add'} Menu Item`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="title"
            value={form.title || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Rank"
            type="number"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="rank"
            value={form.rank || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            type="text"
            fullWidth
            required
            multiline
            onChange={(e) => handleChange(e)}
            name="description"
            value={form.description || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            label="Price"
            value={form.price || ''}
            name="price"
            onChange={(e) => handleChange(e)}
            margin="none"
            fullWidth
            InputProps={{
              inputProps: { step: 0.01, min: 0 },
              startAdornment: (
                <InputAdornment position="start">&euro;</InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          {menuItem && menuItem.imageUrl && (
            <img
              src={`${Config.IMAGE_CDN_URL}${menuItem.imageUrl}`}
              alt=""
              style={{ height: 100, display: 'block' }}
            />
          )}
          <input
            id="icon-button-file"
            type="file"
            accept="image/*"
            onChange={onFileChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">Menu Item Jobs</Typography>

            <ResponsiveIconButton
              onClick={onAddMenuItemJob}
              color="primary"
              icon={AddIcon}
            >
              Add Menu Item Job
            </ResponsiveIconButton>
          </Toolbar>

          {!form.jobs || form.jobs.length === 0 ? (
            <EmptyView>No menu item jobs set</EmptyView>
          ) : (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 200 }}>Job Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Duration (minutes)</TableCell>
                  <TableCell>Rank</TableCell>
                  <TableCell padding="none" />
                </TableRow>
              </TableHead>

              <TableBody>
                {form.jobs
                  .sort(({ rank: a }, { rank: b }) => (a || 0) - (b || 0))
                  .map((item, i, arr) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Autocomplete
                          value={
                            jobTypes.find((jt) => jt.id === item.jobTypeId) ||
                            null
                          }
                          options={jobTypes}
                          getOptionLabel={(option: JobType) =>
                            option?.name ?? ''
                          }
                          onChange={(e: any, jobType: JobType | null) =>
                            onChangeMenuItemJob(jobType, 'jobTypeId', i)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="jobTypeId"
                              variant="standard"
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          value={item.name || ''}
                          onChange={(e) => onChangeMenuItemJob(e, 'name', i)}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          multiline
                          value={item.description || ''}
                          onChange={(e) =>
                            onChangeMenuItemJob(e, 'description', i)
                          }
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          type="number"
                          value={item.expectedDuration || ''}
                          onChange={(e) =>
                            onChangeMenuItemJob(e, 'expectedDuration', i)
                          }
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          type="number"
                          value={item.rank || ''}
                          onChange={(e) => onChangeMenuItemJob(e, 'rank', i)}
                        />
                      </TableCell>

                      <TableCell padding="none">
                        <IconButton
                          className={classes.removeIcon}
                          onClick={(e) => onRemoveMenuItemJob(e, i)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function onAddMenuItemJob(e: React.MouseEvent<HTMLElement>) {
    const newMenuItemJobs = form.jobs?.slice() ?? [];

    const rank =
      newMenuItemJobs.reduce<number>(
        (prev, curr) => (curr.rank && curr.rank > prev ? curr.rank : prev),
        0,
      ) + 1;

    newMenuItemJobs.push({
      jobTypeId: null,
      name: null,
      description: null,
      expectedDuration: null,
      rank,
    });

    setForm((f) => ({
      ...f,
      jobs: newMenuItemJobs,
    }));
  }

  function isJobType(
    input:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | JobType,
  ): input is JobType {
    return input.hasOwnProperty('name');
  }

  function onChangeMenuItemJob(
    e:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | (JobType | null),
    name: keyof MenuItemJobForm,
    i: number,
  ) {
    const value = e ? (isJobType(e) ? e.id : e.currentTarget.value) : null;

    const newMenuItemJobs = form.jobs.slice();
    const data = newMenuItemJobs[i];

    switch (name) {
      case 'name':
      case 'description':
        data[name] = value ? value.toString() : null;
        break;
      case 'jobTypeId':
        data[name] = value ? parseInt(value.toString(), 10) : null;
        break;
      case 'rank':
      case 'expectedDuration':
        data[name] = value ? parseFloat(value.toString()) : null;
        break;
      default:
        return;
    }

    setForm((f) => ({
      ...f,
      jobs: newMenuItemJobs,
    }));
  }

  function onRemoveMenuItemJob(e: React.MouseEvent<HTMLElement>, i: number) {
    if (!form.jobs) {
      return null;
    }

    const newMenuItemJobs = form.jobs.slice();

    newMenuItemJobs.splice(i, 1);

    setForm((f) => ({
      ...f,
      jobs: newMenuItemJobs,
    }));
  }

  async function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { currentTarget } = e;
    const { files } = currentTarget;

    if (!files || !files.length) {
      throw new Error('Select a file.');
    }

    const file = files[0];

    setForm((f) => ({ ...f, image: file }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const response = await MenuItemService.save({
        id: menuItem?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Menu item has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
