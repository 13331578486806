const env = process.env;

export const Config = {
  DEBUG: env.NODE_ENV ? env.NODE_ENV === 'development' : true,
  API_BASE_URL: env.REACT_APP_API_BASE_URL || 'http://localhost:8765/',
  IMAGE_CDN_URL:
    env.REACT_APP_IMAGE_CDN_URL ||
    'https://peking-staging.s3-eu-west-1.amazonaws.com/images/',
  GOOGLE_API_KEY:
    env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyCgp-HEhxa5Uf1zgjTKdE2bgx1vYNBoVqM',
  FIREBASE: {
    API_KEY:
      env.REACT_APP_FIREBASE_API_KEY ||
      'AIzaSyB0Ky6UqdD_M_D1pA2rIdGmc2k34_DnDrE',
    DATABASE_URL:
      env.REACT_APP_FIREBASE_DATABASE_URL ||
      'https://peking-dev.firebaseio.com/',
    PROJECT_ID: env.REACT_APP_FIREBASE_PROJECT_ID || 'peking-dev',
    AUTH_DOMAIN:
      env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'peking-dev.firebaseapp.com',
    STORAGE_BUCKET:
      env.REACT_APP_FIREBASE_PROJECT_BUCKET || 'peking-dev.appspot.com',
    MESSAGING_SENDER_ID:
      env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '407197553055',
    APP_ID:
      env.REACT_APP_FIREBASE_APP_ID ||
      '1:407197553055:web:9eea3655bab859cda46daa',
    MEASURMENT_ID: env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-0VVV28LN3J',
  },
};
