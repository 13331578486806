import { Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { SetMenu, SetMenuCategory } from 'interfaces';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router-dom';
import { SetMenuCategoryService } from 'services';
import { SaveSetMenuCategoryDialog } from '../components/SaveSetMenuCategoryDialog';

interface Params {
  id: string;
}

export const SetMenuCategoryView: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id: setMenuId } = useParams<Params>();

  const snackbar = React.useContext(SnackbarContext);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [setMenu, setSetMenu] = React.useState<SetMenu>();
  const [selectedMenuCategory, setSelectedMenuCategory] = React.useState<
    SetMenuCategory
  >();

  const loadMenuCategories = React.useCallback(
    async function loadMenuCategories(id: string) {
      try {
        const response = await SetMenuCategoryService.get(id);
        setSetMenu(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadMenuCategories(setMenuId);
  }, [setMenuId, loadMenuCategories]);

  const columns: SortableTableHeader[] = [
    { key: 'title', label: 'Title' },
    { key: 'description', label: 'Description' },
    { key: 'numberOfChoices', label: 'Number of choices' },
    { key: 'rank', label: 'Rank' },
    { key: 'numberOfItems', label: 'Number of items' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    setMenu?.setMenuCategories.map((category) => ({
      key: category.id.toString(),
      cells: [
        {
          key: 'title',
          display: category.name,
          sortValue: category.name,
        },
        { key: 'description', display: category.description },
        { key: 'numberOfChoices', display: category.numberOfChoices },
        { key: 'rank', display: category.rank },
        { key: 'numberOfItems', display: category.setMenuCategoryItems.length },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedMenuCategory(category);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedMenuCategory(category);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && <BreadcrumbsItem to={match.url}> Categories</BreadcrumbsItem>}
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">
            {setMenu ? `${setMenu.name} - ` : ''} Categories
          </Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedMenuCategory(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Set Menu Category
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No set menu category available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination
        />

        <SaveSetMenuCategoryDialog
          setMenuCategory={selectedMenuCategory}
          setMenuCategories={setMenu?.setMenuCategories ?? []}
          setMenuId={setMenuId}
          refresh={() => loadMenuCategories(setMenuId)}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'md',
          }}
        />

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this category?"
          onConfirm={deleteMenuCategory}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedMenuCategory(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deleteMenuCategory() {
    try {
      if (!selectedMenuCategory) {
        return;
      }

      const response = await SetMenuCategoryService.delete(
        selectedMenuCategory.id,
      );

      if (!response) {
        return;
      }

      loadMenuCategories(setMenuId);
      snackbar.open('Set menu category has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
