import {
  Typography,
  Paper,
  Toolbar,
  TextField,
  Grid,
  makeStyles,
} from '@material-ui/core';
import * as React from 'react';
import { UserTimeLogReport, User, ReportFilter } from 'interfaces';
import { DelayedLinearProgress, SortableTable } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useRouteMatch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { ReportService, UserService } from 'services';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

const columns: SortableTableHeader[] = [
  { key: 'timeOfLog', label: 'Time of log' },
  { key: 'log', label: 'Log' },
  { key: 'firstName', label: 'First Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'emailAddress', label: 'Email Address' },
];

interface Params {
  id: string;
}

const useStyles = makeStyles((theme) => ({
  filterElement: {
    width: '75%',
    padding: '1em 2em 1em 2em',
  },
  filter: {
    paddingBottom: '5px',
  },
}));

export const UsersTimeLogReport: React.FC = () => {
  const match = useRouteMatch<Params>();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [usersTimeLogReports, setUsersTimeLogReports] = React.useState<
    UserTimeLogReport[]
  >([]);
  const [users, setUsers] = React.useState<User[]>([]);
  const [user, setUser] = React.useState<User | null>();
  const [dateFrom, setDateFrom] = React.useState<Date | null>();
  const [dateTo, setDateTo] = React.useState<Date | null>();

  React.useEffect(() => {
    setLoading(true);

    const data: ReportFilter = {
      userId: user?.id,
      dateFrom: dateFrom?.toDateString() ?? undefined,
      dateTo: dateTo?.toDateString() ?? undefined,
    };

    ReportService.getUserReport(data).then((response) => {
      setUsersTimeLogReports(response);
      setLoading(false);
    });
  }, [user, dateFrom, dateTo]);

  React.useEffect(() => {
    UserService.getUsers().then((response) => {
      setUsers(response);
      setLoading(false);
    });
  }, []);

  const rows = usersTimeLogReports.map((log: UserTimeLogReport, key) => ({
    key: key.toString(),
    cells: [
      {
        key: 'Time of log',
        display: log.timeOfLog,
        sortValue: log.timeOfLog,
      },
      { key: 'Log', display: log.log, sortValue: log.log },
      { key: 'First Name', display: log.firstName, sortValue: log.firstName },
      { key: 'Last Name', display: log.lastName, sortValue: log.lastName },
      {
        key: 'Email Address',
        display: log.emailAddress,
        sortValue: log.emailAddress,
      },
    ],
  }));

  return (
    <React.Fragment>
      {match && (
        <BreadcrumbsItem to={match.url}>User Time Log Report</BreadcrumbsItem>
      )}
      <DelayedLinearProgress loading={loading} />
      <Paper style={{ overflow: 'auto' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Users Time Log Report</Typography>
        </Toolbar>
        <div className={classes.filterElement}>
          <Typography variant="subtitle2" className={classes.filter}>
            Filter
          </Typography>
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <KeyboardDatePicker
                id="date-from-dialog"
                label="Date From"
                format="dd/MM/yyyy"
                value={dateFrom}
                onChange={(date) => setDateFrom(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <KeyboardDatePicker
                id="date-to-dialog"
                label="Date To"
                format="dd/MM/yyyy"
                value={dateTo}
                onChange={(date) => setDateTo(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <Autocomplete
                value={user}
                options={users}
                getOptionLabel={(option: User) =>
                  `${option?.name ?? ''} ${option?.surname ?? ''}`
                }
                onChange={(e: React.ChangeEvent<{}>, user: User | null) => {
                  setUser(user);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="user"
                    variant="standard"
                    label="Filter User"
                    placeholder="User"
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>
        <SortableTable
          columns={columns}
          rows={rows}
          loading={loading}
          emptyTableText="No user time logs available yet."
        />
      </Paper>
    </React.Fragment>
  );
};
