import * as React from 'react';
import { DialogProps, Typography, Grid, TextField } from '@material-ui/core';
import { JobType, NameForm } from 'interfaces';
import { usePrevious } from 'hooks';
import { SnackbarContext, FormDialog } from 'components';
import { JobService } from 'services/JobService';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  jobType?: JobType;
  jobTypeList: JobType[];
  refresh: () => void;
}

export const AddJobTypeDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  jobType,
  jobTypeList,
  refresh,
}) => {
  const getForm = React.useCallback(function getForm(
    jobType?: JobType,
  ): NameForm {
    return {
      name: jobType?.name ?? '',
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(jobType));
  const [error, setError] = React.useState(false);
  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;
  const snackbar = React.useContext(SnackbarContext);

  const names: string[] = jobTypeList
    .filter((type) => (jobType && jobType.name === type.name ? false : true))
    .map((type) => type.name.toLocaleLowerCase());

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(jobType));
    }
  }, [getForm, jobType, justOpened]);

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Add Job Type</Typography>
        </div>
      }
      submitText={jobType ? 'Edit job type' : 'Add job type'}
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Title"
            type="text"
            placeholder="Enter job type title"
            fullWidth
            required
            error={error}
            helperText={error ? 'Job Type title already exists' : ''}
            onChange={(e) => {
              names.includes(e.currentTarget.value.toLocaleLowerCase())
                ? setError(true)
                : setError(false);
              handleChange(e);
            }}
            name="name"
            value={form.name}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      if (error) {
        throw new Error('Job type title already exists');
      }

      !jobType
        ? JobService.postNewJobType(form)
        : JobService.editNewJobType(jobType.id, form);

      refresh();
      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      snackbar.open('Job type has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
