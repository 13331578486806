import { ApiClient } from 'ApiClient';
import { Report, UserTimeLogReport, ReportFilter, JobReport } from 'interfaces';
import { Reports } from 'enums';

class ReportService {
  public async getAllReports(): Promise<Report[]> {
    return ApiClient.get<Report[]>('reports');
  }

  public async getUserReport(data: ReportFilter): Promise<UserTimeLogReport[]> {
    return ApiClient.post(`reports/get-data/${Reports.USERS_TIME_LOG}`, data);
  }

  public async getJobReport(data: ReportFilter): Promise<JobReport[]> {
    return ApiClient.post(`reports/get-data/${Reports.JOB_REPORT}`, data);
  }
}

const singleton = new ReportService();

export { singleton as ReportService };
