import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: '#ebe7d5',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
  },
  image: {
    backgroundSize: 'cover!important',
    backgroundPosition: 'center!important',
  },
  container: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  sectionTitle: {
    fontWeight: 700,
    color: theme.palette.success.main,
  },
  itemLabel: {
    display: 'inline-block',
  },
  itemPrice: {
    float: 'right',
    display: 'inline-block',
  },
  list: {
    padding: 0,
    overflowX: 'hidden',
    listStyle: 'none',
  },
  item: {
    display: 'block',
  },
}));
