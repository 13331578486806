import * as React from 'react';
import {
  DialogProps,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import {
  UserType,
  User,
  UserForm,
  Shop,
  ShopLocation,
  VehicleType,
} from 'interfaces';
import { UserService } from 'services/UserService';
import { Autocomplete } from '@material-ui/lab';
import { ShopService } from 'services/ShopService';
import { usePrevious } from 'hooks';
import { VehicleTypesService, ShopLocationsService } from 'services';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  user?: User;
  users: User[];
  refresh: () => void;
}

export const AddUserDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  user,
  users,
  refresh,
}) => {
  const [userTypes, setUserTypes] = React.useState([]);
  const [shops, setShops] = React.useState<Shop[]>([]);
  const [shopsToShow, setShopsToShow] = React.useState<Shop[]>([]);
  const [shopLocations, setShopLocations] = React.useState<ShopLocation[]>([]);
  const [vehicleTypes, setVehicleTypes] = React.useState<VehicleType[]>([]);
  const [error, setError] = React.useState(false);
  const snackbar = React.useContext(SnackbarContext);

  const emails: string[] = users
    .filter((temp) => !(user && user.emailAddress === temp.emailAddress))
    .map((user) => user.emailAddress);

  const getForm = React.useCallback(function getForm(user?: User): UserForm {
    return {
      emailAddress: user?.emailAddress ?? '',
      name: user?.name ?? '',
      surname: user?.surname ?? '',
      userTypeId: user?.userType.id ?? 0,
      phone: user?.phone ?? '',
      shops: user?.userShopAllocations.map((shop) => shop.shop) ?? [],
      shopLocations:
        user?.userLocationAllocations?.map(
          (allocation) => allocation.shopLocation,
        ) ?? [],
      vehicleTypes: user?.userVehicleTypes.map((t) => t.vehicleType) ?? [],
    };
  }, []);

  const [form, setUserForm] = React.useState(getForm(user));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    UserService.getUserTypes().then((response) => {
      setUserTypes(response);
    });

    ShopService.getShops().then((response: Shop[]) => {
      setShops(response);
    });

    VehicleTypesService.getAll().then((response) => {
      setVehicleTypes(response);
    });

    if (justOpened) {
      setUserForm(getForm(user));
    }
  }, [user, justOpened, getForm, form.shops]);

  React.useEffect(() => {
    setShopLocations([]);
    ShopLocationsService.getShopLocations().then((response: ShopLocation[]) => {
      const shopIds = form.shops.map((shop) => shop.id);
      setShopLocations(
        response
          .filter((shopLocation) => shopIds.includes(shopLocation.shopId))
          .map((shopLocation) => shopLocation),
      );
    });

    setShopsToShow(
      form.shops.length > 0 && form.shops.length < shops.length
        ? shops.filter((shop) =>
            form.shops.find((formShop) => shop.id !== formShop.id),
          )
        : shops,
    );
  }, [form.shops, justOpened, shops]);

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Add Employee</Typography>
        </div>
      }
      submitText={user ? 'Edit user' : 'Add user'}
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Name"
            type="text"
            placeholder="Enter name"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Surname"
            type="text"
            placeholder="Enter surname"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="surname"
            value={form.surname}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            type="email"
            placeholder="Enter email"
            fullWidth
            required
            value={form.emailAddress}
            error={error}
            helperText={error ? 'A user with that email already exists' : ''}
            onChange={(e) => {
              emails.includes(e.currentTarget.value)
                ? setError(true)
                : setError(false);
              handleChange(e);
            }}
            name="emailAddress"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone Number"
            type="tel"
            placeholder="Enter phone number"
            onChange={(e) => handleChange(e)}
            fullWidth
            required
            name="phone"
            value={form.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            label="Type"
            helperText="Please select user type"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="userTypeId"
            value={form.userTypeId}
          >
            {userTypes.map((type: UserType, key) => (
              <MenuItem key={key} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={form.shops}
            multiple
            options={shopsToShow}
            getOptionLabel={(option: Shop) => option?.title ?? ''}
            onChange={(e, shops) => handleChangeShop(shops)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="shops"
                variant="standard"
                label="Shops"
                placeholder="Shops"
              />
            )}
          />
        </Grid>
        {form.shops.length > 0 ? (
          <Grid item xs={12}>
            <Autocomplete
              value={form.shopLocations}
              multiple
              options={shopLocations}
              getOptionLabel={(option: ShopLocation) =>
                shops.find((shop) => shop.id === option.shopId)?.title +
                  '-' +
                  option?.location.name ?? ''
              }
              onChange={(e, shopLocations) =>
                handleChangeLocation(shopLocations)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="locations"
                  variant="standard"
                  label="Locations"
                  placeholder="Locations"
                />
              )}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Autocomplete
            value={form.vehicleTypes}
            multiple
            options={vehicleTypes}
            getOptionLabel={(option: VehicleType) => option?.name ?? ''}
            onChange={(e, vehicleTypes) =>
              handleVehicleTypeChange(vehicleTypes)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="vehicleTypes"
                variant="standard"
                label="Vehicles"
                placeholder="Vehicles"
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChangeLocation(shopLocations: ShopLocation[]) {
    const newForm = { ...form };
    newForm.shopLocations = shopLocations;
    setUserForm(newForm);
  }

  function handleChangeShop(shops: Shop[]) {
    const newForm = { ...form };
    newForm.shops = shops;

    const newLocations = newForm.shopLocations.filter((location) =>
      shops.find((shop) => shop.id === location.shopId),
    );

    newForm.shopLocations = newLocations;
    setUserForm(newForm);
  }

  function handleVehicleTypeChange(vehicleTypes: VehicleType[]) {
    const newForm = { ...form };
    newForm.vehicleTypes = vehicleTypes;
    setUserForm(newForm);
  }

  function handleChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void {
    let key = e.currentTarget.name as keyof UserForm;
    let value = e.currentTarget.value;

    if (!key) {
      key = e.target.name as keyof UserForm;
      value = e.target.value;
    }

    setUserForm((form) => ({ ...form, [key]: value }));
  }

  function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      if (error) {
        throw new Error('A user with that email already exists');
      }

      const newForm = {
        ...form,
        shops: form.shops.map((s) => s.id),
        locations: form.shopLocations.map((l) => l.id),
        vehicleTypes: form.vehicleTypes.map((vt) => vt.id),
      };

      !user
        ? UserService.postNewUser(newForm)
        : UserService.editNewUser(user.id ?? 0, newForm);

      refresh();
      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      snackbar.open('User has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
