import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { usePrevious } from 'hooks';
import { Order } from 'interfaces';
import * as React from 'react';
import { OrderService } from 'services';
import { deliveryPackageStatusToFriendly, orderStatusToFriendly } from 'lib';

interface Props {
  dialogProps: DialogProps;
  order?: Order;
}

const useStyles = makeStyles((theme) => ({
  table: {
    marginBottom: '2em',
    marginTop: '1em',
  },
}));

export const OrderReport: React.FC<Props> = ({ dialogProps, order }) => {
  const [orderReport, setOrderReport] = React.useState<Order>();

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;
  const classes = useStyles();

  React.useEffect(() => {
    if (justOpened && order) {
      OrderService.generateReport(order.id).then((response) => {
        setOrderReport(response);
      });
    }
  }, [order, justOpened]);

  const orderStatusHistory = orderReport?.orderStatusHistories ?? null;
  const deliveryPackage = orderReport?.deliveryPackage ?? null;
  const deliveryPackageHistory =
    orderReport?.deliveryPackage?.deliveryPackageStatusHistories ?? null;
  const orderJobs = orderReport?.orderItems ?? null;

  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <Typography variant="h6">Order report</Typography>
        {orderStatusHistory && orderReport ? (
          <React.Fragment>
            <Typography>
              <strong>Total time: </strong>
              {getTimeElapsed(
                orderReport.created,
                orderStatusHistory && orderStatusHistory.length > 0
                  ? orderStatusHistory[orderStatusHistory.length - 1].created
                  : new Date(),
              )}
            </Typography>

            <TableContainer component={Paper} className={classes.table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Time Started</TableCell>
                    <TableCell align="right">Time Ended</TableCell>
                    <TableCell align="right">Elapsed Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={orderReport.id}>
                    <TableCell component="th" scope="row">
                      Order Created
                    </TableCell>
                    <TableCell align="right">{orderReport.created}</TableCell>
                    <TableCell align="right">
                      {orderStatusHistory[0]
                        ? orderStatusHistory[0].created
                        : 'N/A'}
                    </TableCell>
                    <TableCell align="right">
                      {orderStatusHistory[0]
                        ? getTimeElapsed(
                            orderReport.created,
                            orderStatusHistory[0].created,
                          )
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                  {orderReport.orderStatusHistories
                    ? orderReport.orderStatusHistories.map((status, index) => (
                        <TableRow key={status.id}>
                          <TableCell component="th" scope="row">
                            {orderStatusToFriendly(status.orderStatusId)}
                          </TableCell>
                          <TableCell align="right">{status.created}</TableCell>
                          <TableCell align="right">
                            {orderStatusHistory.length > index + 1
                              ? orderStatusHistory[index + 1].created
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="right">
                            {orderStatusHistory.length > index + 1
                              ? getTimeElapsed(
                                  status.created,
                                  orderStatusHistory[index + 1].created,
                                )
                              : 'N/A'}
                          </TableCell>
                        </TableRow>
                      ))
                    : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          ''
        )}

        {orderJobs && orderJobs[0].jobOrderItems.length > 0 && orderReport ? (
          <React.Fragment>
            <Typography variant="h6">Job History</Typography>
            <TableContainer component={Paper} className={classes.table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Job Type</TableCell>
                    <TableCell align="right">Chef</TableCell>
                    <TableCell align="right">Time Started</TableCell>
                    <TableCell align="right">Time Ended</TableCell>
                    <TableCell align="right">Elapsed Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderJobs
                    .filter((job) => job.jobOrderItems)
                    .map((job, index) =>
                      job.jobOrderItems.map((orderItem) => (
                        <TableRow key={orderItem.id}>
                          <TableCell component="th" scope="row">
                            {orderItem.job.menuItemJob.name}
                          </TableCell>
                          <TableCell align="right">
                            {orderItem.job.user?.name}{' '}
                            {orderItem.job.user?.surname}
                          </TableCell>
                          <TableCell align="right">
                            {orderItem.job.jobLogs &&
                            orderItem.job.jobLogs.length > 0
                              ? orderItem.job.jobLogs[0].created
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="right">
                            {orderItem.job.jobLogs &&
                            orderItem.job.jobLogs.length > 1
                              ? orderItem.job.jobLogs[
                                  orderItem.job.jobLogs.length - 1
                                ].created
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="right">
                            {orderItem.job.jobLogs &&
                            orderItem.job.jobLogs.length > 1
                              ? getTimeElapsed(
                                  orderItem.job.jobLogs[0].created,
                                  orderItem.job.jobLogs[
                                    orderItem.job.jobLogs.length - 1
                                  ].created,
                                )
                              : 'N/A'}
                          </TableCell>
                        </TableRow>
                      )),
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          ''
        )}

        {deliveryPackageHistory && orderReport && deliveryPackage ? (
          <React.Fragment>
            <Typography variant="h6">Delivery History</Typography>
            <Typography>
              <strong>Delivery person: </strong>
              {` ${deliveryPackage.userVehicleType.user.name} ${deliveryPackage.userVehicleType.user.surname}`}
            </Typography>
            <Typography>
              <strong>Vehicle: </strong>
              {deliveryPackage.userVehicleType.vehicleType.name}
            </Typography>
            <Typography>
              <strong>Total time: </strong>
              {getTimeElapsed(
                deliveryPackageHistory[0].created,
                deliveryPackageHistory.length > 0
                  ? deliveryPackageHistory[deliveryPackageHistory.length - 1]
                      .created
                  : new Date(),
              )}
            </Typography>
            <TableContainer component={Paper} className={classes.table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Time Started</TableCell>
                    <TableCell align="right">Time Ended</TableCell>
                    <TableCell align="right">Elapsed Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deliveryPackageHistory.map((status, index) => (
                    <TableRow key={status.id}>
                      <TableCell component="th" scope="row">
                        {deliveryPackageStatusToFriendly(
                          status.deliveryPackageStatusId,
                        )}
                      </TableCell>
                      <TableCell align="right">{status.created}</TableCell>
                      <TableCell align="right">
                        {deliveryPackageHistory.length > index + 1
                          ? deliveryPackageHistory[index + 1].created
                          : 'N/A'}
                      </TableCell>
                      <TableCell align="right">
                        {deliveryPackageHistory.length > index + 1
                          ? getTimeElapsed(
                              status.created,
                              deliveryPackageHistory[index + 1].created,
                            )
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogContent>
        {orderStatusHistory === null &&
        deliveryPackage === null &&
        orderJobs === null
          ? 'This order has not been starter yet'
          : ''}
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogProps.onClose} color="primary">
          Close Report
        </Button>
      </DialogActions>
    </Dialog>
  );

  function getTimeElapsed(firstDate?: Date, secondDate?: Date) {
    if (!firstDate || !secondDate) {
      return 'N/A';
    }

    const firstDateUnix = toTimestamp(firstDate);
    const secondDateUnix = toTimestamp(secondDate);

    let delta = Math.abs(secondDateUnix - firstDateUnix) / 1000;

    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    const seconds = delta % 60;

    return `${formatDateNumber(hours, 'hr')} ${formatDateNumber(
      minutes,
      'min',
    )} ${formatDateNumber(seconds, 'sec')}`;
  }

  function formatDateNumber(num: number, postfix: string) {
    if (num === 0) {
      return '';
    }

    if (num > 1) {
      postfix += 's';
    }

    return `${Math.ceil(num)} ${postfix}`;
  }

  function toTimestamp(strDate: Date) {
    var datum = new Date(strDate).getTime();
    return datum;
  }
};
