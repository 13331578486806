import { Typography, Paper, Toolbar } from '@material-ui/core';
import * as React from 'react';
import { LocationJobType, Location } from 'interfaces';
import {
  DelayedLinearProgress,
  ConfirmDialog,
  ResponsiveIconButton,
  SortableTable,
  SnackbarContext,
  TableControls,
  AddLocationDialog,
  AddJobTypeLocationDialog,
} from 'components';
import { LocationService } from 'services/LocationService';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useRouteMatch, useParams } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import LinkIcon from '@material-ui/icons/Link';

const columns: SortableTableHeader[] = [
  { key: 'name', label: 'Location' },
  { key: 'controls', sortable: false },
];

interface Params {
  id: string;
}

export const JobTypesLocationView: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);
  const match = useRouteMatch<Params>();
  const { id } = useParams<Params>();

  const [loading, setLoading] = React.useState(true);
  const [jobTypeLocations, setJobTypesLocations] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editLocation, setEditLocation] = React.useState<Location>();
  const [deleteLocation, setDeleteLocation] = React.useState<Location>();
  const [deleteLocationJobType, setDeleteLocationJobType] = React.useState<
    LocationJobType
  >();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirmRemove, setOpenConfirmRemove] = React.useState(false);

  const loadData = React.useCallback(
    async function loadData(id: string) {
      try {
        setLoading(true);
        await Promise.all([
            getJobTypeLocations(id),
            getLocations(),
        ]);
      } catch (error) {
        snackbar.error(error);
      } finally {
        setLoading(false);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadData(id);
  }, [id, loadData]);

  const rows = jobTypeLocations.map((jobTypesLocation: LocationJobType) => ({
    key: jobTypesLocation.id.toString(),
    cells: [
      { key: 'name', display: jobTypesLocation.location?.name },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={() => {
              setEditLocation(jobTypesLocation.location);
              setOpenEdit(true);
            }}
            onDelete={() => {
              setOpenConfirm(true);
              setDeleteLocation(jobTypesLocation.location);
            }}
            onRemoveLink={() => {
              setOpenConfirmRemove(true);
              setDeleteLocationJobType(jobTypesLocation);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      {match && <BreadcrumbsItem to={match.url}>Locations</BreadcrumbsItem>}
      <DelayedLinearProgress loading={loading} />
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Location Table</Typography>
          <ResponsiveIconButton
            onClick={() => setOpen(true)}
            color="primary"
            icon={LinkIcon}
          >
            Link Location
          </ResponsiveIconButton>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          loading={loading}
          emptyTableText="No locations available yet."
          emptyTableCreateAction={() => setOpen(true)}
          disablePagination
        />
      </Paper>
      <AddLocationDialog
        loading={loading}
        dialogProps={{
          open: openEdit,
          onClose: () => setOpenEdit(false),
          fullWidth: true,
        }}
        location={editLocation}
        refresh={() => {
          loadData(id);
        }}
        locationList={locations}
      />
      <ConfirmDialog
        title="Are you sure you want to delete this location?"
        dialogProps={{
          open: openConfirm,
          onClose: () => {
            setOpenConfirm(false);
            setDeleteLocation(undefined);
          },
        }}
        onConfirm={() => {
          if (!deleteLocation) return;
          LocationService.deleteLocation(deleteLocation.id);
          loadData(id);
        }}
      />
      <AddJobTypeLocationDialog
        dialogProps={{
          open: open,
          onClose: () => setOpen(false),
          fullWidth: true,
        }}
        locationList={locations}
        jobTypeId={+id}
        jobTypeLocationList={jobTypeLocations}
        loading={loading}
        refresh={() => {
          loadData(id);
        }}
      />
      <ConfirmDialog
        title="Are you sure you want to unlink this job type from this location?"
        dialogProps={{
          open: openConfirmRemove,
          onClose: () => {
            setOpenConfirmRemove(false);
            setDeleteLocationJobType(undefined);
          },
        }}
        onConfirm={() => {
          if (!deleteLocationJobType) return;
          LocationService.deleteLocationJobType(deleteLocationJobType.id);
          loadData(id);
        }}
      />
    </React.Fragment>
  );

  function getJobTypeLocations(id: string)
  {
    LocationService.getLocationJobTypesByJobType(+id).then((response) => {
      setJobTypesLocations(response);
    });
  }

  function getLocations()
  {
    LocationService.getLocations().then((response) => {
      setLocations(response);
    });
  }
};
