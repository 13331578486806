import * as React from 'react';
import {
  DialogProps,
  Typography,
  Grid,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import {
  User,
  DriverCashFlow
} from 'interfaces';
import { DriverCashFlowService } from 'services/DriverCashFlowService';
import { usePrevious } from 'hooks';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  user?: User;
  driverCashFlow?: DriverCashFlow;
  refresh: () => void;
}

interface DriverCashFlowForm {
  price?: number;
}

export const SaveDriverCashFlowDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  user,
  driverCashFlow,
  refresh,
}) => {
  const [error] = React.useState(false);
  const snackbar = React.useContext(SnackbarContext);

  const getForm = React.useCallback(function getForm(driverCashFlow?: DriverCashFlow): DriverCashFlowForm {
    return {
      price: driverCashFlow?.price ?? 0.0,
    };
  }, []);

  const [form, setUserForm] = React.useState(getForm(driverCashFlow));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setUserForm(getForm(driverCashFlow));
    }
  }, [justOpened, getForm, driverCashFlow]);

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Driver Cash Flow</Typography>
        </div>
      }
      submitText= 'Set Driver Cash Flow'
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            type="number"
            label="Price"
            value={form.price || ''}
            name="price"
            onChange={(e) => handleChange(e)}
            margin="none"
            fullWidth
            InputProps={{
              inputProps: { step: 0.01, min: 0 },
              startAdornment: (
                <InputAdornment position="start">&euro;</InputAdornment>
              ),
            }}
            required
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setUserForm((f) => ({ ...f, [name]: value }));
  }

  function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      if (error) {
        throw new Error('Error when saving driver cash flow');
      }

      const newForm = {
        ...form,
        userId: user?.id
      };

      DriverCashFlowService.saveDriverCashFlow(newForm)

      refresh();
      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      snackbar.open('Cash Flow has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
