import { Typography, Paper, Toolbar } from '@material-ui/core';
import * as React from 'react';
import { Setting } from 'interfaces';
import {
  DelayedLinearProgress,
  TableControls,
  SortableTable,
  EditSettingDialog,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { SettingsService } from 'services';

const columns: SortableTableHeader[] = [
  { key: 'key', label: 'Title' },
  { key: 'value', label: 'Value' },
  { key: 'constrols', sortable: false },
];

export const SettingsTab: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [settings, setSettings] = React.useState<Setting[]>([]);
  const [editSetting, setEditSetting] = React.useState<Setting>();

  React.useEffect(() => {
    getSettings();
  }, []);

  const rows = (settings ?? []).map((setting: Setting) => ({
    key: setting.id.toString(),
    cells: [
      {
        key: 'key',
        display: (
          <div style={{ textTransform: 'capitalize' }}>
            {setting.key.replace(/_/g, " ")}
          </div>
        ),
      },
      { key: 'value', display: setting.value },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={() => {
              setEditSetting(setting);
              setOpenEdit(true);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      <DelayedLinearProgress loading={loading} />
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Settings</Typography>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No settings available yet."
          disablePagination
        />
      </Paper>
      <EditSettingDialog
        loading={loading}
        dialogProps={{
          open: openEdit,
          onClose: () => setOpenEdit(false),
          maxWidth: 'xs',
          fullWidth: true,
        }}
        setting={editSetting}
        refresh={() => getSettings()}
      />
    </React.Fragment>
  );

  async function getSettings() {
    try {
      const response = await SettingsService.getSettings();
      setSettings(response);
    } catch (error) {
      setSettings([]);
    } finally {
      setLoading(false);
    }
  }
};
