import { Grid, Typography } from '@material-ui/core';
import { Screen } from 'interfaces';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ScreenService } from 'services';
import { useStyles } from './styles';
import { Config } from 'config';

interface Params {
  id: string;
}

export const MenuDisplay: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<Params>();

  const [refresh] = React.useState(false);
  const [screen, setScreen] = React.useState<Screen>();

  React.useEffect(() => {
    ScreenService.getById(id).then((response) => {
      setScreen(response);
    });
  }, [refresh, id]);

  return (
    <Grid className={classes.wrapper} container>
      {screen?.imagePosition === 'left' ? (
        <Grid
          item
          xs={3}
          style={{
            background: `url(${Config.IMAGE_CDN_URL}${screen?.imageUrl})`,
          }}
          className={classes.image}
        ></Grid>
      ) : null}
      <Grid item xs={screen?.imagePosition ? 9 : 12}>
        <Grid container>
          {screen?.screenSections
            .sort((a, b) => a.rank - b.rank)
            .map((section, index) => (
              <Grid key={section.id} item xs={getColumnSize(index)}>
                <div className={classes.container}>
                  <Typography variant="h3" className={classes.sectionTitle}>
                    {section.name}
                  </Typography>
                  <ul className={classes.list}>
                    {section.screenSectionMenuItems.map((item) => (
                      <li key={item.menuItem.id} className={classes.item}>
                        <Typography
                          variant="h5"
                          component="span"
                          className={classes.itemLabel}
                        >
                          {item.menuItem.id}. {item.menuItem.title}
                        </Typography>
                        <Typography
                          variant="h5"
                          component="span"
                          className={classes.itemPrice}
                        >
                          &euro;{item.menuItem.price}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
            )) ?? null}
        </Grid>
      </Grid>
      {screen?.imagePosition === 'right' ? (
        <Grid
          item
          xs={3}
          style={{
            background: `url(${Config.IMAGE_CDN_URL}${screen?.imageUrl})`,
          }}
          className={classes.image}
        ></Grid>
      ) : null}
    </Grid>
  );

  function getColumnSize(index: number) {
    const numberOfSections = screen?.screenSections.length ?? 0;
    const numberOfColumnsPerRow = 2;

    // return columnNumber;
    return numberOfSections % numberOfColumnsPerRow === 0 ||
      index + 1 < numberOfSections
      ? 6
      : 12;
  }
};
