import { DialogProps, Grid, TextField } from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Customer, Locality } from 'interfaces';
import * as React from 'react';
import { CustomerService, LocalityService } from 'services';
import { Autocomplete } from '@material-ui/lab';
import GoogleMapContainer from 'components/GoogleMapContainer/GoogleMapContainer';

interface Props {
  dialogProps: DialogProps;
  setCustomer?: (customer: Customer) => void;
  customer?: Customer;
  refresh: () => void;
}

interface CustomerForm {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  address?: string;
  houseName?: string;
  locationId?: number;
}

export const SaveCustomerDialog: React.FC<Props> = ({
  dialogProps,
  setCustomer,
  customer,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [localities, setLocalities] = React.useState<Locality[]>([]);

  const [coordinates, setCoordinates] = React.useState<{
    lat: number;
    lng: number;
  }>({
    lat: customer?.latitude ?? 35.917973,
    lng: customer?.longitude ?? 14.409943,
  });

  const getForm = React.useCallback(function getForm(
    customer?: Customer,
  ): CustomerForm {
    return {
      firstName: customer?.firstName ?? undefined,
      lastName: customer?.lastName ?? undefined,
      emailAddress: customer?.emailAddress ?? undefined,
      phoneNumber: customer?.phoneNumber ?? undefined,
      address: customer?.address ?? undefined,
      houseName: customer?.houseName ?? undefined,
      locationId: customer?.locationId ?? undefined,
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(customer));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    LocalityService.getAll().then((response) => {
      setLocalities(response);
    });

    if (justOpened) {
      setForm(getForm(customer));
      setCoordinates({
        lat: customer?.latitude ?? 35.917973,
        lng: customer?.longitude ?? 14.409943,
      });
    }
  }, [getForm, customer, justOpened]);

  return (
    <FormDialog
      title={`${customer ? 'Edit' : 'Add'} Customer`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="First Name"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="firstName"
            value={form.firstName || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Last Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="lastName"
            value={form.lastName || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Email Address"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="emailAddress"
            value={form.emailAddress || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Phone Number"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="phoneNumber"
            value={form.phoneNumber || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Address"
            type="text"
            fullWidth
            autoComplete="off"
            required
            onChange={(e) => handleChange(e)}
            name="address"
            value={form.address || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="House name"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="houseName"
            value={form.houseName || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={localities.find((s) => s.id === form.locationId) || null}
            options={localities}
            getOptionLabel={(option: Locality) => option?.title ?? ''}
            onChange={(e: React.ChangeEvent<{}>, locality: Locality | null) =>
              handleAutocompleteChange(locality?.id ?? null, 'locationId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="locationId"
                variant="standard"
                label="Location"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <GoogleMapContainer
            coordinates={coordinates}
            setCoordinates={setCoordinates}
            address={form.address ?? ''}
            setAddress={(address:string)=>{ setForm((f) => ({ ...f, address}));}}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const response = await CustomerService.save({
        id: customer?.id ?? undefined,
        data: {
          ...form,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        },
      });

      if (!response) {
        return;
      }

      if (setCustomer) {
        setCustomer(response);
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Customer has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
