import {
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker, EmptyView, DelayedLinearProgress } from 'components';
import { format } from 'date-fns';
import { useApiClient, useDebounce } from 'hooks';
import { PendingItem } from 'interfaces';
import * as React from 'react';
import { PendingOrderCard } from '../PendingItemsTab/PendingItemsView/components';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(0.5, 0),
  },
  toolbarBtn: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  filtersContainer: {
    ...theme.mixins.gutters(),
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  filterDateInputs: {
    marginTop: theme.spacing(1.25),
  },
}));

export const OrderHistoryTab: React.FC = () => {
  const classes = useStyles();
  const [textSearch, setTextSearch] = React.useState('');
  const [dateFrom, setDateFrom] = React.useState<Date>();
  const [dateTo, setDateTo] = React.useState<Date>();

  const variables = React.useMemo(
    () => ({
      dateFrom: format(dateFrom ?? new Date(), 'yyyy-MM-dd'),
      dateTo: format(dateTo ?? new Date(), 'yyyy-MM-dd'),
      orderId: textSearch,
    }),
    [dateFrom, dateTo, textSearch],
  );

  const [{ data, progress }] = useApiClient<PendingItem[]>(
    `orders/items-packed`,
    useDebounce(variables, 500),
  );

  const items = (data ?? []).reduce<
    { orderId: number; starred: boolean; items: PendingItem[] }[]
  >((prev, curr) => {
    const orderIndex = prev.findIndex((p) => p.orderId === curr.orderId);

    const splitItems =
      curr.quantity > 1
        ? Array.from(Array(curr.quantity).keys()).map((c) => ({
            ...curr,
            quantity: 1,
          }))
        : [curr];

    if (orderIndex === -1) {
      return [
        ...prev,
        {
          orderId: curr.orderId,
          starred: false,
          items: [...splitItems],
        },
      ];
    } else {
      prev[orderIndex].items.push(...splitItems);
    }

    return prev;
  }, []);

  return (
    <React.Fragment>
      <Paper>
        {progress && <DelayedLinearProgress />}
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Order History</Typography>
          <Typography variant="subtitle2">
            Number of orders: {items.length}
          </Typography>
        </Toolbar>

        <div className={classes.filtersContainer}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Date From"
                value={dateFrom || new Date()}
                onChange={(e: MaterialUiPickersDate) =>
                  setDateFrom(e ?? undefined)
                }
                fullWidth
                clearable
                className={classes.filterDateInputs}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                label="Date To"
                value={dateTo || new Date()}
                onChange={(e: MaterialUiPickersDate) =>
                  setDateTo(e ?? undefined)
                }
                fullWidth
                clearable
                className={classes.filterDateInputs}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Search"
                placeholder="Search"
                value={textSearch}
                style={{ marginTop: 10 }}
                onChange={(e) => setTextSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                autoFocus
              />
            </Grid>
          </Grid>
        </div>
      </Paper>

      {items.length > 0 ? (
        <Grid container>
          {items.map((order, index) => (
            <PendingOrderCard readonly key={order.orderId} order={order} />
          ))}
        </Grid>
      ) : (
        <EmptyView>No orders found matching the filters</EmptyView>
      )}
    </React.Fragment>
  );
};
