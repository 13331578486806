import { DialogProps, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious, useApiClient } from 'hooks';
import { DeliveryPackage, Order, Zone } from 'interfaces';
import * as React from 'react';
import { DeliveryPackageService } from 'services';

interface Props {
  dialogProps: DialogProps;
  deliveryPackage?: DeliveryPackage;
}

interface CustomerForm {
  packageSize?: number;
  zoneId?: number;
  orders?: number[];
}

export const SaveDeliveryPackageDialog: React.FC<Props> = ({
  dialogProps,
  deliveryPackage,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [submitLoading, setSubmitLoading] = React.useState(false);

  const [{ data: zonesResponse, progress: zonesLoading }] = useApiClient<
    Zone[]
  >(`zones`);

  const [{ data: ordersResponse, progress: ordersLoading }] = useApiClient<
    Order[]
  >(`orders/waiting-for-delivery`);

  const zones = zonesResponse ?? [];
  const orders = ordersResponse ?? [];

  const loading = zonesLoading || ordersLoading || submitLoading;
  const getForm = React.useCallback(function getForm(
    deliveryPackage?: DeliveryPackage,
  ): CustomerForm {
    return {
      packageSize: deliveryPackage?.packageSize ?? undefined,
      zoneId: deliveryPackage?.zoneId ?? undefined,
      orders: deliveryPackage?.orders
        ? Object.entries(deliveryPackage.orders).map(([id, order]) =>
            parseInt(id),
          )
        : [],
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(deliveryPackage));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(deliveryPackage));
    }
  }, [getForm, deliveryPackage, justOpened]);

  return (
    <FormDialog
      title={`${deliveryPackage ? 'Edit' : 'Add'} Delivery Package`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Package Size"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="packageSize"
            value={form.packageSize || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={zones.find((s) => s.id === form.zoneId) || null}
            options={zones}
            getOptionLabel={(option: Zone) => option?.name ?? ''}
            onChange={(e: React.ChangeEvent<{}>, zone: Zone | null) =>
              handleAutocompleteChange(zone?.id ?? null, 'zoneId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="zoneId"
                variant="standard"
                label="Zone"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={orders.filter((s) => form.orders?.includes(s.id)) || null}
            options={getFilteredOrders()}
            multiple
            getOptionLabel={(option: Order) =>
              option
                ? `Order No. ${option.id} - ${option.locality?.title ??
                    'No Locality'}`
                : ''
            }
            onChange={(e: React.ChangeEvent<{}>, orders: Order[] | null) =>
              handleAutocompleteChange(orders?.map((o) => o.id) ?? [], 'orders')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="orders"
                variant="standard"
                label="Orders"
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function getFilteredOrders() {
    const copiedOrders = orders
      .slice()
      .filter((o) => !Boolean(o.deliveryPackageId));

    if (!form.zoneId) {
      return copiedOrders;
    }

    const zone = zones.find((z) => z.id === form.zoneId);

    if (!zone) {
      return copiedOrders;
    }

    return copiedOrders.filter(
      (o) =>
        !o.locality || zone.localities.map((l) => l.id).includes(o.locality.id),
    );
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setSubmitLoading(true);

      const response = await DeliveryPackageService.save({
        id: deliveryPackage?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      snackbar.open('Delivery package has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setSubmitLoading(false);
    }
  }
};
