import { Button, DialogActions } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import * as React from 'react';

export interface DialogControlProps {
  loading?: boolean;

  skipProps?: Partial<ButtonProps>;
  actionName?: string;
  actionProps?: Partial<ButtonProps>;
  actionNameSub?: string;
  actionPropsSub?: Partial<ButtonProps>;

  onClose: DialogProps['onClose'];
  prevStep?(): void;
}

export const DialogControls: React.FC<DialogControlProps> = ({
  loading,
  skipProps,
  onClose,
  actionName,
  actionProps,
  actionNameSub,
  actionPropsSub,
  prevStep,
}) => (
  <DialogActions>
    <Button onClick={onClose} color="default" disabled={loading}>
      Close
    </Button>

    {prevStep && (
      <Button disabled={loading} onClick={(e) => prevStep()} color="default">
        Back
      </Button>
    )}

    {skipProps && (
      <Button {...skipProps} disabled={loading} color="default">
        Skip
      </Button>
    )}

    {actionName && (
      <Button {...actionProps} disabled={loading} color="primary">
        {actionName}
      </Button>
    )}

    {actionNameSub && (
      <Button {...actionPropsSub} disabled={loading} color="primary">
        {actionNameSub}
      </Button>
    )}
  </DialogActions>
);
