import { Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Zone } from 'interfaces';
import * as React from 'react';
import { ZoneService } from 'services';
import { SaveZoneDialog } from './components/SaveZoneDialog';

export const Zones: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [zones, setZones] = React.useState<Zone[]>([]);
  const [selectedZone, setSelectedZone] = React.useState<Zone>();

  React.useEffect(() => {
    loadZones();
  }, []);

  const columns: SortableTableHeader[] = [
    { key: 'zone', label: 'Zone' },
    { key: 'location', label: 'Location' },
    { key: 'controls', sortable: false },
  ];

  const rows = zones.map((zone) => ({
    key: zone.id.toString(),
    cells: [
      { key: 'zone', display: zone.name },
      {
        key: 'location',
        display: zone.localities.map((l) => l.title).join(', '),
      },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={(e) => {
              setModalOpen(true);
              setSelectedZone(zone);
            }}
            onDelete={(e) => {
              setConfirmOpen(true);
              setSelectedZone(zone);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <Paper>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Zones</Typography>

        <ResponsiveIconButton
          onClick={(e) => {
            setSelectedZone(undefined);
            setModalOpen(true);
          }}
          color="primary"
          icon={AddIcon}
        >
          Add Zone
        </ResponsiveIconButton>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No zones available yet."
        emptyTableCreateAction={(e) => setModalOpen(true)}
        disablePagination
      />

      <SaveZoneDialog
        zone={selectedZone}
        refresh={() => loadZones()}
        dialogProps={{
          open: modalOpen,
          onClose: (e) => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'sm',
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this zone?"
        onConfirm={deleteZone}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedZone(undefined);
          },
        }}
      />
    </Paper>
  );

  async function deleteZone() {
    try {
      if (!selectedZone) {
        return;
      }

      const response = await ZoneService.delete(selectedZone.id);

      if (!response) {
        return;
      }

      loadZones();
      snackbar.open('Note has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }

  async function loadZones() {
    const response = await ZoneService.getAll();

    setZones(response ?? []);
  }
};
