import * as React from 'react';
import { DialogProps, Typography, Grid, TextField } from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { NameForm, Location } from 'interfaces';
import { usePrevious } from 'hooks';
import { LocationService } from 'services/LocationService';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  location?: Location;
  locationList: Location[];
  refresh: () => void;
}

export const AddLocationDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  location,
  locationList,
  refresh,
}) => {
  const getForm = React.useCallback(function getForm(
    location?: Location,
  ): NameForm {
    return {
      name: location?.name ?? '',
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(location));
  const [error, setError] = React.useState(false);
  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;
  const snackbar = React.useContext(SnackbarContext);

  const names: string[] = locationList
    .filter((type) => !(location && location.name === type.name))
    .map((type) => type.name.toLocaleLowerCase());

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(location));
    }
  }, [getForm, location, justOpened]);

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Add location</Typography>
        </div>
      }
      submitText={location ? 'Edit location' : 'Add location'}
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Name"
            type="text"
            placeholder="Enter location name"
            fullWidth
            required
            error={error}
            helperText={error ? 'A location with that name already exists' : ''}
            onChange={(e) => {
              names.includes(e.currentTarget.value.toLocaleLowerCase())
                ? setError(true)
                : setError(false);
              handleChange(e);
            }}
            name="name"
            value={form.name}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      if (error) {
        throw new Error('Location name already exists');
      }

      !location
        ? LocationService.postNewLocation(form)
        : LocationService.editNewLocation(location.id, form);

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Location has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }
};
