import * as React from 'react';
import { ApiClient } from 'ApiClient';

export function useApiClient<T>(
  url: string,
  queryStringData?: { [key: string]: any },
): [{ data?: T; progress: boolean; error: string; refetch: () => void }] {
  const [data, setData] = React.useState<T>();
  const [progress, setProgress] = React.useState(true);
  const [error, setError] = React.useState('');

  const loadData = React.useCallback(
    async function loadData() {
      try {
        setProgress(true);
        const response = await ApiClient.get<T>(url, queryStringData);
        if (!response) {
          return;
        }

        setData(response);
      } catch (error) {
        setError(error.message);
      } finally {
        setProgress(false);
      }
    },
    [url, queryStringData],
  );

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return [{ data, progress, error, refetch: loadData }];
}
