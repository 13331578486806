import {
  DialogProps,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { Autocomplete } from '@material-ui/lab';
import { WeekDayNumbers } from 'enums';
import {
  EmptyView,
  FormDialog,
  ResponsiveIconButton,
  SnackbarContext,
} from 'components';
import {
  ShopService
} from 'services';
import { usePrevious } from 'hooks';
import { Shop } from 'interfaces';
import * as React from 'react';
import { useStyles } from './styles';

interface Props {
  dialogProps: DialogProps;
  shop: Shop;
  refresh: () => void;
}

interface WeekDays {
  id: number;
  title: string;
}

const weekDays: WeekDays[] = [
  {
    id: WeekDayNumbers.SUNDAY,
    title: 'Sunday',
  },
  {
    id: WeekDayNumbers.MONDAY,
    title: 'Monday',
  },
  {
    id: WeekDayNumbers.TUESDAY,
    title: 'Tuesday',
  },
  {
    id: WeekDayNumbers.WEDNESDAY,
    title: 'Wednesday',
  },
  {
    id: WeekDayNumbers.THURSDAY,
    title: 'Thursday',
  },
  {
    id: WeekDayNumbers.FRIDAY,
    title: 'Friday',
  },
  {
    id: WeekDayNumbers.SATURDAY,
    title: 'Saturday',
  },
];

interface OpeningHourForm {
  dayOfWeek?: number;
  fromTime: string | null;
  toTime: string | null;
}

interface ShopForm {
  title: string;
  shopId: number;
  openingHours: OpeningHourForm[];
}

export const SaveShopOpeningHoursDialog: React.FC<Props> = ({
  dialogProps,
  shop,
  refresh,
}) => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const getForm = React.useCallback(
    function getForm(shop: Shop): ShopForm {
      return {
        title: shop.title,
        shopId: shop.id,
        openingHours:
          shop.openingHours.map(
            ({ dayOfWeek, fromTime, toTime }) => ({
              dayOfWeek,
              fromTime,
              toTime,
            }),
          ) ?? [],
      };
    },
    [],
  );

  const [form, setForm] = React.useState(getForm(shop));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(shop));
    }
  }, [getForm, shop, justOpened]);

  return (
    <FormDialog
      title="Shop Details"
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            disabled
            name="title"
            value={form.title || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">Shop Opening Hours</Typography>

            <ResponsiveIconButton
              onClick={onAddShopOpeningHour}
              color="primary"
              icon={AddIcon}
            >
              Add Shop Opening Hour
            </ResponsiveIconButton>
          </Toolbar>

          {!form.openingHours || form.openingHours.length === 0 ? (
            <EmptyView>No shop opening hours set</EmptyView>
          ) : (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 200 }}>Week Day</TableCell>
                  <TableCell>Time From</TableCell>
                  <TableCell>Time To</TableCell>
                  <TableCell padding="none" />
                </TableRow>
              </TableHead>

              <TableBody>
                {form.openingHours
                  .map((item, i, arr) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Autocomplete
                          value={
                            weekDays.find((jt) => jt.id === item.dayOfWeek) || null
                          }
                          options={weekDays}
                          getOptionLabel={(option: WeekDays) =>
                            option?.title ?? ''
                          }
                          onChange={(e: any, option: WeekDays | null) =>
                            onchangeOpeningHour(option, 'dayOfWeek', i)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="dayOfWeek"
                              variant="standard"
                              required
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          type="time"
                          value={item.fromTime || ''}
                          onChange={(e) => onchangeOpeningHour(e, 'fromTime', i)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          type="time"
                          value={item.toTime || ''}
                          onChange={(e) => onchangeOpeningHour(e, 'toTime', i)}

                        />
                      </TableCell>

                      <TableCell padding="none">
                        <IconButton
                          className={classes.removeIcon}
                          onClick={(e) => onRemoveOpeningHour(e, i)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );  

  function onAddShopOpeningHour(e: React.MouseEvent<HTMLElement>) {
    const newOpeningHours = form.openingHours?.slice() ?? [];
      newOpeningHours.push({
      dayOfWeek: undefined,
      fromTime: null,
      toTime: null,
    });

    setForm((f) => ({
      ...f,
      openingHours: newOpeningHours,
    }));
  }

  function isWeekDay(
    input:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | WeekDays,
  ): input is WeekDays {
    return input.hasOwnProperty('title');
  }

  function onchangeOpeningHour(
    e:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | ( WeekDays | null),
    name: keyof OpeningHourForm,
    i: number,
  ) {
    let value = undefined;
    if (e && ( isWeekDay(e))) {
      value = e.id;
    } else if (e) {
      value = e.currentTarget.value;
    }

    const newShopOpeningHours = form.openingHours.slice();
    const data = newShopOpeningHours[i];

    switch (name) {
      case 'fromTime':
      case 'toTime':
        data[name] = value ? value.toString() + ':00' : '';
        break;
      case 'dayOfWeek':
        data[name] = value ? parseInt(value.toString(), 10) : undefined;
        break;
      default:
        return;
    }

    setForm((f) => ({
      ...f,
      openingHours: newShopOpeningHours,
    }));
  }

  function onRemoveOpeningHour(e: React.MouseEvent<HTMLElement>, i: number) {
    if (!form.openingHours) {
      return null;
    }

    const newShopOpeningHours = form.openingHours.slice();

    newShopOpeningHours.splice(i, 1);

    setForm((f) => ({
      ...f,
      openingHours: newShopOpeningHours,
    }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);
      const response = await ShopService.save({
        id: shop.id,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Shop details saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
