import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MenuDisplay } from 'views/MenuDisplay';

export const MenuLayout: React.FC = () => (
  <Switch>
    <Route path="/menu/display/:id([0-9]+)">
      <MenuDisplay />
    </Route>

    <Route>
      <Redirect to="/dashboard" />
    </Route>
  </Switch>
);
