import { Paper, Toolbar, Typography } from '@material-ui/core';
import {
  ConfirmDialog,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { JobStatus } from 'enums';
import { JobOrderItem, Order, OrderItem } from 'interfaces';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router-dom';
import { OrderService } from 'services';

interface Params {
  id: string;
}

interface JobState {
  jobOrderItem: JobOrderItem;
  orderItem: Omit<OrderItem, 'jobOrderItems'>;
}

export const OrderJobsView: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id: orderId } = useParams<Params>();

  const snackbar = React.useContext(SnackbarContext);

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [order, setOrder] = React.useState<Order>();
  const [selectedJob, setSelectedJob] = React.useState<JobState>();

  const loadOrders = React.useCallback(
    async function loadOrders(id: string) {
      try {
        const response = await OrderService.getById(id);
        setOrder(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadOrders(orderId);
  }, [orderId, loadOrders]);

  const promise = setTimeout(() => {
    loadOrders(orderId);
    clearTimeout(promise);
  }, 60000);

  React.useEffect(() => {
    return () => clearTimeout(promise);
  }, [promise]);

  const columns: SortableTableHeader[] = [
    { key: 'menuItem', label: 'Menu Item' },
    { key: 'quantity', label: 'Quantity' },
    { key: 'location', label: 'Location' },
    { key: 'assignee', label: 'Assignee' },
    { key: 'startTime', label: 'Start Time' },
    { key: 'endTime', label: 'End Time' },
    { key: 'status', label: 'Status' },
    { key: 'controls', sortable: false },
  ];

  const jobs =
    order?.orderItems.reduce<JobState[]>(
      (prev, { jobOrderItems, ...orderItem }) => {
        return [
          ...prev,
          ...jobOrderItems.map((jobOrderItem) => {
            return {
              jobOrderItem: { ...jobOrderItem },
              orderItem: { ...orderItem },
            };
          }),
        ];
      },
      [],
    ) ?? [];

  const rows = jobs.map((job) => ({
    key: job.jobOrderItem.id.toString(),
    cells: [
      {
        key: 'menuItem',
        display: job.orderItem.menuItem.title,
      },
      {
        key: 'quantity',
        display: job.jobOrderItem.job.quantity,
      },
      {
        key: 'location',
        display: job.jobOrderItem.job.location.name,
      },
      {
        key: 'assignee',
        display: job.jobOrderItem.job.user?.name ?? 'Unassigned',
      },
      {
        key: 'startTime',
        display: job.jobOrderItem.job.startTime || 'N/A',
      },
      {
        key: 'endTime',
        display: job.jobOrderItem.job.endTime || 'N/A',
      },
      {
        key: 'status',
        display: getStatusText(job.jobOrderItem.job.statusId),
      },
      {
        key: 'controls',
        display: (
          <TableControls
            deleteTitle="Cancel"
            onDelete={
              job.jobOrderItem.job.statusId !== JobStatus.CANCELLED
                ? (e) => {
                    setConfirmOpen(true);
                    setSelectedJob(job);
                  }
                : undefined
            }
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      {match && <BreadcrumbsItem to={match.url}> Jobs</BreadcrumbsItem>}
      <Paper style={{ overflow: 'auto' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">
            {order ? `Order No. ${order.id} - ` : ''} Jobs
          </Typography>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No jobs available yet."
          disablePagination
        />

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to cancel this job?"
          onConfirm={deleteJob}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedJob(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  function getStatusText(statusId: number) {
    switch (statusId) {
      case JobStatus.PENDING:
        return (
          <Typography variant="subtitle2" color="secondary">
            Pending
          </Typography>
        );
      case JobStatus.COMPLETED:
        return (
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ color: '#61ab9a' }}
          >
            Completed
          </Typography>
        );
      case JobStatus.IN_PROGRESS:
        return (
          <Typography variant="subtitle2" color="secondary">
            In Progress
          </Typography>
        );
      case JobStatus.CANCELLED:
        return (
          <Typography variant="subtitle2" color="error">
            Cancelled
          </Typography>
        );
    }

    return 'N/A';
  }

  async function deleteJob() {
    try {
      if (!selectedJob) {
        return;
      }

      const response = await OrderService.cancelJob(
        selectedJob.jobOrderItem.job.id,
      );

      if (!response) {
        return;
      }

      loadOrders(orderId);
      snackbar.open('Job has been cancelled successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
