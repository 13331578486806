import {
  DialogProps,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  EmptyView,
  FormDialog,
  FormField,
  ResponsiveIconButton,
  SnackbarContext,
} from 'components';
import { usePrevious } from 'hooks';
import { VehicleType } from 'interfaces';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import * as React from 'react';
import { VehicleTypesService } from 'services';

interface Props {
  dialogProps: DialogProps;
  vehicleType: VehicleType;
  refresh: () => void;
}

interface Form {
  numberOfItems: number;
  amount: number;
  vehicleTypeId: number;
  vehicles: {
    name: string;
    numberPlate: string;
  }[];
}

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    minHeight: 0,
    paddingRight: 0,
    paddingLeft: 0,
  },
  removeIcon: {
    color: theme.palette.danger.main,
  },
  table: {
    marginBottom: theme.spacing(5),
  },
  narrowPadding: {
    padding: theme.spacing(0, 1),
  },
}));

export const SaveVehicleTypeDialog: React.FC<Props> = ({
  dialogProps,
  vehicleType,
  refresh,
}) => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);

  const getForm = React.useCallback(function getForm(
    vehicleType: VehicleType,
  ): Form {
    return {
      numberOfItems: vehicleType.numberOfItems ?? 0,
      amount: vehicleType.amount ?? 0,
      vehicleTypeId: vehicleType.id,
      vehicles: vehicleType.vehicles.map((v) => ({
        name: v.name,
        numberPlate: v.numberPlate,
      })),
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(vehicleType));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(vehicleType));
    }
  }, [getForm, vehicleType, justOpened]);

  return (
    <FormDialog
      title="Edit Vehicle Type"
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <TextField
            label="Number of bags"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="numberOfItems"
            value={form.numberOfItems || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Number of Vehicles"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="amount"
            value={form.amount || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">Vehicles</Typography>

            <ResponsiveIconButton
              onClick={onAddVehicle}
              color="primary"
              icon={AddIcon}
            >
              Add Vehicle
            </ResponsiveIconButton>
          </Toolbar>

          {!form.vehicles || form.vehicles.length === 0 ? (
            <EmptyView>No vehicles set</EmptyView>
          ) : (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.narrowPadding}>
                    Model Name
                  </TableCell>

                  <TableCell className={classes.narrowPadding}>
                    Number Plate
                  </TableCell>

                  <TableCell padding="none" />
                </TableRow>
              </TableHead>
              <TableBody>
                {form.vehicles.map((v, i) => (
                  <TableRow key={i}>
                    <TableCell className={classes.narrowPadding}>
                      <FormField
                        fullWidth
                        value={v.name ?? ''}
                        onChange={(e) => onChangeVehicleDetail(e, 'name', i)}
                      />
                    </TableCell>

                    <TableCell className={classes.narrowPadding}>
                      <FormField
                        fullWidth
                        value={v.numberPlate ?? ''}
                        onChange={(e) =>
                          onChangeVehicleDetail(e, 'numberPlate', i)
                        }
                      />
                    </TableCell>

                    <TableCell padding="none">
                      <IconButton
                        className={classes.removeIcon}
                        onClick={(e) => onRemoveVehicle(e, i)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const response = await VehicleTypesService.save({
        id: vehicleType.id,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Vehicle type has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }

  function onAddVehicle(e: React.MouseEvent<HTMLElement>) {
    const vehicles = form.vehicles?.slice() ?? [];

    vehicles.push({
      name: '',
      numberPlate: '',
    });

    setForm((f) => ({
      ...f,
      vehicles,
    }));
  }

  function onChangeVehicleDetail(
    e: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >,
    name: 'name' | 'numberPlate',
    i: number,
  ) {
    if (!form.vehicles) {
      return null;
    }

    const value = e && e.currentTarget.value;

    const vehicles = form.vehicles.slice();
    const data = vehicles[i];

    switch (name) {
      case 'name':
      case 'numberPlate':
        if (typeof value === 'string') {
          data[name] = value;
        }
        break;

      default:
        return;
    }

    setForm((f) => ({
      ...f,
      vehicles,
    }));
  }

  function onRemoveVehicle(e: React.MouseEvent<HTMLElement>, i: number) {
    if (!form.vehicles) {
      return null;
    }

    const vehicles = form.vehicles.slice();

    vehicles.splice(i, 1);

    setForm((f) => ({ ...f, vehicles }));
  }
};
