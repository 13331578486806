import { Paper } from '@material-ui/core';
import {
  ConfirmDialog,
  SnackbarContext,
  SortableTable,
  TableControls,
  DelayedLinearProgress,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Order } from 'interfaces';
import * as React from 'react';
import { OrderService } from 'services';
import { orderStatusToFriendly } from 'lib/orderStatusToFriendly';
import { OrderStatus } from 'enums';

interface Props {
  isFuture?: boolean;
}

export const OrderPickup: React.FC<Props> = ({ isFuture }) => {
  const snackbar = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<Order>();
  const [orders, setOrders] = React.useState<Order[]>([]);

  React.useEffect(() => {
    setLoading(true);

    Promise.all([OrderService.getOrdersForPickUp()])
      .then(([ordersForPickUpResponse]) => {
        setOrders(ordersForPickUpResponse);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  const columns: SortableTableHeader[] = [
    { key: 'orderNumber', label: 'Order No.' },
    { key: 'dayOrderNumber', label: 'Order No. Of the Day' },
    { key: 'customer', label: 'Customer' },
    { key: 'numberOfItems', label: 'No. of Menu Items' },
    { key: 'numberOfBags', label: 'No. of Bags' },
    { key: 'pickUpComments', label: 'Pick Up Comments' },
    { key: 'status', label: 'Status' },
    { key: 'controls', sortable: false },
  ];

  const rows = orders.map((order) => {
    const rows = {
      key: order.id.toString(),
      cells: [
        {
          key: 'orderNumber',
          display: order.id,
          sortValue: order.id,
        },
        { key: 'dayOrderNumber', display: order.orderNumber ?? 'N/A' },
        {
          key: 'customer',
          display: order.customer ? (
            <span>
              {order.customer.firstName} {order.customer.lastName} <br />{' '}
              {order.address ?? order.customer.address} <br />
              {order.customer.phoneNumber}
            </span>
          ) : (
            ''
          ),
        },
        { key: 'numberOfItems', display: order.orderItems.length },
        { key: 'numberOfBags', display: order.numberOfBags },
        { key: 'pickUpComments', display: order.pickUpComments ?? '' },
        {
          key: 'status',
          display: order.orderStatusId
            ? orderStatusToFriendly(order.orderStatusId)
            : 'Pending',
        },
        {
          key: 'controls',
          display:
            order.orderStatusId !== OrderStatus.DELIVERED ? (
              <TableControls
                readonly
                disabled={
                  order.orderStatusId !== OrderStatus.READY_FOR_DELIVERY
                }
                onPickUp={() => {
                  setConfirmOpen(true);
                  setSelectedOrder(order);
                }}
              />
            ) : (
              ''
            ),
        },
      ],
    };

    return rows;
  });

  return (
    <Paper>
      {loading && <DelayedLinearProgress />}

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No orders yet."
        emptyTableCreateAction={() => setModalOpen(true)}
        defaultSort={{ columnKey: 'created', order: 'asc' }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure the delivery package is picked?"
        onConfirm={setPickUpOrder}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedOrder(undefined);
          },
        }}
      />
    </Paper>
  );

  async function setPickUpOrder() {
    try {
      if (!selectedOrder) {
        return;
      }

      const response = await OrderService.setPickUp(selectedOrder.id);

      if (!response) {
        return;
      }

      setRefresh(!refresh);
      snackbar.open('Pick up picked successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
