import { ApiClient } from 'ApiClient';
import { Customer, MenuItem } from 'interfaces';

class CustomerService {
  public async getAll(): Promise<Customer[]> {
    return ApiClient.get<MenuItem[]>('customers');
  }

  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.put(`customers/${id}`, data);
    }

    return ApiClient.post('customers', data);
  }

  public delete(id: number) {
    return ApiClient.delete(`customers/${id}`);
  }
}

const singleton = new CustomerService();

export { singleton as CustomerService };
