import { DialogProps, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Locality, Zone } from 'interfaces';
import * as React from 'react';
import { LocalityService, ZoneService } from 'services';

interface Props {
  dialogProps: DialogProps;
  zone?: Zone;
  refresh: () => void;
}

interface ZoneForm {
  name?: string;
  localities: number[];
}

export const SaveZoneDialog: React.FC<Props> = ({
  dialogProps,
  zone,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [localities, setLocalities] = React.useState<Locality[]>([]);

  const getForm = React.useCallback(function getForm(zone?: Zone): ZoneForm {
    return {
      name: zone?.name ?? undefined,
      localities: zone?.localities.map((l) => l.id) ?? [],
    };
  }, []);

  const [form, setForm] = React.useState(getForm(zone));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    LocalityService.getAllWithoutZones(zone?.id).then((response) => {
      setLocalities(response);
    });

    if (justOpened) {
      setForm(getForm(zone));
    }
  }, [getForm, zone, justOpened]);

  return (
    <FormDialog
      title={`${zone ? 'Edit' : 'Add'} Zone`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={
              localities.filter((s) => form.localities.includes(s.id)) || null
            }
            options={localities}
            getOptionLabel={(option: Locality) => option?.title ?? ''}
            multiple
            onChange={(e: React.ChangeEvent<{}>, locals: Locality[] | null) =>
              handleAutocompleteChange(
                locals ? locals.map((l) => l.id) : null,
                'localities',
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="localities"
                variant="standard"
                label="Localities"
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const response = await ZoneService.save({
        id: zone?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Zone has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
