import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FaceIcon from '@material-ui/icons/Face';
import KitchenIcon from '@material-ui/icons/Kitchen';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import WebIcon from '@material-ui/icons/Web';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RoomIcon from '@material-ui/icons/Room';
import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PanToolIcon from '@material-ui/icons/PanTool';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { RouteProps } from 'react-router-dom';
import {
  Customers,
  Deliveries,
  Orders,
  Settings,
  Shops,
  Website,
  Preparations,
  Dashboard,
  Menus,
  MenuManagement,
  JobTypes,
  Locations,
  Report,
} from 'views';

interface SubPage {
  path: string;
  headerTitle: string;
  icon: typeof SvgIcon;
  searchTitle: string;
}

// Children not supported by AppLayout
export interface AppRouteProps extends Omit<RouteProps, 'children'> {
  path: string;
  headerTitle: string;
  sidebarName?: React.ReactNode;
  icon: typeof SvgIcon;
  subPages?: SubPage[];
}

export function getRoutes() {
  const routes: AppRouteProps[] = [
    {
      path: '/dashboard',
      headerTitle: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      path: '/menu-sections',
      headerTitle: 'Menu',
      icon: MenuBookIcon,
      component: Menus,
    },
    {
      path: '/customers',
      headerTitle: 'Customers',
      icon: FaceIcon,
      component: Customers,
    },
    {
      path: '/orders',
      headerTitle: 'Orders',
      icon: ReceiptIcon,
      component: Orders,
    },
    {
      path: '/deliveries',
      headerTitle: 'Deliveries',
      icon: LocalShippingIcon,
      component: Deliveries,
    },
    {
      path: '/screens',
      headerTitle: 'Screens',
      icon: OndemandVideoIcon,
      component: MenuManagement,
    },
    {
      path: '/shops',
      headerTitle: 'Shop',
      icon: StorefrontIcon,
      component: Shops,
    },
    {
      path: '/job-type',
      headerTitle: 'Job Types',
      icon: KitchenIcon,
      component: JobTypes,
    },
    {
      path: '/location',
      headerTitle: 'Location',
      icon: RoomIcon,
      component: Locations,
    },
    {
      path: '/settings',
      headerTitle: 'Settings',
      icon: SettingsIcon,
      component: Settings,
    },
    {
      path: '/website',
      headerTitle: 'Website',
      icon: WebIcon,
      component: Website,
    },
    {
      path: '/preparation',
      headerTitle: 'Preparations',
      icon: PanToolIcon,
      component: Preparations,
    },
    {
      path: '/report',
      headerTitle: 'Reports',
      icon: AssessmentIcon,
      component: Report,
    },
  ];

  return routes;
}
