import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { MenuCategoryView } from './MenuCategoryView';
import { MenuItemView } from './MenuItemView';
import { MenuSectionView } from './MenuSectionView';

export const MenuSwitch: React.FC<RouteComponentProps<{ id?: string }>> = ({
  match: { url, path },
}) => (
  <React.Fragment>
    <BreadcrumbsItem to={url}>Menu Sections</BreadcrumbsItem>

    <Switch>
      <Route exact path={path}>
        <MenuSectionView />
      </Route>

      <Route path={`${path}/:id([0-9]+)/categories/:categoryId([0-9]+)/items`}>
        <MenuItemView />
      </Route>

      <Route path={`${path}/:id([0-9]+)/categories`}>
        <MenuCategoryView />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  </React.Fragment>
);
