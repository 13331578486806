export const DateFormat = {
  LONG: 'eeee, d MMMM yyyy',
  LONG_NO_YEAR: 'eeee, d MMMM',
  LONG_DAY: 'eeee d',
  MEDIUM: 'd MMMM yyyy',
  MEDIUM_DAY: 'eee d',
  SHORT: 'dd/MM/yyyy',

  DAY_MONTH: 'd MMM',
  YEAR_ONLY: 'yyyy',
  DAY_ONLY: 'dd',

  SHORT_DAY_NAME_ONLY: 'eeeeee',
  MONTH_NAME_ONLY: 'MMMM',
  MONTH_NAME_YEAR: 'MMMM yyyy',

  INPUT: 'yyyy-MM-dd',
  GQL: 'yyyy-MM-dd',
  ISO: 'yyyy-MM-dd',
};

export const TimeFormat = {
  LONG: 'HH:mm',
  MEDIUM: 'HH:mm',
  SHORT: 'HH:mm',

  INPUT: 'HH:mm',
  MYSQL_TIME: 'HH:mm:ss',
  GQL: 'HH:mm:ss.SSSXXX',
};

export const DateTimeFormat = {
  LONG: `${DateFormat.LONG} ${TimeFormat.LONG}`,
  MEDIUM: `${DateFormat.MEDIUM} ${TimeFormat.MEDIUM}`,
  SHORT: `${DateFormat.SHORT} ${TimeFormat.SHORT}`,

  GQL: `${DateFormat.GQL}'T'${TimeFormat.GQL}`,
  INPUT: `${DateFormat.INPUT} ${TimeFormat.MYSQL_TIME}`,
};
