import { capitalise } from './Utility';
import { OrderStatus } from 'enums';

export function orderStatusToFriendly(statusId: number) {
  switch (statusId) {
    case OrderStatus.READY_FOR_DELIVERY:
      return 'Ready for delivery';
    case OrderStatus.OUT_FOR_DELIVERY:
      return 'Out for delivery';
    case OrderStatus.DELIVERED:
      return 'Delivered';
    case OrderStatus.COMPLETED:
      return 'Completed';
    case OrderStatus.READY_FOR_PACKING:
      return 'Ready for packing'
    case OrderStatus.WAITING_FOR_PICKUP:
        return 'Waiting for pickup'
    default:
      return capitalise(OrderStatus[statusId]);
  }
}
