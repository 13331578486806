import { Paper, Toolbar, Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
  RouterLink,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Screen } from 'interfaces';
import * as React from 'react';
import { ScreenService } from 'services';
import { SaveScreenDialog } from '../components/SaveScreenDialog';
import { Config } from 'config';
import { capitalise } from 'lib';

interface Params {
  id: string;
  categoryId: string;
}

export const MenuScreenView: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [screens, setScreens] = React.useState<Screen[]>([]);
  const [selectedScreen, setSelectedScreen] = React.useState<Screen>();

  const loadScreens = React.useCallback(
    async function loadScreens() {
      try {
        const response = await ScreenService.getAll();
        setScreens(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadScreens();
  }, [loadScreens]);

  const columns: SortableTableHeader[] = [
    { key: 'title', label: 'Title' },
    { key: 'image', label: 'Image' },
    { key: 'imagePosition', label: 'Image Position' },
    { key: 'screenNumber', label: 'Screen Number' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    screens.map((screen) => ({
      key: screen.id.toString(),
      cells: [
        {
          key: 'title',
          display: (
            <Button
              component={RouterLink}
              to={`/screens/${screen.id.toString()}/sections`}
              variant="text"
              size="small"
              style={{ minWidth: 'auto' }}
              color="secondary"
            >
              {screen.name}
            </Button>
          ),
          sortValue: screen.name,
        },
        {
          key: 'image',
          display: screen.imageUrl ? (
            <img
              src={`${Config.IMAGE_CDN_URL}${screen.imageUrl}`}
              alt=""
              style={{ height: 80 }}
            />
          ) : (
            'N/A'
          ),
        },
        {
          key: 'imagePosition',
          display: screen.imagePosition ? capitalise(screen.imagePosition) : '',
        },
        { key: 'screenNumber', display: screen.screenNumber },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedScreen(screen);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedScreen(screen);
              }}
              previewUrl={`/menu/display/${screen.id}`}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      <Paper style={{ overflow: 'auto' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Screens</Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedScreen(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Screen
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No menu screens available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination
        />

        <SaveScreenDialog
          screen={selectedScreen}
          refresh={() => loadScreens()}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'xs',
          }}
        />

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this screen menu?"
          onConfirm={deleteScreen}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedScreen(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deleteScreen() {
    try {
      if (!selectedScreen) {
        return;
      }

      const response = await ScreenService.delete(selectedScreen.id);

      if (!response) {
        return;
      }

      loadScreens();
      snackbar.open('Screen menu has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
