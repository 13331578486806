import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ReportListView, UsersTimeLogReport, JobsReport } from '.';

export const ReportSwitch: React.FC<RouteComponentProps<{ id?: string }>> = ({
  match: { url, path },
}) => (
  <React.Fragment>
    <BreadcrumbsItem to={url}>Reports</BreadcrumbsItem>

    <Switch>
      <Route exact path={path}>
        <ReportListView />
      </Route>

      <Route path={`${path}/user-time-logs`}>
        <UsersTimeLogReport />
      </Route>

      <Route path={`${path}/jobs`}>
        <JobsReport />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  </React.Fragment>
);
