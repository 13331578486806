import * as React from 'react';
import { DialogProps, Grid, TextField } from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { GallerySection } from 'interfaces';
import { GalleryService } from 'services/GalleryService';

interface Props {
  dialogProps: DialogProps;
  gallerySection?: GallerySection;
  refresh: () => void;
}

interface MenuSectionForm {
  title?: string;
  rank?: number;
}

export const SaveGallerySectionDialog: React.FC<Props> = ({
  dialogProps,
  gallerySection,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);

  const getForm = React.useCallback(function getForm(
    gallerySection?: GallerySection,
  ): MenuSectionForm {
    return {
      title: gallerySection?.title ?? undefined,
      rank: gallerySection?.rank ?? undefined,
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(gallerySection));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(gallerySection));
    }
  }, [getForm, gallerySection, justOpened]);

  return (
    <FormDialog
      title={`${gallerySection ? 'Edit' : 'Add'} Gallery Section`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="title"
            value={form.title || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Rank"
            type="number"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="rank"
            value={form.rank || ''}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);
      const response = await GalleryService.saveSection({
        id: gallerySection?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Gallery section has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
