import * as React from 'react';
import { DialogProps, Typography, Grid, TextField } from '@material-ui/core';
import { UserType, NameForm } from 'interfaces';
import { usePrevious } from 'hooks';
import { SnackbarContext, FormDialog } from 'components';
import { UserService } from 'services';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  userType?: UserType;
  userTypeList: UserType[];
  refresh: () => void;
}

export const AddUserTypeDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  userType,
  userTypeList,
  refresh,
}) => {
  const getForm = React.useCallback(function getForm(
    userType?: UserType,
  ): NameForm {
    return {
      name: userType?.name ?? '',
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(userType));
  const [error, setError] = React.useState(false);
  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;
  const snackbar = React.useContext(SnackbarContext);

  const names: string[] = userTypeList
    .filter((type) => (userType && userType.name === type.name ? false : true))
    .map((type) => type.name.toLocaleLowerCase());

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(userType));
    }
  }, [getForm, userType, justOpened]);

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Add User Type</Typography>
        </div>
      }
      submitText={userType ? 'Edit user' : 'Add user'}
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Title"
            type="text"
            placeholder="Enter title"
            fullWidth
            required
            error={error}
            helperText={error ? 'User Type title already exists' : ''}
            onChange={(e) => {
              names.includes(e.currentTarget.value.toLocaleLowerCase())
                ? setError(true)
                : setError(false);
              handleChange(e);
            }}
            name="name"
            value={form.name}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      if (error) {
        throw new Error('User Type title already exists');
      }

      !userType
        ? UserService.postNewUserType(form)
        : UserService.editNewUserType(userType.id ?? 0, form);

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('User type has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
