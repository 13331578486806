import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { LocationJobTypesView, LocationsView } from '.';

export const LocationSwitch: React.FC<RouteComponentProps<{ id?: string }>> = ({
  match: { url, path },
}) => (
  <React.Fragment>
    <BreadcrumbsItem to={url}>Locations</BreadcrumbsItem>

    <Switch>
      <Route exact path={path}>
        <LocationsView />
      </Route>

      <Route path={`${path}/:id([0-9]+)/job-types`}>
        <LocationJobTypesView />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  </React.Fragment>
);
