import * as React from 'react';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { GallerySectionView } from './GallerySectionView';
import { GalleryView } from './GalleryView/GalleryView';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

export const GallerySwitch: React.FC = () => {
  const [match] = React.useState(useRouteMatch());
  const loc = useLocation();

  return (
    <React.Fragment>
      <BreadcrumbsItem to={match.url}>Gallery Sections</BreadcrumbsItem>

      <Switch>
        <Route exact path={match.path}>
          {loc.hash.match('#gallery-section/([0-9]+)/gallery') ? (
            <GalleryView />
          ) : (
            <GallerySectionView />
          )}
        </Route>
      </Switch>
    </React.Fragment>
  );
};
