import { Typography, Paper, Toolbar } from '@material-ui/core';
import * as React from 'react';
import { Location, ShopLocation, Shop } from 'interfaces';
import {
  DelayedLinearProgress,
  ConfirmDialog,
  ResponsiveIconButton,
  SortableTable,
  TableControls,
  AddLocationDialog,
  AddShopLocationDialog,
  SnackbarContext,
} from 'components';
import { LocationService } from 'services/LocationService';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useRouteMatch, useParams } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import LinkIcon from '@material-ui/icons/Link';
import { ShopService, ShopLocationsService } from 'services';

const columns: SortableTableHeader[] = [
  { key: 'name', label: 'Location' },
  { key: 'controls', sortable: false },
];

interface Params {
  id: string;
}

export const ShopLocationView: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id } = useParams<Params>();

  const [loading, setLoading] = React.useState(true);
  const [shopLocations, setShopLocations] = React.useState([]);
  const [shop, setShop] = React.useState<Shop>();
  const [locations, setLocations] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editLocation, setEditLocation] = React.useState<Location>();
  const [deleteLocation, setDeleteLocation] = React.useState<Location>();
  const [deleteShopLocation, setDeleteShopLocation] = React.useState<
    ShopLocation
  >();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirmRemove, setOpenConfirmRemove] = React.useState(false);
  const snackbar = React.useContext(SnackbarContext);

  const loadData = React.useCallback(
    async function loadData(id: string) {
      try {
        setLoading(true);
        await Promise.all([
          getShopLocations(id),
          getShops(id),
          getLocations(),
        ]);
      } catch (error) {
        snackbar.error(error);
      } finally {
        setLoading(false);
      }
    },
    [snackbar],
  );

  React.useEffect(() => { 
    loadData(id);
  }, [id, loadData]);

  const rows = shopLocations.map((shopLocation: ShopLocation) => ({
    key: shopLocation.id.toString(),
    cells: [
      { key: 'name', display: shopLocation.location.name },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={() => {
              setEditLocation(shopLocation.location);
              setOpenEdit(true);
            }}
            onDelete={() => {
              setOpenConfirm(true);
              setDeleteLocation(shopLocation.location);
            }}
            onRemoveLink={() => {
              setOpenConfirmRemove(true);
              setDeleteShopLocation(shopLocation);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      {match && <BreadcrumbsItem to={match.url}>{shop?.title}</BreadcrumbsItem>}
      <DelayedLinearProgress loading={loading} />
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">{shop?.title} locations Table</Typography>
          <ResponsiveIconButton
            onClick={() => setOpen(true)}
            color="primary"
            icon={LinkIcon}
          >
            Link Location
          </ResponsiveIconButton>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          loading={loading}
          emptyTableText="No locations available yet."
          emptyTableCreateAction={() => setOpen(true)}
          disablePagination
        />
      </Paper>
      <AddLocationDialog
        loading={loading}
        dialogProps={{
          open: openEdit,
          onClose: () => setOpenEdit(false),
          fullWidth: true,
        }}
        location={editLocation}
        refresh={() => {
          loadData(id);
        }}
        locationList={locations}
      />
      <ConfirmDialog
        title="Are you sure you want to delete this location?"
        dialogProps={{
          open: openConfirm,
          onClose: () => {
            setOpenConfirm(false);
            setDeleteLocation(undefined);
          },
        }}
        onConfirm={() => {
          if (!deleteLocation) return;
          LocationService.deleteLocation(deleteLocation.id);
          loadData(id);
          snackbar.open('Location was deleted successfully.');
        }}
      />
      <AddShopLocationDialog
        dialogProps={{
          open: open,
          onClose: () => setOpen(false),
          fullWidth: true,
        }}
        locationList={locations}
        shopId={+id}
        shopLocationList={shopLocations}
        loading={loading}
        refresh={() => {
          loadData(id);
        }}
      />
      <ConfirmDialog
        title="Are you sure you want to unlink this location from this shop?"
        dialogProps={{
          open: openConfirmRemove,
          onClose: () => {
            setOpenConfirmRemove(false);
            setDeleteShopLocation(undefined);
          },
        }}
        onConfirm={() => {
          if (!deleteShopLocation) return;
          ShopLocationsService.deleteShopLocation(deleteShopLocation.id);
          loadData(id);
          snackbar.open('Location was unlinked from this shop successfully.');
        }}
      />
    </React.Fragment>
  );

  function getShopLocations(id: string)
  {
    ShopLocationsService.getLocationsByShopId(+id).then((response) => {
      setShopLocations(response);
    });
  }

  function getShops(id: string)
  {
    ShopService.getShops().then((response: Shop[]) => {
      setShop(response.find((shop) => shop.id === +id));
    });
  }

  function getLocations()
  {
    LocationService.getLocations().then((response) => {
      setLocations(response);
    });
  }
};
