import { Typography } from '@material-ui/core';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ForgotPassword, Login, ResetPassword } from 'views';
import { useStyles } from './styles';

export const AuthLayout: React.FC = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.authContainer}>
          <React.Fragment>
            <div className={classes.logoContainer}>
              <Typography variant="h5">Peking</Typography>
            </div>
            <div>
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>

                <Route path="/forgot-password">
                  <ForgotPassword />
                </Route>

                <Route path="/reset-password/:code">
                  <ResetPassword />
                </Route>

                <Route>
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </div>
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};
