import { ApiClient } from 'ApiClient';

class ShopLocationsService {
  public async getLocationsByShopId(id: number) {
    return ApiClient.get(`shop-locations/by-shop/${id}`);
  }

  public async deleteShopLocation(id: number) {
    return ApiClient.delete(`shop-locations/${id}`);
  }

  public async postNewShopLocation(locationId: number, shopId: number) {
    return ApiClient.post('shop-locations', {
      shopId: shopId,
      locationId: locationId,
    });
  }

  public getShopLocations() {
    return ApiClient.get('shop-locations');
  }
}

const singleton = new ShopLocationsService();

export { singleton as ShopLocationsService };
