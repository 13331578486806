import { Paper, Toolbar, Typography } from '@material-ui/core';
import { SortableTable, TableControls } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { VehicleType } from 'interfaces';
import { underscoreCaseToWords } from 'lib';
import * as React from 'react';
import { VehicleTypesService } from 'services';
import { SaveVehicleTypeDialog } from './components/SaveVehicleTypeDialog';

export const VehicleTypes: React.FC = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [vehicleTypes, setVehicleTypes] = React.useState<VehicleType[]>([]);
  const [selectedVehicleType, setSelectedVehicleType] = React.useState<
    VehicleType
  >();

  React.useEffect(() => {
    loadVehicleTypes();
  }, []);

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'numberOfItems', label: 'Number of bags' },
    { key: 'amount', label: 'Number of Vehicles' },
    { key: 'controls', sortable: false },
  ];

  const rows = vehicleTypes.map((vehicleType) => ({
    key: vehicleType.id.toString(),
    cells: [
      { key: 'name', display: underscoreCaseToWords(vehicleType.name) },
      { key: 'numberOfItems', display: vehicleType.numberOfItems },
      { key: 'amount', display: vehicleType.amount },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={(e) => {
              setModalOpen(true);
              setSelectedVehicleType(vehicleType);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <Paper style={{ overflow: 'auto' }}>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Vehicle Types</Typography>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No vehicles available yet."
        disablePagination
      />

      {selectedVehicleType && (
        <SaveVehicleTypeDialog
          vehicleType={selectedVehicleType}
          refresh={() => loadVehicleTypes()}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />
      )}
    </Paper>
  );

  async function loadVehicleTypes() {
    const response = await VehicleTypesService.getAll();

    setVehicleTypes(response ?? []);
  }
};
