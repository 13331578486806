import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  RouterLink,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { GallerySection } from 'interfaces';
import * as React from 'react';
import { GalleryService } from 'services/GalleryService';
import { SaveGallerySectionDialog } from './components';

export const GallerySectionView: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [gallerySections, setGallerySections] = React.useState<
    GallerySection[]
  >([]);
  const [selectedGallerySection, setSelectedGallerySection] = React.useState<
    GallerySection
  >();

  const loadSections = React.useCallback(
    async function loadSections() {
      try {
        const response = await GalleryService.getSections();
        setGallerySections(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadSections();
  }, [loadSections]);

  const columns: SortableTableHeader[] = [
    { key: 'title', label: 'Title' },
    { key: 'rank', label: 'Rank' },
    { key: 'controls', sortable: false },
  ];

  const rows = gallerySections.map((gallerySection) => ({
    key: gallerySection.id ? gallerySection.id.toString() : '',
    cells: [
      {
        key: 'title',
        display: (
          <Button
            component={RouterLink}
            to={`website#gallery-section/${gallerySection.id.toString()}/gallery`}
            variant="text"
            size="small"
            style={{ minWidth: 'auto' }}
            color="secondary"
          >
            {gallerySection.title}
          </Button>
        ),
        sortValue: gallerySection.title,
      },
      { key: 'rank', display: gallerySection.rank },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={() => {
              setModalOpen(true);
              setSelectedGallerySection(gallerySection);
            }}
            onDelete={() => {
              setConfirmOpen(true);
              setSelectedGallerySection(gallerySection);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <Paper>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Gallery Sections</Typography>

        <ResponsiveIconButton
          onClick={() => {
            setSelectedGallerySection(undefined);
            setModalOpen(true);
          }}
          color="primary"
          icon={AddIcon}
        >
          Add Menu Section
        </ResponsiveIconButton>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No gallery sections available yet."
        emptyTableCreateAction={() => setModalOpen(true)}
        disablePagination
      />

      <SaveGallerySectionDialog
        gallerySection={selectedGallerySection}
        refresh={() => loadSections()}
        dialogProps={{
          open: modalOpen,
          onClose: () => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'xs',
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this gallery section?"
        onConfirm={deleteGallerySection}
        dialogProps={{
          open: confirmOpen,
          onClose: () => {
            setConfirmOpen(false);
            setSelectedGallerySection(undefined);
          },
        }}
      />
    </Paper>
  );

  async function deleteGallerySection() {
    try {
      if (!selectedGallerySection) {
        return;
      }

      const response = await GalleryService.deleteSection(
        selectedGallerySection.id,
      );

      if (!response) {
        return;
      }

      loadSections();
      snackbar.open('Gallery section has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
