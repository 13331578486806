import { ApiClient } from 'ApiClient';
import { Gallery, GallerySection } from 'interfaces';

class GalleryService {
  public getAll(): Promise<Gallery[]> {
    return ApiClient.get<Gallery[]>('galleries');
  }

  public getSections(): Promise<GallerySection[]> {
    return ApiClient.get<GallerySection[]>('gallery-sections');
  }

  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.post(`galleries/update/${id}`, data);
    }

    return ApiClient.post('galleries', data);
  }

  public deleteSection(id: number) {
    return ApiClient.delete(`gallery-sections/${id}`);
  }

  public saveSection({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.post(`gallery-sections/update/${id}`, data);
    }

    return ApiClient.post('gallery-sections', data);
  }

  public async getBySection(gallerySectionId: string): Promise<GallerySection> {
    const response: GallerySection = await ApiClient.get<GallerySection>(
      `gallery-sections/${gallerySectionId}`,
    );

    return response;
  }
}

const singleton = new GalleryService();

export { singleton as GalleryService };
