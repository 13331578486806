import { ApiClient } from 'ApiClient';
import { Screen } from 'interfaces';

class ScreenService {
  public getAll(): Promise<Screen[]> {
    return ApiClient.get<Screen[]>('screens');
  }

  public getById(id: string): Promise<Screen> {
    return ApiClient.get<Screen>(`screens/${id}`);
  }

  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.post(`screens/update/${id}`, data);
    }

    return ApiClient.post('screens', data);
  }

  public delete(id: number) {
    return ApiClient.delete(`screens/${id}`);
  }
}

const singleton = new ScreenService();

export { singleton as ScreenService };
