import {
  Button,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  DelayedLinearProgress,
  ResponsiveIconButton,
  RouterLink,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Config } from 'config';
import { MenuSection, Shop } from 'interfaces';
import * as React from 'react';
import { MenuSectionService, ShopService } from 'services';
import { SaveMenuSectionDialog } from '../components/SaveMenuSectionDialog';

export const MenuSectionView: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [shops, setShops] = React.useState<Shop[]>([]);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [menuSections, setMenuSections] = React.useState<MenuSection[]>([]);
  const [selectedMenuSection, setSelectedMenuSection] = React.useState<
    MenuSection
  >();
  const [shopId, setShopId] = React.useState<number | null>(null);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([MenuSectionService.get(), ShopService.getShops()])
      .then(([sectionsResponse, shopsResponse]) => {
        setMenuSections(sectionsResponse);
        setShops(shopsResponse);
      })
      .finally(() => setLoading(false));
  }, [refresh]);

  const columns: SortableTableHeader[] = [
    { key: 'title', label: 'Title' },
    { key: 'image', label: 'Image' },
    { key: 'rank', label: 'Rank' },
    { key: 'shop', label: 'Shop' },
    { key: 'controls', sortable: false },
  ];

  const rows = menuSections
    .filter(
      (menuSection) =>
        !shopId || menuSection.shop.id.toString() === shopId.toString(),
    )
    .map((menuSection) => ({
      key: menuSection.id ? menuSection.id.toString() : '',
      cells: [
        {
          key: 'title',
          display: (
            <Button
              component={RouterLink}
              to={`/menu-sections/${menuSection.id.toString()}/categories`}
              variant="text"
              size="small"
              style={{ minWidth: 'auto' }}
              color="secondary"
            >
              {menuSection.title}
            </Button>
          ),
          sortValue: menuSection.title,
        },
        {
          key: 'image',
          display: menuSection.imageUrl ? (
            <img
              src={`${Config.IMAGE_CDN_URL}${menuSection.imageUrl}`}
              alt=""
              style={{ height: 80 }}
            />
          ) : (
            'N/A'
          ),
        },
        { key: 'rank', display: menuSection.rank },
        { key: 'shop', display: menuSection.shop.title },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedMenuSection(menuSection);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedMenuSection(menuSection);
              }}
            />
          ),
        },
      ],
    }));

  return (
    <Paper style={{ overflow: 'auto' }}>
      {loading && <DelayedLinearProgress />}
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Menu Sections</Typography>

        <div style={{ display: 'flex' }}>
          <TextField
            select
            SelectProps={{ native: true }}
            style={{ flex: 1, marginRight: 8, width: 120 }}
            onChange={(e: any) => setShopId(e.target.value)}
          >
            <option value="">All Shops</option>

            {shops.map((d) => (
              <option value={d.id} key={d.id}>
                {d.title}
              </option>
            ))}
          </TextField>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedMenuSection(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Menu Section
          </ResponsiveIconButton>
        </div>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No menu sections available yet."
        emptyTableCreateAction={(e) => setModalOpen(true)}
        disablePagination
      />

      <SaveMenuSectionDialog
        menuSection={selectedMenuSection}
        menuSections={menuSections}
        refresh={() => setRefresh(!refresh)}
        dialogProps={{
          open: modalOpen,
          onClose: (e) => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'xs',
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this menu section?"
        onConfirm={deleteMenuSection}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedMenuSection(undefined);
          },
        }}
      />
    </Paper>
  );

  async function deleteMenuSection() {
    try {
      if (!selectedMenuSection) {
        return;
      }

      const response = await MenuSectionService.delete(selectedMenuSection.id);

      if (!response) {
        return;
      }

      setRefresh(!refresh);
      snackbar.open('Menu section has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
