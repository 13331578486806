import { ApiClient } from 'ApiClient';
import { Screen } from 'interfaces';

class ScreenSectionService {
  public get(screenId: string | number): Promise<Screen> {
    return ApiClient.get<Screen>(`screens/${screenId}`);
  }

  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.put(`screen-sections/${id}`, data);
    }

    return ApiClient.post('screen-sections', data);
  }

  public delete(id: number) {
    return ApiClient.delete(`screen-sections/${id}`);
  }
}

const singleton = new ScreenSectionService();

export { singleton as ScreenSectionService };
