import { ApiClient } from 'ApiClient';
import { Locality } from 'interfaces';

class LocalityService {
  public getAll(): Promise<Locality[]> {
    return ApiClient.get<Locality[]>('localities');
  }

  public getAllWithoutZones(zoneId?: number): Promise<Locality[]> {
    return ApiClient.get<Locality[]>(
      `localities/without-zones`,
      zoneId ? { zone_id: zoneId } : undefined,
    );
  }

  public save({ id, data }: { id: number; data: any }) {
    if (id) {
      return ApiClient.post(`localities/update/${id}`, data);
    }
  }
}

const singleton = new LocalityService();

export { singleton as LocalityService };
