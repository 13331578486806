import {
  DialogProps,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import * as React from 'react';
import { DialogControls } from 'components';
import { useReactToPrint } from 'react-to-print';
import { PrintOrderReceipt } from 'components';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  successColor: {
    color: theme.palette.success.main,
  },
  centreAlign: {
    textAlign: 'center',
  },
}));

interface Props {
  orderId?: number;
  dialogProps: DialogProps;
}

export const FinishStep: React.FC<Props> = ({ dialogProps, orderId }) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const classes = useStyles();
  const [isPrinted, setIsPrinted] = React.useState(false);

  React.useEffect(() => {
    if (isPrinted) {
      return;
    }

    if (handlePrint) {
      setTimeout(() => {
        setIsPrinted(true);
        orderId && handlePrint();
      }, 1000);
    }
  }, [isPrinted, handlePrint, orderId]);

  return (
    <React.Fragment>
      <DialogContent className={classes.centreAlign}>
        <CheckCircleOutlineIcon
          style={{ fontSize: 60 }}
          className={classes.successColor}
        />
        <br />
        <Typography variant="h6" className={classes.successColor}>
          Order has been placed!
        </Typography>
      </DialogContent>

      <DialogControls onClose={dialogProps.onClose} />

      {orderId && (
        <div hidden>
          <div ref={componentRef}>
            <PrintOrderReceipt orderId={orderId} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
