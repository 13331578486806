import {
  DialogContent,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Autocomplete } from '@material-ui/lab';
import { ResponsiveIconButton } from 'components';
import { DialogControlProps, DialogControls } from 'components/DialogControls';
import { Customer, Shop } from 'interfaces';
import * as React from 'react';
import { SaveCustomerDialog } from 'views/Customers/components/SaveCustomerDialog';
import { OrderForm } from './SaveOrderDialog';

interface Props extends DialogControlProps {
  customers: Customer[];
  shops: Shop[];
  readonly?: boolean;
  form: OrderForm;
  setForm: React.Dispatch<React.SetStateAction<OrderForm>>;
  refreshDropdown: () => void;
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    minHeight: 0,
    marginTop: theme.spacing(1),
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

export const CustomerStep: React.FC<Props> = ({
  customers,
  shops,
  readonly,
  form,
  setForm,
  refreshDropdown,
  ...dialogControlProps
}) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);
  const selectedCustomer = customers.find((c) => c.id === form.customerId);

  return (
    <React.Fragment>
      <DialogContent>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6"></Typography>

          <ResponsiveIconButton
            onClick={(e) => setModalOpen(true)}
            color="primary"
            icon={AddIcon}
            disabled={readonly}
          >
            Add Customer
          </ResponsiveIconButton>
        </Toolbar>
        <Autocomplete
          value={shops.find((c) => c.id === form.shopId) || null}
          options={shops}
          getOptionLabel={(option: Shop) => option?.title ?? ''}
          onChange={(e: React.ChangeEvent<{}>, shop: Shop | null) =>
            setForm((f) => ({ ...f, shopId: shop?.id ?? undefined }))
          }
          disabled={readonly}
          renderInput={(params) => (
            <TextField
              {...params}
              name="shopId"
              variant="standard"
              label="Shop"
              required
            />
          )}
        />

        <br />

        <Autocomplete
          value={customers.find((c) => c.id === form.customerId) || null}
          options={customers}
          getOptionLabel={(option: Customer) =>
            option
              ? `${option.firstName} ${option.lastName || ''} - ${
                  option.phoneNumber
                }`
              : ''
          }
          disabled={readonly}
          onChange={(e: React.ChangeEvent<{}>, customer: Customer | null) => {
            setForm((f) => ({
              ...f,
              customerId: customer?.id,
              address: customer?.address,
              latitude: customer?.latitude,
              longitude: customer?.longitude,
              localityId: customer?.locality.id,
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="customerId"
              variant="standard"
              label="Customer"
            />
          )}
        />

        <br />

        {selectedCustomer ? (
          <React.Fragment>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6">
                {selectedCustomer.firstName} {selectedCustomer.lastName}
              </Typography>

              <ResponsiveIconButton
                onClick={(e) => setModalOpen(true)}
                color="primary"
                icon={AddIcon}
                disabled={readonly}
              >
                Edit Customer
              </ResponsiveIconButton>
            </Toolbar>
            <Typography>
              <strong>Address</strong>:{' '}
              {(form?.orderAddress ?? selectedCustomer.address) || 'N/A'}
            </Typography>
            <Typography>
              <strong>Location</strong>:{' '}
              {(form?.orderLocality ?? selectedCustomer.locality.title) ||
                'N/A'}
            </Typography>
            <Typography>
              <strong>Phone Number</strong>: {selectedCustomer.phoneNumber}
            </Typography>
          </React.Fragment>
        ) : null}

        <br />
      </DialogContent>

      <SaveCustomerDialog
        refresh={() => refreshDropdown()}
        customer={selectedCustomer || undefined}
        setCustomer={(customer) => {
          setForm((f) => ({ ...f, customerId: customer.id }));
        }}
        dialogProps={{
          open: modalOpen,
          onClose: (e) => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'md',
        }}
      />

      <DialogControls {...dialogControlProps} />
    </React.Fragment>
  );
};
