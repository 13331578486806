import { Typography, Paper, Toolbar, Button } from '@material-ui/core';
import * as React from 'react';
import { JobType } from 'interfaces';
import {
  DelayedLinearProgress,
  ConfirmDialog,
  ResponsiveIconButton,
  SortableTable,
  SnackbarContext,
  TableControls,
  RouterLink,
} from 'components';
import AddIcon from '@material-ui/icons/Add';
import { JobService } from 'services/JobService';
import { AddJobTypeDialog } from 'components/AddJobTypeDialog';
import { SortableTableHeader } from 'components/SortableTable/components';

const columns: SortableTableHeader[] = [
  { key: 'name', label: 'Job Name' },
  { key: 'controls', sortable: false },
];

export const JobTypesView: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(true);
  const [jobTypes, setJobTypes] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editJobType, setEditJobType] = React.useState<JobType>();
  const [deleteJobType, setDeleteJobType] = React.useState<JobType>();
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const loadJobTypes = React.useCallback(
    async function loadJobTypes() {
      try {
        const response = await JobService.getJobTypes();
        setJobTypes(response);
        setLoading(false);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadJobTypes();
  }, [loadJobTypes]);

  const rows = jobTypes.map((jobType: JobType) => ({
    key: jobType.id.toString(),
    cells: [
      {
        key: 'name',
        display: (
          <Button
            component={RouterLink}
            to={`/job-type/${jobType.id.toString()}/locations`}
            variant="text"
            size="small"
            color="secondary"
          >
            {jobType.name}
          </Button>
        ),
        sortValue: jobType.name,
      },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={(e) => {
              setEditJobType(jobType);
              setOpenEdit(true);
            }}
            onDelete={(e) => {
              setOpenConfirm(true);
              setDeleteJobType(jobType);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      <DelayedLinearProgress loading={loading} />
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Job Types Table</Typography>
          <ResponsiveIconButton
            onClick={() => setOpen(true)}
            color="primary"
            icon={AddIcon}
          >
            Add Job Type
          </ResponsiveIconButton>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          // loading={queryLoading}
          emptyTableText="No job types available yet."
          emptyTableCreateAction={(e) => setOpen(true)}
          disablePagination
        />
      </Paper>
      <AddJobTypeDialog
        loading={loading}
        dialogProps={{
          open: open,
          onClose: () => setOpen(false),
          fullWidth: true,
        }}
        refresh={() => loadJobTypes()}
        jobTypeList={jobTypes}
      />
      <AddJobTypeDialog
        loading={loading}
        dialogProps={{
          open: openEdit,
          onClose: () => setOpenEdit(false),
          fullWidth: true,
        }}
        jobType={editJobType}
        refresh={() => loadJobTypes()}
        jobTypeList={jobTypes}
      />
      <ConfirmDialog
        title="Are you sure you want to delete this job type?"
        dialogProps={{
          open: openConfirm,
          onClose: () => {
            setOpenConfirm(false);
            setDeleteJobType(undefined);
          },
        }}
        onConfirm={() => {
          if (!deleteJobType) return;
          JobService.deleteJobType(deleteJobType.id ?? 0);
          loadJobTypes();
        }}
      />
    </React.Fragment>
  );
};
