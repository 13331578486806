import { ApiClient } from 'ApiClient';
import { DeliveryPackageStatus } from 'enums';
import { DeliveryPackage } from 'interfaces';

class DeliveryPackageService {
  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.put(`delivery-packages/${id}`, data);
    }

    return ApiClient.post('delivery-packages', data);
  }

  public delete(id: number) {
    return ApiClient.delete(`delivery-packages/${id}`);
  }

  public removeOrderFromPackage(data: { packageId: number; orderId: number }) {
    return ApiClient.post('delivery-packages/remove-order', data);
  }

  public getAllCompletedPackages({
    dateFrom,
    dateTo,
  }: {
    dateFrom: string;
    dateTo: string;
  }): Promise<DeliveryPackage[]> {
    return ApiClient.get('delivery-packages', {
      statuses: DeliveryPackageStatus.DELIVERED,
      dateFrom,
      dateTo,
    });
  }

  public assignPackage({
    id,
    data,
  }: {
    id: number;
    data: { userId: number; vehicleTypeId: number };
  }) {
    return ApiClient.post(`delivery-packages/assign-package/${id}`, data);
  }
}

const singleton = new DeliveryPackageService();

export { singleton as DeliveryPackageService };
