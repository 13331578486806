import { DialogProps, Grid, TextField } from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Gallery } from 'interfaces';
import * as React from 'react';
import { Config } from 'config';
import { GalleryService } from 'services/GalleryService';

interface Props {
  dialogProps: DialogProps;
  image?: Gallery;
  gallerySectionId: number;
  refresh: () => void;
}

interface GalleryImageForm {
  rank?: number;
  title?: string;
  image?: File;
  gallerySectionId: number;
}

export const SaveGalleryDialog: React.FC<Props> = ({
  dialogProps,
  image,
  refresh,
  gallerySectionId,
}) => {
  const snackbar = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(false);

  const getForm = React.useCallback(
    function getForm(image?: Gallery): GalleryImageForm {
      return {
        rank: image?.rank ?? undefined,
        title: image?.title ?? undefined,
        gallerySectionId,
      };
    },
    [gallerySectionId],
  );

  const [form, setForm] = React.useState(getForm(image));
  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(image));
    }
  }, [getForm, image, justOpened]);

  const title = `${image?.id ? 'Add' : 'Edit'} Image`;

  return (
    <FormDialog
      title={title}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="title"
            value={form.title || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Rank"
            type="number"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="rank"
            value={form.rank || 0}
          />
        </Grid>
        <Grid item xs={12}>
          {image && image.imageUrl && (
            <img
              src={`${Config.IMAGE_CDN_URL}${image.imageUrl}`}
              alt=""
              style={{ height: 100, display: 'block' }}
            />
          )}
          <input
            id="icon-button-file"
            type="file"
            accept="image/*"
            onChange={onFileChange}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  async function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { currentTarget } = e;
    const { files } = currentTarget;

    if (!files || !files.length) {
      throw new Error('Select a file.');
    }

    const file = files[0];

    setForm((f) => ({ ...f, image: file }));
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const response = await GalleryService.save({
        id: image?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Gallery Image has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
