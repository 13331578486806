import { ApiClient } from 'ApiClient';
import { SetMenu } from 'interfaces';

class SetMenuCategoryService {
  public async get(setMenuId: string): Promise<SetMenu> {
    const response: SetMenu = await ApiClient.get<SetMenu>(
      `set-menus/${setMenuId}`,
    );

    return response;
  }

  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.post(`set-menu-categories/update/${id}`, data);
    }

    return ApiClient.post('set-menu-categories', data);
  }

  public delete(id: number) {
    return ApiClient.delete(`set-menu-categories/${id}`);
  }
}

const singleton = new SetMenuCategoryService();

export { singleton as SetMenuCategoryService };
