import {
  DialogContent,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@material-ui/icons/Print';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { Autocomplete } from '@material-ui/lab';
import { ResponsiveIconButton } from 'components';
import { DialogControlProps, DialogControls } from 'components/DialogControls';
import { MenuItem } from 'interfaces';
import * as React from 'react';
import { ChooseSetMenuDialog, SetMenuItemForm } from './ChooseSetMenuDialog';
import { OrderForm, OrderItemsForm } from './SaveOrderDialog';
import { useReactToPrint } from 'react-to-print';
import { PrintOrderReceipt } from 'components';
import { useRef } from 'react';

interface Props extends DialogControlProps {
  menuItems: MenuItem[];
  readonly?: boolean;
  orderId?: number;
  form: OrderForm;
  setForm: React.Dispatch<React.SetStateAction<OrderForm>>;
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    minHeight: 0,
    marginTop: theme.spacing(1),
    paddingRight: 0,
    paddingLeft: 0,
  },
  removeIcon: {
    color: theme.palette.danger.main,
  },
  table: {
    marginBottom: theme.spacing(5),
  },
}));

export const OrderStep: React.FC<Props> = ({
  menuItems,
  readonly,
  orderId,
  form,
  setForm,
  ...dialogControlProps
}) => {
  const classes = useStyles();

  const [openSetMenu, setOpenSetMenu] = React.useState(false);
  const componentRef = useRef(null);
  const [maxSetMenuItemGrouped, setMaxSetMenuItemGrouped] = React.useState(0);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <React.Fragment>
      <DialogContent>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6"></Typography>
          <ResponsiveIconButton
            onClick={printOrder}
            color="primary"
            icon={PrintIcon}
            disabled={!readonly}
          >
            Print Order
          </ResponsiveIconButton>

          <div>
            <ResponsiveIconButton
              onClick={onAddMenuItem}
              color="primary"
              icon={AddIcon}
              disabled={readonly}
              responsive={false}
            >
              Add Menu Item
            </ResponsiveIconButton>
            <ResponsiveIconButton
              onClick={() => setOpenSetMenu(true)}
              color="primary"
              icon={AddIcon}
              disabled={readonly}
              responsive={false}
            >
              Add Set Menu
            </ResponsiveIconButton>
          </div>
        </Toolbar>

        {form.items.length > 0 ? (
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Menu Item</TableCell>
                <TableCell style={{ width: 100 }}>Price</TableCell>
                <TableCell style={{ width: 100 }}>Quantity</TableCell>
                <TableCell style={{ width: 200 }}>Comment</TableCell>
                <TableCell style={{ width: 50 }} padding="none" />
              </TableRow>
            </TableHead>

            <TableBody>
              {form.items.map((item, i, arr) => (
                <TableRow key={i}>
                  <TableCell>
                    <Autocomplete
                      value={
                        menuItems.find((mi) => mi.id === item.menuItemId) ||
                        null
                      }
                      options={menuItems}
                      getOptionLabel={(option: MenuItem) =>
                        option ? `${option.title}` : ''
                      }
                      onChange={(e: any, mItem: MenuItem | null) =>
                        onChangeMenuItem(mItem, 'menuItemId', i)
                      }
                      disabled={readonly || Boolean(item.setMenuCategoryItemId)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="menuItemId"
                          variant="standard"
                        />
                      )}
                    />
                  </TableCell>

                  {item.setMenuCategoryItemId ? (
                    <TableCell colSpan={2}>
                      <Typography variant="body2" align="center">
                        {item.setMenuName
                          ? `Part of "${item.setMenuName}"`
                          : 'Part of set menu'}
                      </Typography>
                    </TableCell>
                  ) : (
                    <React.Fragment>
                      <TableCell>
                        <TextField
                          type="number"
                          fullWidth
                          required
                          value={item.price || ''}
                          disabled
                          onChange={(e) => onChangeMenuItem(e, 'price', i)}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          type="number"
                          fullWidth
                          required
                          disabled={readonly}
                          value={item.quantity || 1}
                          onChange={(e) => onChangeMenuItem(e, 'quantity', i)}
                        />
                      </TableCell>
                    </React.Fragment>
                  )}

                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      disabled={readonly}
                      value={item.notes || ''}
                      onChange={(e) => onChangeMenuItem(e, 'notes', i)}
                    />
                  </TableCell>

                  <TableCell padding="none">
                    <IconButton
                      disabled={readonly}
                      className={classes.removeIcon}
                      onClick={(e) => onRemoveMenuItem(e, i)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}

        <Typography variant="h6" align="right">
          <strong>Total:</strong> &euro;
          {readonly &&
            form.items
              .reduce<number>(
                (prev, curr) =>
                  (prev += parseFloat(
                    curr.itemCostIncludingVat?.toString() ?? '0',
                  )),
                form.setMenuPrice,
              )
              .toFixed(2)}
          {!readonly &&
            form.items
              .reduce<number>(
                (prev, curr) =>
                  (prev +=
                    parseFloat(curr.price?.toString() ?? '0') *
                    parseFloat(curr.quantity?.toString() ?? '0')),
                parseFloat(form.setMenuPrice.toString() ?? '0'),
              )
              .toFixed(2)}
        </Typography>

        <ChooseSetMenuDialog
          addSetMenuItems={addSetMenuItems}
          dialogProps={{
            open: openSetMenu,
            onClose: () => setOpenSetMenu(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />
      </DialogContent>
      <DialogControls {...dialogControlProps} />
      <div hidden>
        <div ref={componentRef}>
          <PrintOrderReceipt orderId={orderId} />
        </div>
      </div>
    </React.Fragment>
  );

  function addSetMenuItems(items: SetMenuItemForm[], price: number) {
    const newMenuItems = form.items?.slice() ?? [];
    let counter = maxSetMenuItemGrouped + 1;
    setMaxSetMenuItemGrouped(counter);
    setForm((f) => ({
      ...f,
      setMenuPrice:
        parseFloat(f.setMenuPrice.toString()) + parseFloat(price.toString()),
      items: [
        ...newMenuItems,
        ...items.map((i) => ({
          menuItemId: i.menuItemId,
          quantity: 1,
          setMenuName: i.setMenuName ?? undefined,
          setMenuCategoryItemId: i.setMenuCategoryItemId,
          setMenuGroupId: counter,
          setMenuGroupPrice: price,
        })),
      ],
    }));
  }

  function onAddMenuItem(e: React.MouseEvent<HTMLElement>) {
    const newMenuItems = form.items?.slice() ?? [];

    newMenuItems.push({
      menuItemId: undefined,
      quantity: 1,
    });

    setForm((f) => ({
      ...f,
      items: newMenuItems,
    }));
  }

  function printOrder(e: React.MouseEvent<HTMLElement>) {
    if (orderId !== undefined && handlePrint) {
      handlePrint();
    }
  }

  function isMenuItem(
    input:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | MenuItem,
  ): input is MenuItem {
    return input.hasOwnProperty('title');
  }

  function onChangeMenuItem(
    e:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | (MenuItem | null),
    name: keyof OrderItemsForm,
    i: number,
  ) {
    const value = e ? (isMenuItem(e) ? e.id : e.currentTarget.value) : null;

    const newMenuItems = form.items.slice();
    const data = newMenuItems[i];

    switch (name) {
      case 'notes':
        if (typeof value === 'string') {
          data[name] = value;
        }
        break;
      case 'menuItemId':
        if (typeof value === 'number') {
          const item = menuItems.find((i) => i.id === value);

          if (item) {
            data.price = item.price;
          }
        }

        data[name] = value ? parseFloat(value.toString()) : undefined;
        break;
      case 'quantity':
        if (typeof value === 'string') {
          data[name] = value ? parseFloat(value) : undefined;
        }
        break;
      default:
        return;
    }

    setForm((f) => ({
      ...f,
      items: newMenuItems,
    }));
  }

  function onRemoveMenuItem(e: React.MouseEvent<HTMLElement>, i: number) {
    if (!form.items) {
      return null;
    }

    const currentItem = form.items[i];
    let newMenuItems = form.items.slice();
    newMenuItems.splice(i, 1);

    let setMenuPrice = form.setMenuPrice;
    if (currentItem.setMenuCategoryItemId) {
      newMenuItems = newMenuItems.filter(
        (x) =>
          x.setMenuGroupId !== currentItem.setMenuGroupId ||
          !x.setMenuCategoryItemId,
      );
      setMenuPrice -= currentItem.setMenuGroupPrice ?? 0;
    }
    setForm((f) => ({
      ...f,
      items: newMenuItems,
      setMenuPrice:
        newMenuItems.filter((item) => item.setMenuCategoryItemId).length === 0
          ? 0
          : setMenuPrice,
    }));
  }
};
