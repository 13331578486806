import { Typography, Paper, Toolbar } from '@material-ui/core';
import * as React from 'react';
import { UserType } from 'interfaces';
import {
  DelayedLinearProgress,
  ConfirmDialog,
  AddUserTypeDialog,
  TableControls,
  ResponsiveIconButton,
  SortableTable,
} from 'components';
import AddIcon from '@material-ui/icons/Add';
import { UserService } from 'services/UserService';
import { SortableTableHeader } from 'components/SortableTable/components';

const columns: SortableTableHeader[] = [
  { key: 'name', label: 'User Type' },
  { key: 'controls', sortable: false },
];

export const UserTypes: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [userTypes, setUserTypes] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editUserType, setEditUserType] = React.useState<UserType>();
  const [deleteUser, setDeleteUser] = React.useState<UserType>();
  const [openConfirm, setOpenConfirm] = React.useState(false);

  React.useEffect(() => {
    getUserTypes()
  }, []);

  const rows = userTypes.map((userType: UserType) => ({
    key: userType.id.toString(),
    cells: [
      { key: 'name', display: userType.name },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={() => {
              setEditUserType(userType);
              setOpenEdit(true);
            }}
            onDelete={() => {
              setOpenConfirm(true);
              setDeleteUser(userType);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      <DelayedLinearProgress loading={loading} />
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">User Types</Typography>
          <ResponsiveIconButton
            onClick={() => setOpen(true)}
            color="primary"
            icon={AddIcon}
          >
            Add User Type
          </ResponsiveIconButton>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          // loading={queryLoading}
          emptyTableText="No user types available yet."
          emptyTableCreateAction={() => setOpen(true)}
          disablePagination
        />
      </Paper>

      <AddUserTypeDialog
        loading={loading}
        dialogProps={{
          open: open,
          onClose: () => setOpen(false),
          fullWidth: true,
        }}
        refresh={() => getUserTypes()}
        userTypeList={userTypes}
      />
      <AddUserTypeDialog
        loading={loading}
        dialogProps={{
          open: openEdit,
          onClose: () => setOpenEdit(false),
          fullWidth: true,
        }}
        userType={editUserType}
        refresh={() => getUserTypes()}
        userTypeList={userTypes}
      />
      <ConfirmDialog
        title="Are you sure you want to delete this user type?"
        dialogProps={{
          open: openConfirm,
          onClose: () => {
            setOpenConfirm(false);
            setDeleteUser(undefined);
          },
        }}
        onConfirm={() => {
          if (deleteUser) {
            UserService.deleteUserType(deleteUser.id ?? 0);
          }
          getUserTypes();
        }}
      />
    </React.Fragment>
  );

  async function getUserTypes() {
    const response = await UserService.getUserTypes();
    setUserTypes(response);
    setLoading(false);
  }
};
