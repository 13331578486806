import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { SetMenuCategoryView } from './SetMenuCategoryView';
import { SetMenuView } from './SetMenuView';

export const SetMenuSwitch: React.FC<RouteComponentProps<{ id?: string }>> = ({
  match: { url, path },
}) => (
  <React.Fragment>
    <BreadcrumbsItem to={`${url}#set_menu`}>Set Menu</BreadcrumbsItem>

    <Switch>
      <Route exact path={path}>
        <SetMenuView />
      </Route>

      <Route path={`${path}/:id([0-9]+)/set-menu`}>
        <SetMenuCategoryView />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  </React.Fragment>
);
