import {
  DialogProps,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { Autocomplete } from '@material-ui/lab';
import {
  EmptyView,
  FormDialog,
  ResponsiveIconButton,
  SnackbarContext,
} from 'components';
import { usePrevious } from 'hooks';
import { MenuItem, ScreenSection } from 'interfaces';
import * as React from 'react';
import { MenuItemService, ScreenSectionService } from 'services';
import { useStyles } from './styles';

interface Props {
  dialogProps: DialogProps;
  screenSection?: ScreenSection;
  sections: ScreenSection[];
  screenId: number;
  refresh: () => void;
}

interface MenuItemForm {
  menuItemId: number | null;
  rank: number | null;
}

interface ScreenSectionForm {
  name?: string;
  rank?: number;
  screenSectionMenuItems: MenuItemForm[];
  screenId: number;
}

export const SaveScreenSectionDialog: React.FC<Props> = ({
  dialogProps,
  screenSection,
  sections,
  screenId,
  refresh,
}) => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);

  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);

  const getNextRank = React.useCallback(
    function getNextRank() {
      return (
        sections.reduce((prev, curr) => {
          if (curr.rank > prev) {
            return curr.rank;
          }

          return prev;
        }, 0) + 1
      );
    },
    [sections],
  );

  const getForm = React.useCallback(
    function getForm(screenSection?: ScreenSection): ScreenSectionForm {
      return {
        name: screenSection?.name ?? undefined,
        rank: screenSection?.rank ?? getNextRank(),
        screenSectionMenuItems:
          screenSection?.screenSectionMenuItems.map(({ rank, menuItemId }) => ({
            rank,
            menuItemId,
          })) ?? [],
        screenId,
      };
    },
    [screenId, getNextRank],
  );

  const [form, setForm] = React.useState(getForm(screenSection));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    MenuItemService.getAll().then((response) => {
      setMenuItems(response);
    });

    if (justOpened) {
      setForm(getForm(screenSection));
    }
  }, [getForm, screenSection, justOpened]);

  return (
    <FormDialog
      title={`${screenSection ? 'Edit' : 'Add'} Screen Section`}
      submitText="Save"
      onSubmit={submit}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Rank"
            type="number"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="rank"
            value={form.rank || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">Menu Items</Typography>

            <ResponsiveIconButton
              onClick={onAddMenuItem}
              color="primary"
              icon={AddIcon}
            >
              Add Menu Item
            </ResponsiveIconButton>
          </Toolbar>

          {!form.screenSectionMenuItems ||
          form.screenSectionMenuItems.length === 0 ? (
            <EmptyView>No menu items set</EmptyView>
          ) : (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 480 }}>Menu Item</TableCell>
                  <TableCell style={{ width: 200 }}>Rank</TableCell>
                  <TableCell padding="none" />
                </TableRow>
              </TableHead>

              <TableBody>
                {form.screenSectionMenuItems
                  .sort(({ rank: a }, { rank: b }) => (a || 0) - (b || 0))
                  .map((item, i, arr) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Autocomplete
                          value={
                            menuItems.find((mi) => mi.id === item.menuItemId) ||
                            null
                          }
                          options={menuItems}
                          getOptionLabel={(option: MenuItem) =>
                            option ? `[${option.id}] ${option.title}` : ''
                          }
                          onChange={(e: any, mItem: MenuItem | null) =>
                            onChangeMenuItem(mItem, 'menuItemId', i)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="menuItemId"
                              variant="standard"
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          value={item.rank || ''}
                          onChange={(e) => onChangeMenuItem(e, 'rank', i)}
                        />
                      </TableCell>

                      <TableCell padding="none">
                        <IconButton
                          className={classes.removeIcon}
                          onClick={(e) => onRemoveMenuItem(e, i)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function onAddMenuItem(e: React.MouseEvent<HTMLElement>) {
    const newMenuItems = form.screenSectionMenuItems?.slice() ?? [];

    const rank =
      newMenuItems.reduce<number>(
        (prev, curr) => (curr.rank && curr.rank > prev ? curr.rank : prev),
        0,
      ) + 1;

    newMenuItems.push({
      menuItemId: null,
      rank,
    });

    setForm((f) => ({
      ...f,
      screenSectionMenuItems: newMenuItems,
    }));
  }

  function isMenuItem(
    input:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | MenuItem,
  ): input is MenuItem {
    return input.hasOwnProperty('title');
  }

  function onChangeMenuItem(
    e:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | (MenuItem | null),
    name: keyof MenuItemForm,
    i: number,
  ) {
    const value = e ? (isMenuItem(e) ? e.id : e.currentTarget.value) : null;

    const newMenuItems = form.screenSectionMenuItems.slice();
    const data = newMenuItems[i];

    data[name] = value ? parseFloat(value.toString()) : null;

    setForm((f) => ({
      ...f,
      screenSectionMenuItems: newMenuItems,
    }));
  }

  function onRemoveMenuItem(e: React.MouseEvent<HTMLElement>, i: number) {
    if (!form.screenSectionMenuItems) {
      return null;
    }

    const newMenuItems = form.screenSectionMenuItems.slice();

    newMenuItems.splice(i, 1);

    setForm((f) => ({
      ...f,
      screenSectionMenuItems: newMenuItems,
    }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      const response = await ScreenSectionService.save({
        id: screenSection?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Screen section has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
