import { IconButton, Tooltip } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

export interface TableControlButtonProps
  extends Omit<IconButtonProps, 'onClick'> {
  onClick?: React.MouseEventHandler<HTMLElement>;
  tooltipTitle?: string;
  Icon: React.ComponentType<SvgIconProps>;

  // TODO MUI v3 -> v4 fix? need to test
  href?: string;
}

export const TableControlButton: React.FC<TableControlButtonProps> = ({
  onClick,
  tooltipTitle,
  Icon,
  ...iconButtonProps
}) => {
  const iconButton = (
    <IconButton onClick={onClick} {...iconButtonProps}>
      <Icon />
    </IconButton>
  );

  /*
    Material-UI: you are providing a disabled `button` child to the Tooltip component.
    A disabled element does not fire events.
    Tooltip needs to listen to the child element's events to display the title.

    Place a `div` container on top of the element.
  */
  return (
    <Tooltip title={tooltipTitle ?? ''}>
      {iconButtonProps.disabled ? (
        <div style={{ display: 'inline-block' }}>{iconButton}</div>
      ) : (
        iconButton
      )}
    </Tooltip>
  );
};
