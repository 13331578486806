import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    minHeight: 0,
    marginTop: theme.spacing(1),
    paddingRight: 0,
    paddingLeft: 0,
  },
  removeIcon: {
    color: theme.palette.danger.main,
  },
  table: {
    marginBottom: theme.spacing(5),
  },
}));
