import { Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Preparation } from 'interfaces';
import * as React from 'react';
import { PreparationService } from 'services';
import { SavePreparationDialog } from './SavePreparationDialog';

export const Preparations: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [preparations, setPreparations] = React.useState<Preparation[]>([]);
  const [selectedPreparation, setSelectedPreparation] = React.useState<
    Preparation
  >();

  const loadPreparations = React.useCallback(
    async function loadPreparations() {
      try {
        const response = await PreparationService.getAll();

        if (!response) {
          return;
        }

        setPreparations(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadPreparations();
  }, [loadPreparations]);

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'start', label: 'Start Time' },
    { key: 'end', label: 'End Time' },
    { key: 'user', label: 'User' },
    { key: 'location', label: 'Location' },
    { key: 'controls', sortable: false },
  ];

  const rows = preparations.map((preparation) => ({
    key: preparation.id.toString(),
    cells: [
      { key: 'name', display: preparation.name },
      { key: 'start', display: preparation.start ?? '' },
      { key: 'end', display: preparation.end ?? '' },
      {
        key: 'user',
        display: `${preparation.user?.name ?? ''} ${preparation.user?.surname ??
          ''}`,
      },
      {
        key: 'location',
        display: `${preparation.shopLocation?.shop.title ?? ''} ${preparation
          .shopLocation?.location.name ?? ''}`,
      },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={(e) => {
              setModalOpen(true);
              setSelectedPreparation(preparation);
            }}
            onDelete={(e) => {
              setConfirmOpen(true);
              setSelectedPreparation(preparation);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <Paper style={{ overflow: 'auto' }}>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Preparations</Typography>

        <ResponsiveIconButton
          onClick={(e) => {
            setSelectedPreparation(undefined);
            setModalOpen(true);
          }}
          color="primary"
          icon={AddIcon}
        >
          Add Preparations
        </ResponsiveIconButton>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No preparations available yet."
        emptyTableCreateAction={(e) => setModalOpen(true)}
        disablePagination
      />

      <SavePreparationDialog
        preparation={selectedPreparation}
        refresh={() => loadPreparations()}
        dialogProps={{
          open: modalOpen,
          onClose: (e) => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'md',
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this preparation?"
        onConfirm={deletePreparation}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedPreparation(undefined);
          },
        }}
      />
    </Paper>
  );

  async function deletePreparation() {
    try {
      if (!selectedPreparation) {
        return;
      }

      const response = await PreparationService.delete(selectedPreparation.id);

      if (!response) {
        return;
      }

      loadPreparations();
      snackbar.open('Preparation has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
