import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import {PendingItemView} from './PendingItemsView';

export const PendingItemsSwitch: React.FC<RouteComponentProps<{ id?: string }>> = ({
  match: { url, path },
}) => (
  <React.Fragment>
    <Switch>
      <Route exact path={path}>
        <PendingItemView />
      </Route>

      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  </React.Fragment>
);
