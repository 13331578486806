import {
  Paper,
  Toolbar,
  Typography,
  Button,
  TextField,
  makeStyles,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import { DatePicker } from 'components';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
  RouterLink,
  DelayedLinearProgress,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Order, Shop } from 'interfaces';
import * as React from 'react';
import { OrderService, ShopService } from 'services';
import { SaveOrderDialog, OrderReport } from '../components';
import { orderStatusToFriendly } from 'lib/orderStatusToFriendly';
import { OrderPreparationDialog } from '../components/OrderPreparationDialog';
import { OrderStatus } from 'enums';
import { format } from 'date-fns';
import { useApiClient, useDebounce } from 'hooks';

interface Props {
  isFuture?: boolean;
}

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    ...theme.mixins.gutters(),
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  filterDateInputs: {
    marginTop: theme.spacing(1.25),
  },
}));

export const OrderView: React.FC<Props> = ({ isFuture }) => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [generateReportOpen, setGenerateReportOpen] = React.useState(false);
  const [orderPreparationOpen, setOrderPreparationOpen] = React.useState(false);
  const [shops, setShops] = React.useState<Shop[]>([]);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<Order>();
  const [shopId, setShopId] = React.useState<number | null>(null);

  const [textSearch, setTextSearch] = React.useState('');
  const [dateFrom, setDateFrom] = React.useState<Date>();
  const [dateTo, setDateTo] = React.useState<Date>();

  const variables = React.useMemo(
    () => ({
      dateFrom: format(dateFrom ?? new Date(), 'yyyy-MM-dd'),
      dateTo: format(dateTo ?? new Date(), 'yyyy-MM-dd'),
      orderId: textSearch,
      refreshVal: refresh,
    }),
    [dateFrom, dateTo, textSearch, refresh],
  );

  const [{ data, progress }] = useApiClient<Order[]>(
    !isFuture ? `orders` : 'orders/get-future-orders',
    useDebounce(variables, 500),
  );

  const items = (data ?? []).filter(
    (order: Order) =>
      isFuture ||
      order.futureOrder === null ||
      !order.futureOrder ||
      order.futureOrder.actioned,
  );

  React.useEffect(() => {
    setLoading(true);
    Promise.all([ShopService.getShops()])
      .then(([shopsResponse]) => {
        setShops(shopsResponse);
      })
      .finally(() => setLoading(false));
  }, [isFuture, refresh]);

  const columns: SortableTableHeader[] = [
    { key: 'orderNumber', label: 'Order No.' },
    { key: 'dayOrderNumber', label: 'Order No. Of the Day' },
    { key: 'shop', label: 'Shop' },
    { key: 'tableNumber', label: 'Table No.' },
    { key: 'customer', label: 'Customer' },
    { key: 'total', label: 'Total' },
    { key: 'numberOfItems', label: 'No. of Menu Items' },
    { key: 'status', label: 'Status' },
  ];

  if (!isFuture) {
    columns.push({ key: 'created', label: 'Created' });
  }

  columns.push(
    { key: 'deliveryAt', label: 'Delivery at' },
    { key: 'controls', sortable: false },
  );

  const rows = items
    .filter(
      (order) => !shopId || order.shop.id.toString() === shopId.toString(),
    )
    .map((order) => {
      const rows = {
        key: order.id.toString(),
        cells: [
          {
            key: 'orderNumber',
            display: (
              <Button
                component={RouterLink}
                to={`/orders/${order.id.toString()}/jobs`}
                variant="text"
                size="small"
                style={{ minWidth: 'auto' }}
                color="secondary"
              >
                Order No. {order.id}
              </Button>
            ),
            sortValue: order.id,
          },
          { key: 'dayOrderNumber', display: order.orderNumber ?? 'N/A' },
          { key: 'shop', display: order.shop.title },
          { key: 'tableNumber', display: order.cardNumber || '' },
          {
            key: 'customer',
            display: order.customer ? (
              <span>
                {order.customer.firstName} {order.customer.lastName} <br />{' '}
                {order.address ?? order.customer.address} <br />
                {order.customer.phoneNumber}
              </span>
            ) : (
              ''
            ),
          },
          {
            key: 'total',
            display: (
              <span>
                &euro;
                {(
                  parseFloat(order.totalCostExcludingVat.toString()) +
                  parseFloat(order.vat.toString())
                ).toFixed(2)}
              </span>
            ),
          },
          { key: 'numberOfItems', display: order.orderItems.length },
          {
            key: 'status',
            display: order.orderStatusId
              ? orderStatusToFriendly(order.orderStatusId)
              : 'Pending',
          },
          {
            key: 'deliveryAt',
            display: order.futureOrder
              ? order.futureOrder.deliveryAt
              : order.deliveryAt,
          },
          {
            key: 'controls',
            display: (
              <TableControls
                readonly
                generateTitle="Generate Report"
                onAssign={
                  order.orderStatusId === OrderStatus.READY_FOR_PACKING
                    ? () => {
                        setOrderPreparationOpen(true);
                        setSelectedOrder(order);
                      }
                    : undefined
                }
                onGenerate={() => {
                  setGenerateReportOpen(true);
                  setSelectedOrder(order);
                }}
                onEdit={() => {
                  setModalOpen(true);
                  setSelectedOrder(order);
                }}
                onDelete={() => {
                  setConfirmOpen(true);
                  setSelectedOrder(order);
                }}
              />
            ),
          },
        ],
      };

      if (!isFuture) {
        rows.cells.splice(8, 0, {
          key: 'created',
          display: order.futureOrder
            ? order.futureOrder.actioned
            : order.created,
        });
      }

      return rows;
    });

  return (
    <Paper style={{ overflow: 'auto' }}>
      {(loading || progress) && <DelayedLinearProgress />}
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Orders</Typography>
        <div style={{ display: 'flex' }}>
          <TextField
            select
            SelectProps={{ native: true }}
            style={{ flex: 1, marginRight: 8, width: 120 }}
            onChange={(e: any) => setShopId(e.target.value)}
          >
            <option value="">All Shops</option>

            {shops.map((d) => (
              <option value={d.id} key={d.id}>
                {d.title}
              </option>
            ))}
          </TextField>
          <ResponsiveIconButton
            onClick={() => {
              setSelectedOrder(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Order
          </ResponsiveIconButton>
        </div>
      </Toolbar>

      {!isFuture && (
        <div className={classes.filtersContainer}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Date From"
                value={dateFrom || new Date()}
                onChange={(e: MaterialUiPickersDate) =>
                  setDateFrom(e ?? undefined)
                }
                fullWidth
                clearable
                className={classes.filterDateInputs}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                label="Date To"
                value={dateTo || new Date()}
                onChange={(e: MaterialUiPickersDate) =>
                  setDateTo(e ?? undefined)
                }
                fullWidth
                clearable
                className={classes.filterDateInputs}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Search"
                placeholder="Search"
                value={textSearch}
                style={{ marginTop: 10 }}
                onChange={(e) => setTextSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                autoFocus
              />
            </Grid>
          </Grid>
        </div>
      )}
      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No orders yet."
        emptyTableCreateAction={() => setModalOpen(true)}
        defaultSort={{ columnKey: 'created', order: 'asc' }}
      />

      <SaveOrderDialog
        order={selectedOrder}
        refresh={() => setRefresh(!refresh)}
        readonly={Boolean(selectedOrder)}
        dialogProps={{
          open: modalOpen,
          onClose: () => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'md',
        }}
      />

      <OrderReport
        order={selectedOrder}
        dialogProps={{
          open: generateReportOpen,
          onClose: () => setGenerateReportOpen(false),
          fullWidth: true,
          maxWidth: 'md',
        }}
      />

      {selectedOrder && (
        <OrderPreparationDialog
          refresh={() => setRefresh(!refresh)}
          orderId={selectedOrder.id}
          dialogProps={{
            open: orderPreparationOpen,
            onClose: () => setOrderPreparationOpen(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />
      )}

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this order?"
        onConfirm={deleteOrder}
        dialogProps={{
          open: confirmOpen,
          onClose: () => {
            setConfirmOpen(false);
            setSelectedOrder(undefined);
          },
        }}
      />
    </Paper>
  );

  async function deleteOrder() {
    try {
      if (!selectedOrder) {
        return;
      }

      setLoading(true);
      const response = await OrderService.delete(selectedOrder.id);

      if (!response) {
        return;
      }

      setRefresh(!refresh);
      snackbar.open('Order has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
