import {
  Checkbox,
  DialogProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { SetMenu, SetMenuCategory, SetMenuItem } from 'interfaces';
import * as React from 'react';
import { SetMenuService } from 'services';

interface Props {
  addSetMenuItems: (items: SetMenuItemForm[], price: number) => void;
  dialogProps: DialogProps;
}

export interface SetMenuItemForm {
  menuItemId: number;
  setMenuName?: string;
  setMenuCategoryItemId: number;
  setMenuCategoryId: number;
}

interface SetMenuForm {
  setMenuId?: number;
  price: number;
  items: SetMenuItemForm[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ChooseSetMenuDialog: React.FC<Props> = ({
  dialogProps,
  addSetMenuItems,
}) => {
  const snackbar = React.useContext(SnackbarContext);
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const getForm = React.useCallback(function getForm(): SetMenuForm {
    return {
      setMenuId: undefined,
      price: 0,
      items: [],
    };
  }, []);

  const [menus, setMenus] = React.useState<SetMenu[]>([]);
  const [form, setForm] = React.useState(getForm());

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  const getSetMenus = React.useCallback(
    async function getSetMenus() {
      try {
        const response = await SetMenuService.get();

        setMenus(response);
      } catch (error) {
        snackbar.error(error);
      } finally {
        setLoading(false);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    if (justOpened) {
      getSetMenus();
      setForm(getForm());
    }
  }, [getForm, justOpened, getSetMenus]);

  const selectedMenu = menus.find((m) => m.id === form.setMenuId);

  return (
    <FormDialog
      title="Choose Set Menu"
      submitText="Done"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Autocomplete
        value={menus.find((m) => m.id === form.setMenuId) ?? null}
        options={menus}
        getOptionLabel={(option: SetMenu) =>
          `${option.name} - €${option.price}`
        }
        onChange={(e: any, menu: any) => handleChangeSetMenu(menu)}
        renderInput={(params) => (
          <TextField
            {...params}
            name="menus"
            variant="standard"
            label="Set Menus"
            placeholder="Set menus"
          />
        )}
      />
      <br />
      {Boolean(selectedMenu) &&
        selectedMenu?.setMenuCategories.map((category) => (
          <List
            key={category.id}
            dense
            subheader={
              <Typography variant="h6">
                {category.name}{' '}
                <Typography variant="body2" component="span">
                  (choose {category.numberOfChoices})
                </Typography>
              </Typography>
            }
            className={classes.root}
          >
            {category.setMenuCategoryItems.map((categoryItem) => (
              <ListItem key={categoryItem.id} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={
                      form.items.findIndex(
                        (item) =>
                          item.setMenuCategoryItemId === categoryItem.id,
                      ) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                    disabled={Boolean(
                      form.items.filter(
                        (item) => item.setMenuCategoryId === category.id,
                      ).length >= category.numberOfChoices &&
                        form.items.findIndex(
                          (item) =>
                            item.setMenuCategoryItemId === categoryItem.id,
                        ) === -1,
                    )}
                    onChange={() => chooseCategoryItem(categoryItem, category)}
                    inputProps={{
                      'aria-labelledby': `checkbox-${categoryItem.id}`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`checkbox-${categoryItem.id}`}
                  primary={categoryItem.menuItem.title}
                />
              </ListItem>
            ))}
          </List>
        ))}
    </FormDialog>
  );

  function chooseCategoryItem(
    categoryItem: SetMenuItem,
    category: SetMenuCategory,
  ) {
    let newItems = form.items.slice();

    const itemIndex = newItems.findIndex(
      (item) => item.setMenuCategoryItemId === categoryItem.id,
    );

    if (itemIndex !== -1) {
      newItems = newItems.filter(
        (item) => item.setMenuCategoryItemId !== categoryItem.id,
      );
    } else {
      const itemsChosen = newItems.filter(
        (item) => item.setMenuCategoryId === category.id,
      );

      if (itemsChosen.length < category.numberOfChoices) {
        newItems.push({
          menuItemId: categoryItem.menuItemId,
          setMenuName: selectedMenu?.name,
          setMenuCategoryId: category.id,
          setMenuCategoryItemId: categoryItem.id,
        });
      }
    }

    setForm((f) => ({ ...f, items: newItems }));
  }

  function handleChangeSetMenu(menu: SetMenu) {
    setForm((f) => ({
      ...f,
      setMenuId: menu?.id ?? undefined,
      price: menu?.price ?? 0,
    }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    if (form.items.length === 0) {
      snackbar.error('Please select at least one item');
      return;
    }

    addSetMenuItems(form.items, form.price);

    if (dialogProps.onClose) {
      dialogProps.onClose(e);
    }
  }
};
