import * as React from 'react';
import { DialogProps, Typography, Grid, TextField } from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { NameForm, PreparationItem } from 'interfaces';
import { usePrevious } from 'hooks';
import { PreparationService } from 'services';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  preparationItems: PreparationItem[];
  refresh: () => void;
}

export const AddPreparationItemDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  preparationItems,
  refresh,
}) => {
  const getForm = React.useCallback(function getForm(): NameForm {
    return {
      name: '',
    };
  }, []);

  const [form, setForm] = React.useState(getForm());
  const [error, setError] = React.useState(false);
  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;
  const snackbar = React.useContext(SnackbarContext);

  const names: string[] = preparationItems.map((type) =>
    type.name.toLocaleLowerCase(),
  );

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm());
    }
  }, [getForm, justOpened]);

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Add preparation item</Typography>
        </div>
      }
      submitText="Add Preparation Item"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Name"
            type="text"
            placeholder="Enter preparation item name"
            fullWidth
            required
            error={error}
            helperText={
              error ? 'A preparation item with that name already exists' : ''
            }
            onChange={(e) => {
              setError(
                names.includes(e.currentTarget.value.toLocaleLowerCase()),
              );
              handleChange(e);
            }}
            name="name"
            value={form.name}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      if (error) {
        throw new Error('Preparation item name already exists');
      }

      PreparationService.addNewPreparationItem(form);

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Preparation item has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }
};
