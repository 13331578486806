import { DialogProps, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { DeliveryPackage, User, UserVehicleType } from 'interfaces';
import * as React from 'react';
import { DeliveryPackageService, UserService } from 'services';

interface Props {
  dialogProps: DialogProps;
  deliveryPackage: DeliveryPackage;
}

interface AssignForm {
  userId?: number;
  vehicleTypeId?: number;
}

export const AssignPackageDialog: React.FC<Props> = ({
  dialogProps,
  deliveryPackage,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState<User[]>([]);

  const getForm = React.useCallback(function getForm(
    deliveryPackage: DeliveryPackage,
  ): AssignForm {
    return {
      userId: deliveryPackage.assigneeId ?? undefined,
      vehicleTypeId: deliveryPackage.vehicleTypeId ?? undefined,
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(deliveryPackage));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    UserService.getAllOnDuty().then((response) => {
      setUsers(response);
    });

    if (justOpened) {
      setForm(getForm(deliveryPackage));
    }
  }, [getForm, deliveryPackage, justOpened]);

  return (
    <FormDialog
      title="Assign Delivery Package"
      submitText="Assign"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            value={users.find((s) => s.id === form.userId) || null}
            options={users}
            getOptionLabel={(option: User) =>
              option ? `${option.name} ${option.surname}` : ''
            }
            onChange={(e: React.ChangeEvent<{}>, user: User | null) =>
              handleAutocompleteChange(user?.id ?? null, 'userId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="userId"
                variant="standard"
                label="User"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={
              getVehicleTypes().find(
                (vt) => vt.vehicleTypeId === form.vehicleTypeId,
              ) || null
            }
            options={getVehicleTypes()}
            getOptionLabel={(option: UserVehicleType) =>
              option?.vehicleType.name ?? ''
            }
            onChange={(
              e: React.ChangeEvent<{}>,
              userVehicleType: UserVehicleType | null,
            ) =>
              handleAutocompleteChange(
                userVehicleType?.vehicleTypeId ?? null,
                'vehicleTypeId',
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="vehicleTypeId"
                variant="standard"
                label="Vehicle"
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function getVehicleTypes() {
    if (!form.userId) {
      return [];
    }

    const user = users.find((u) => u.id === form.userId);

    return user?.userVehicleTypes ?? [];
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      if (!form.userId) {
        throw new Error('Please select a user');
      }

      if (!form.vehicleTypeId) {
        throw new Error('Please select a vehicle');
      }

      const response = await DeliveryPackageService.assignPackage({
        id: deliveryPackage.id,
        data: { userId: form.userId, vehicleTypeId: form.vehicleTypeId },
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      snackbar.open('Delivery package has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
