import { Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { MenuCategory, MenuItem } from 'interfaces';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router-dom';
import { MenuItemService } from 'services';
import { SaveMenuItemDialog } from '../components/SaveMenuItemDialog';
import { Config } from 'config';

interface Params {
  id: string;
  categoryId: string;
}

export const MenuItemView: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id: menuSectionId, categoryId } = useParams<Params>();

  const snackbar = React.useContext(SnackbarContext);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmStockOpen, setConfirmStockOpen] = React.useState(false);
  const [menuCategory, setMenuCategory] = React.useState<MenuCategory>();
  const [selectedMenuItem, setSelectedMenuItem] = React.useState<MenuItem>();

  const loadMenuItems = React.useCallback(
    async function loadMenuItems(id: string) {
      try {
        const response = await MenuItemService.getByCategory(id);
        setMenuCategory(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadMenuItems(categoryId);
  }, [categoryId, loadMenuItems]);

  const columns: SortableTableHeader[] = [
    { key: 'title', label: 'Title' },
    { key: 'image', label: 'Image' },
    { key: 'description', label: 'Description' },
    { key: 'price', label: 'Price' },
    { key: 'rank', label: 'Rank' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    menuCategory?.menuItems.map((item) => ({
      key: item.id.toString(),
      cells: [
        {
          key: 'title',
          display: item.title,
        },
        {
          key: 'image',
          display: item.imageUrl ? (
            <img
              src={`${Config.IMAGE_CDN_URL}${item.imageUrl}`}
              alt=""
              style={{ height: 80 }}
            />
          ) : (
            'N/A'
          ),
        },
        { key: 'description', display: item.description },
        { key: 'price', display: <span>&euro;{item.price}</span> },
        { key: 'rank', display: item.rank },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedMenuItem(item);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedMenuItem(item);
              }}
              outOfStock={item.isSoldOut}
              onToggleSoldOut={(e) => {
                setConfirmStockOpen(true);
                setSelectedMenuItem(item);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && (
        <React.Fragment>
          <BreadcrumbsItem to={`/menu-sections/${menuSectionId}/categories`}>
            Categories
          </BreadcrumbsItem>
          <BreadcrumbsItem to={match.url}>Menu Items</BreadcrumbsItem>
        </React.Fragment>
      )}
      <Paper style={{ overflow: 'auto' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">
            {menuCategory ? `${menuCategory.name} - ` : ''} Menu Items
          </Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedMenuItem(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Menu Item
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No menu item available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination
        />

        <SaveMenuItemDialog
          menuItem={selectedMenuItem}
          menuItems={menuCategory?.menuItems ?? []}
          menuCategoryId={parseInt(categoryId, 10)}
          refresh={() => loadMenuItems(categoryId)}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'md',
          }}
        />

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this item?"
          onConfirm={deleteMenuItem}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedMenuItem(undefined);
            },
          }}
        />

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to toggle the stock status of this item?"
          onConfirm={toggleSoldOut}
          dialogProps={{
            open: confirmStockOpen,
            onClose: (e) => {
              setConfirmStockOpen(false);
              setSelectedMenuItem(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function toggleSoldOut() {
    try {
      if (!selectedMenuItem) {
        return;
      }

      const response = await MenuItemService.toggleSoldOut(selectedMenuItem.id);

      if (!response) {
        return;
      }

      loadMenuItems(categoryId);
      snackbar.open('Menu item sold out status has been toggled successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }

  async function deleteMenuItem() {
    try {
      if (!selectedMenuItem) {
        return;
      }

      const response = await MenuItemService.delete(selectedMenuItem.id);

      if (!response) {
        return;
      }

      loadMenuItems(categoryId);
      snackbar.open('Menu item has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
