import { Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  DelayedLinearProgress,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Customer } from 'interfaces';
import * as React from 'react';
import { CustomerService } from 'services';
import { SaveCustomerDialog } from './components/SaveCustomerDialog';

export const Customers: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer>();
  const [loading, setLoading] = React.useState(true);

  const loadCustomers = React.useCallback(
    async function loadCustomers() {
      try {
        const response = await CustomerService.getAll();
        setCustomers(response);
        setLoading(false);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadCustomers();
  }, [loadCustomers]);

  const columns: SortableTableHeader[] = [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'emailAddress', label: 'Email Address' },
    { key: 'phoneNumber', label: 'Phone Number' },
    { key: 'address', label: 'Address' },
    { key: 'location', label: 'Location' },
    { key: 'controls', sortable: false },
  ];

  const rows = customers.map((customer) => ({
    key: customer.id.toString(),
    cells: [
      { key: 'firstName', display: customer.firstName },
      { key: 'lastName', display: customer.lastName ?? '' },
      { key: 'emailAddress', display: customer.emailAddress ?? '' },
      { key: 'phoneNumber', display: customer.phoneNumber },
      { key: 'address', display: customer.address },
      { key: 'location', display: customer.locality?.title ?? '' },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={(e) => {
              setModalOpen(true);
              setSelectedCustomer(customer);
            }}
            onDelete={(e) => {
              setConfirmOpen(true);
              setSelectedCustomer(customer);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      {loading && <DelayedLinearProgress />}
      <br />
      <Paper style={{ overflow: 'auto' }}>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Customers</Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedCustomer(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Customer
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No customers available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination={false}
          loading={loading}
        />

        <SaveCustomerDialog
          customer={selectedCustomer}
          refresh={() => loadCustomers()}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'md',
          }}
        />

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this customer?"
          onConfirm={deleteCustomer}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedCustomer(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deleteCustomer() {
    try {
      if (!selectedCustomer) {
        return;
      }

      const response = await CustomerService.delete(selectedCustomer.id);

      if (!response) {
        return;
      }

      loadCustomers();
      snackbar.open('Customer has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
