import { Shop } from 'interfaces';
import { ApiClient } from 'ApiClient';

class ShopService {
  public async getShops() {
    return ApiClient.get<Shop[]>('shops');
  }

  public save({ id, data }: { id: number; data: any }) {
    if (id) {
      return ApiClient.post(`shops/update/${id}`, data);
    }
  }
}

const singleton = new ShopService();

export { singleton as ShopService };
