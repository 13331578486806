import {
  DialogProps,
  Grid,
  TextField,
  Toolbar,
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import {
  FormDialog,
  SnackbarContext,
  ResponsiveIconButton,
  EmptyView,
} from 'components';
import { usePrevious } from 'hooks';
import { Preparation, ShopLocation, Shop, PreparationItem } from 'interfaces';
import * as React from 'react';
import {
  ShopLocationsService,
  ShopService,
  PreparationService,
} from 'services';
import { Autocomplete } from '@material-ui/lab';
import { MetricTypes } from 'enums';
import { AddPreparationItemDialog } from '../AddPreparationItemDialog';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    minHeight: 0,
    marginTop: theme.spacing(1),
    paddingRight: 0,
    paddingLeft: 0,
  },
  removeIcon: {
    color: theme.palette.danger.main,
  },
  table: {
    marginBottom: theme.spacing(5),
  },
}));

interface Props {
  dialogProps: DialogProps;
  setPreparation?: (preparation: Preparation) => void;
  preparation?: Preparation;
  refresh: () => void;
}

interface PreparationForm {
  name?: string;
  shop?: Shop;
  shopLocation?: ShopLocation;
  preparationJobs: PreparationJobForm[];
}

interface Metric {
  id: number;
  title: string;
}

export interface PreparationJobForm {
  shopId?: number;
  preparationItemId?: number;
  quantity?: number;
  metric?: number;
  notes?: string;
  shop?: Shop;
  preparationItem?: PreparationItem;
}

export const SavePreparationDialog: React.FC<Props> = ({
  dialogProps,
  setPreparation,
  preparation,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [shopLocations, setShopLocations] = React.useState<ShopLocation[]>([]);
  const [shops, setShops] = React.useState<Shop[]>([]);
  const [preparationItems, setPreparationItems] = React.useState<
    PreparationItem[]
  >([]);

  const metrics: Metric[] = [
    {
      id: MetricTypes.KG,
      title: 'KG',
    },
    {
      id: MetricTypes.PIECES,
      title: 'Pieces',
    },
  ];

  const getForm = React.useCallback(function getForm(
    preparation?: Preparation,
  ): PreparationForm {
    return {
      name: preparation?.name ?? undefined,
      shop: preparation?.shopLocation?.shop ?? undefined,
      shopLocation: preparation?.shopLocation ?? undefined,
      preparationJobs:
        preparation?.preparationJobs.map(
          ({
            shopId,
            preparationItemId,
            quantity,
            metric,
            notes,
            shop,
            preparationItem,
          }) => ({
            shopId,
            preparationItemId,
            quantity,
            metric,
            notes,
            shop,
            preparationItem,
          }),
        ) ?? [],
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(preparation));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  const loadShops = React.useCallback(
    async function loadShops() {
      try {
        const response = await ShopService.getShops();

        if (!response) {
          return;
        }

        setShops(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  const loadPreperationItems = React.useCallback(
    async function loadPreperationItems() {
      try {
        const response: PreparationItem[] = await PreparationService.getPreparationItems();

        if (!response) {
          return;
        }

        setPreparationItems(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  const loadLocations = React.useCallback(
    async function loadLocations(shopId) {
      try {
        const response: ShopLocation[] = await ShopLocationsService.getLocationsByShopId(
          shopId,
        );

        if (!response) {
          return;
        }

        setShopLocations(response);
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadShops();

    if (justOpened) {
      setForm(getForm(preparation));
    }
  }, [getForm, justOpened, loadShops, preparation]);

  React.useEffect(() => {
    loadPreperationItems();
  }, [loadPreperationItems]);

  React.useEffect(() => {
    if (!form.shop) {
      return;
    }

    loadLocations(form.shop.id);
  }, [form.shop, loadLocations]);

  return (
    <React.Fragment>
      <FormDialog
        title={`${preparation ? 'Edit' : 'Add'} Preperation`}
        submitText="Save"
        onSubmit={submit}
        loading={loading}
        dialogProps={dialogProps}
      >
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="Name"
              type="text"
              fullWidth
              required
              onChange={(e) => handleChange(e)}
              name="name"
              value={form.name || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={form.shop}
              options={shops}
              getOptionLabel={(option: Shop) => option?.title ?? ''}
              onChange={(e: React.ChangeEvent<{}>, shop: Shop | null) =>
                handleChangeShop(shop)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="shop"
                  variant="standard"
                  label="Shop"
                  placeholder="Shop"
                  required
                />
              )}
            />
          </Grid>
          {form.shop ? (
            <Grid item xs={12}>
              <Autocomplete
                value={form.shopLocation}
                options={shopLocations}
                getOptionLabel={(option: ShopLocation) =>
                  shops.find((shop) => shop.id === option.shopId)?.title +
                    '-' +
                    option?.location.name ?? ''
                }
                onChange={(
                  e: React.ChangeEvent<{}>,
                  shopLocation: ShopLocation | null,
                ) => handleAutocompleteChange(shopLocation, 'shopLocation')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="location"
                    variant="standard"
                    label="Location"
                    placeholder="Location"
                    required
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="h6">Preperation Jobs</Typography>

              <ResponsiveIconButton
                onClick={onAddPreparationJob}
                color="primary"
                icon={AddIcon}
              >
                Add Preparation Job
              </ResponsiveIconButton>
            </Toolbar>

            {!form.preparationJobs || form.preparationJobs.length === 0 ? (
              <EmptyView>No preparation jobs set</EmptyView>
            ) : (
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 200 }}>Shop</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell style={{ width: 150 }}>Metric</TableCell>
                    <TableCell style={{ width: 200 }}>
                      Preparation Item{' '}
                      <ResponsiveIconButton
                        onClick={() => setOpen(true)}
                        color="primary"
                        icon={AddIcon}
                      ></ResponsiveIconButton>
                    </TableCell>
                    <TableCell>Note</TableCell>
                    <TableCell padding="none" />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {form.preparationJobs.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Autocomplete
                          value={
                            shops.find((jt) => jt.id === item.shopId) || null
                          }
                          options={shops}
                          getOptionLabel={(option: Shop) => option?.title ?? ''}
                          onChange={(e: any, shop: Shop | null) =>
                            onChangePreparationJob(shop, 'shopId', i)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="shopId"
                              variant="standard"
                              required
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          fullWidth
                          type="number"
                          required
                          InputProps={{ inputProps: { min: 0 } }}
                          value={item.quantity || ''}
                          onChange={(e) =>
                            onChangePreparationJob(e, 'quantity', i)
                          }
                        />
                      </TableCell>

                      <TableCell>
                        <Autocomplete
                          value={
                            metrics.find((jt) => jt.id === item.metric) || null
                          }
                          options={metrics}
                          getOptionLabel={(option: Metric) =>
                            option?.title ?? ''
                          }
                          onChange={(e: any, option: Metric | null) =>
                            onChangePreparationJob(option, 'metric', i)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="metric"
                              variant="standard"
                              required
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell>
                        <Autocomplete
                          value={
                            preparationItems.find(
                              (jt) => jt.id === item.preparationItemId,
                            ) || null
                          }
                          options={preparationItems}
                          getOptionLabel={(option: PreparationItem) =>
                            option?.name ?? ''
                          }
                          onChange={(e: any, option: PreparationItem | null) =>
                            onChangePreparationJob(
                              option,
                              'preparationItemId',
                              i,
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="metric"
                              variant="standard"
                              required
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          fullWidth
                          value={item.notes ?? ''}
                          onChange={(e) =>
                            onChangePreparationJob(e, 'notes', i)
                          }
                        />
                      </TableCell>

                      <TableCell padding="none">
                        <IconButton
                          className={classes.removeIcon}
                          onClick={(e) => onRemovePrepartionJob(e, i)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
      </FormDialog>

      <AddPreparationItemDialog
        dialogProps={{
          open: open,
          onClose: (e) => setOpen(false),
          fullWidth: true,
        }}
        loading={loading}
        preparationItems={preparationItems}
        refresh={() => loadPreperationItems()}
      />
    </React.Fragment>
  );

  function handleChangeShop(shop: Shop | null) {
    const newForm = { ...form };
    newForm.shop = shop ?? undefined;

    if (!shop) {
      newForm.shopLocation = undefined;
      setForm(newForm);
      return;
    }

    newForm.shopLocation =
      newForm.shopLocation?.shopId === shop.id
        ? newForm.shopLocation
        : undefined;

    setForm(newForm);
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const response = await PreparationService.save({
        id: preparation?.id ?? undefined,
        data: {
          ...form,
          shopLocationId: form.shopLocation?.id,
        },
      });

      if (!response) {
        return;
      }

      if (setPreparation) {
        setPreparation(response);
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Preperation has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }

  function onAddPreparationJob() {
    const newPreparationJobs = form.preparationJobs?.slice() ?? [];

    newPreparationJobs.push({
      quantity: undefined,
      metric: undefined,
      notes: '',
      shop: undefined,
      preparationItemId: undefined,
      shopId: undefined,
      preparationItem: undefined,
    });

    setForm((f) => ({
      ...f,
      preparationJobs: newPreparationJobs,
    }));
  }

  function isShop(
    input:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | Shop
      | Metric
      | PreparationItem,
  ): input is Shop {
    return input.hasOwnProperty('title');
  }

  function isMetric(
    input:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | Shop
      | Metric
      | PreparationItem,
  ): input is Metric {
    return input.hasOwnProperty('title');
  }

  function isPreparationItem(
    input:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | Shop
      | Metric
      | PreparationItem,
  ): input is PreparationItem {
    return input.hasOwnProperty('name');
  }

  function onChangePreparationJob(
    e:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | (Shop | Metric | PreparationItem | null),
    name: keyof PreparationJobForm,
    i: number,
  ) {
    let value = undefined;
    if (e && (isShop(e) || isMetric(e) || isPreparationItem(e))) {
      value = e.id;
    } else if (e) {
      value = e.currentTarget.value;
    }

    const newPreparationJobs = form.preparationJobs.slice();
    const data = newPreparationJobs[i];

    switch (name) {
      case 'notes':
        data[name] = value ? value.toString() : '';
        break;
      case 'shopId':
      case 'preparationItemId':
      case 'quantity':
      case 'metric':
        data[name] = value ? parseInt(value.toString(), 10) : undefined;
        break;
      default:
        return;
    }

    setForm((f) => ({
      ...f,
      preparationJobs: newPreparationJobs,
    }));
  }

  function onRemovePrepartionJob(e: React.MouseEvent<HTMLElement>, i: number) {
    if (!form.preparationJobs) {
      return null;
    }

    const newPreparationJobs = form.preparationJobs.slice();

    newPreparationJobs.splice(i, 1);

    setForm((f) => ({
      ...f,
      preparationJobs: newPreparationJobs,
    }));
  }
};
