import { Typography, Paper, Toolbar } from '@material-ui/core';
import * as React from 'react';
import { LocationJobType, JobType } from 'interfaces';
import {
  DelayedLinearProgress,
  ConfirmDialog,
  ResponsiveIconButton,
  SortableTable,
  TableControls,
  AddLocationJobTypeDialog,
  SnackbarContext,
} from 'components';
import { LocationService } from 'services/LocationService';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useRouteMatch, useParams } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { AddJobTypeDialog } from 'components/AddJobTypeDialog';
import { JobService } from 'services/JobService';
import LinkIcon from '@material-ui/icons/Link';

const columns: SortableTableHeader[] = [
  { key: 'name', label: 'Job Name' },
  { key: 'controls', sortable: false },
];

interface Params {
  id: string;
}

export const LocationJobTypesView: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id } = useParams<Params>();

  const [loading, setLoading] = React.useState(true);
  const [locationjobTypes, setLocationJobTypes] = React.useState([]);
  const [jobTypes, setJobTypes] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editJobType, setEditJobType] = React.useState<JobType>();
  const [deleteJobType, setDeleteJobType] = React.useState<JobType>();
  const [deleteLocationJobType, setDeleteLocationJobType] = React.useState<
    LocationJobType
  >();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirmRemove, setOpenConfirmRemove] = React.useState(false);
  const snackbar = React.useContext(SnackbarContext);

  const loadData = React.useCallback(
    async function loadData(id: string) {
      try {
        setLoading(true);
        await Promise.all([
          getJobTypes(),
          getJobTypeLocations(id),
        ]);
      } catch (error) {
        snackbar.error(error);
      } finally {
        setLoading(false);
      }
    },
    [snackbar],
  );

  React.useEffect(() => {
    loadData(id);
  }, [id, loadData]);

  const rows = locationjobTypes.map((locationjobType: LocationJobType) => ({
    key: locationjobType.id.toString(),
    cells: [
      { key: 'name', display: locationjobType.jobType?.name },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={() => {
              setEditJobType(locationjobType.jobType);
              setOpenEdit(true);
            }}
            onDelete={() => {
              setOpenConfirm(true);
              setDeleteJobType(locationjobType.jobType);
            }}
            onRemoveLink={() => {
              setOpenConfirmRemove(true);
              setDeleteLocationJobType(locationjobType);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      {match && <BreadcrumbsItem to={match.url}>Job Types</BreadcrumbsItem>}
      <DelayedLinearProgress loading={loading} />
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Job Types Table</Typography>
          <ResponsiveIconButton
            onClick={() => setOpen(true)}
            color="primary"
            icon={LinkIcon}
          >
            Link Job Type
          </ResponsiveIconButton>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          loading={loading}
          emptyTableText="No job types available yet."
          emptyTableCreateAction={() => setOpen(true)}
          disablePagination
        />
      </Paper>
      <AddJobTypeDialog
        loading={loading}
        dialogProps={{
          open: openEdit,
          onClose: () => setOpenEdit(false),
          fullWidth: true,
        }}
        jobType={editJobType}
        refresh={() => {
          loadData(id);
        }}
        jobTypeList={jobTypes}
      />
      <ConfirmDialog
        title="Are you sure you want to delete this job type?"
        dialogProps={{
          open: openConfirm,
          onClose: () => {
            setOpenConfirm(false);
            setDeleteJobType(undefined);
          },
        }}
        onConfirm={() => onDelete()}
      />
      <AddLocationJobTypeDialog
        dialogProps={{
          open: open,
          onClose: () => setOpen(false),
          fullWidth: true,
        }}
        jobTypeList={jobTypes}
        locationId={+id}
        locationJobTypeList={locationjobTypes}
        loading={loading}
        refresh={() => {
          loadData(id);
        }}
      />
      <ConfirmDialog
        title="Are you sure you want to unlink this job type from this location?"
        dialogProps={{
          open: openConfirmRemove,
          onClose: () => {
            setOpenConfirmRemove(false);
            setDeleteLocationJobType(undefined);
          },
        }}
        onConfirm={() => onUnlink()}
      />
    </React.Fragment>
  );

  function getJobTypeLocations(id: string)
  {
    LocationService.getLocationJobTypesByLocation(+id).then((response) => {
      setLocationJobTypes(response);
      setLoading(false);
    });
  }

  function getJobTypes()
  {
    JobService.getJobTypes().then((response) => {
      setJobTypes(response);
      setLoading(false);
    });
  }

  async function onDelete() {
    if (!deleteJobType) return;
    const respone = await JobService.deleteJobType(deleteJobType.id);

    if (respone) {
      snackbar.open('Job Type for this locaton has been deleted successfully.');
      loadData(id);
    }
  }

  async function onUnlink() {
    if (!deleteLocationJobType) return;
    const response = await LocationService.deleteLocationJobType(
      deleteLocationJobType.id,
    );

    if (response) {
      snackbar.open(
        'Job Type for this locaton has been unlinked successfully.',
      );
      loadData(id);
    }
  }
};
