import {
  DialogProps,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { Autocomplete } from '@material-ui/lab';
import {
  EmptyView,
  FormDialog,
  ResponsiveIconButton,
  SnackbarContext,
} from 'components';
import { usePrevious } from 'hooks';
import { MenuItem, SetMenuCategory } from 'interfaces';
import * as React from 'react';
import { MenuItemService, SetMenuCategoryService } from 'services';
import { useStyles } from './styles';

interface Props {
  dialogProps: DialogProps;
  setMenuCategory?: SetMenuCategory;
  setMenuCategories: SetMenuCategory[];
  setMenuId: string;
  refresh: () => void;
}

interface MenuItemForm {
  menuItemId: number | null;
  rank: number | null;
}

interface SetMenuCategoryForm {
  name?: string;
  description?: string;
  rank?: number;
  setMenuId: string;
  numberOfChoices?: number;
  categoryItems: MenuItemForm[];
}

export const SaveSetMenuCategoryDialog: React.FC<Props> = ({
  dialogProps,
  setMenuCategory,
  setMenuCategories,
  setMenuId,
  refresh,
}) => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);

  const getNextRank = React.useCallback(
    function getNextRank() {
      return (
        setMenuCategories.reduce((prev, curr) => {
          if (curr.rank > prev) {
            return curr.rank;
          }

          return prev;
        }, 0) + 1
      );
    },
    [setMenuCategories],
  );

  const getForm = React.useCallback(
    function getForm(setMenuCategory?: SetMenuCategory): SetMenuCategoryForm {
      return {
        name: setMenuCategory?.name ?? undefined,
        description: setMenuCategory?.description ?? undefined,
        rank: setMenuCategory?.rank ?? getNextRank(),
        numberOfChoices: setMenuCategory?.numberOfChoices ?? 1,
        setMenuId,
        categoryItems:
          setMenuCategory?.setMenuCategoryItems.map(({ rank, menuItemId }) => ({
            rank,
            menuItemId,
          })) ?? [],
      };
    },
    [getNextRank, setMenuId],
  );

  const [form, setForm] = React.useState(getForm(setMenuCategory));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    MenuItemService.getAll().then((response) => {
      setMenuItems(response);
    });

    if (justOpened) {
      setForm(getForm(setMenuCategory));
    }
  }, [getForm, setMenuCategory, justOpened]);

  return (
    <FormDialog
      title={`${setMenuCategory ? 'Edit' : 'Add'} Set Menu Category`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={4} xs={12}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            label="Number of choices"
            type="number"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="numberOfChoices"
            value={form.numberOfChoices || ''}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            label="Rank"
            type="number"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="rank"
            value={form.rank || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            type="text"
            fullWidth
            required
            multiline
            onChange={(e) => handleChange(e)}
            name="description"
            value={form.description || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">Set Menu Items</Typography>

            <ResponsiveIconButton
              onClick={onAddMenuItemJob}
              color="primary"
              icon={AddIcon}
            >
              Add Set Menu Item
            </ResponsiveIconButton>
          </Toolbar>

          {!form.categoryItems || form.categoryItems.length === 0 ? (
            <EmptyView>No set menu items set</EmptyView>
          ) : (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '75%' }}>Menu Item</TableCell>
                  <TableCell style={{ width: '25%' }}>Rank</TableCell>
                  <TableCell padding="none" />
                </TableRow>
              </TableHead>

              <TableBody>
                {form.categoryItems
                  .sort(({ rank: a }, { rank: b }) => (a || 0) - (b || 0))
                  .map((item, i, arr) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Autocomplete
                          value={
                            menuItems.find((mi) => mi.id === item.menuItemId) ||
                            null
                          }
                          options={menuItems}
                          getOptionLabel={(option: MenuItem) =>
                            option ? `[${option.id}] ${option.title}` : ''
                          }
                          onChange={(e: any, menuItem: MenuItem | null) =>
                            onChangeMenuItem(menuItem, 'menuItemId', i)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="menuItemId"
                              variant="standard"
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          type="number"
                          value={item.rank || ''}
                          onChange={(e) => onChangeMenuItem(e, 'rank', i)}
                        />
                      </TableCell>

                      <TableCell padding="none">
                        <IconButton
                          className={classes.removeIcon}
                          onClick={(e) => onRemoveMenuItemJob(e, i)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function onAddMenuItemJob(e: React.MouseEvent<HTMLElement>) {
    const newMenuItems = form.categoryItems?.slice() ?? [];

    const rank =
      newMenuItems.reduce<number>(
        (prev, curr) => (curr.rank && curr.rank > prev ? curr.rank : prev),
        0,
      ) + 1;

    newMenuItems.push({
      menuItemId: null,
      rank,
    });

    setForm((f) => ({
      ...f,
      categoryItems: newMenuItems,
    }));
  }

  function isMenuItem(
    input:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | MenuItem,
  ): input is MenuItem {
    return input.hasOwnProperty('title');
  }

  function onChangeMenuItem(
    e:
      | React.ChangeEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
      | (MenuItem | null),
    name: keyof MenuItemForm,
    i: number,
  ) {
    const value = e ? (isMenuItem(e) ? e.id : e.currentTarget.value) : null;

    const newMenuItems = form.categoryItems.slice();
    const data = newMenuItems[i];

    switch (name) {
      case 'menuItemId':
        data[name] = value ? parseInt(value.toString(), 10) : null;
        break;
      case 'rank':
        data[name] = value ? parseFloat(value.toString()) : null;
        break;
      default:
        return;
    }

    setForm((f) => ({
      ...f,
      categoryItems: newMenuItems,
    }));
  }

  function onRemoveMenuItemJob(e: React.MouseEvent<HTMLElement>, i: number) {
    if (!form.categoryItems) {
      return null;
    }

    const newMenuItems = form.categoryItems.slice();

    newMenuItems.splice(i, 1);

    setForm((f) => ({
      ...f,
      categoryItems: newMenuItems,
    }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const response = await SetMenuCategoryService.save({
        id: setMenuCategory?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Set menu category has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
