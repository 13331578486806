import { ApiClient } from 'ApiClient';
import { MenuCategory, MenuItem } from 'interfaces';

class MenuItemService {
  public async getAll(): Promise<MenuItem[]> {
    return ApiClient.get<MenuItem[]>('menu-items');
  }

  public async getByCategory(menuCategoryId: string): Promise<MenuCategory> {
    const response: MenuCategory = await ApiClient.get<MenuCategory>(
      `menu-categories/${menuCategoryId}`,
    );

    return response;
  }

  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.post(`menu-items/update/${id}`, data);
    }

    return ApiClient.post('menu-items', data);
  }

  public delete(id: number) {
    return ApiClient.delete(`menu-items/${id}`);
  }

  public toggleSoldOut(id: number) {
    return ApiClient.put(`menu-items/sold-out/${id}`);
  }
}

const singleton = new MenuItemService();

export { singleton as MenuItemService };
