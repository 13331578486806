import { ApiClient } from 'ApiClient';
import { VehicleType } from 'interfaces';

class VehicleTypesService {
  public getAll(): Promise<VehicleType[]> {
    return ApiClient.get<VehicleType[]>('vehicle-types');
  }

  public save({ id, data }: { id: number; data: any }) {
    return ApiClient.put(`vehicle-types/${id}`, data);
  }
}

const singleton = new VehicleTypesService();

export { singleton as VehicleTypesService };
