import { ApiClient } from 'ApiClient';
import { MenuSection } from 'interfaces';

class MenuSectionService {
  public async get() {
    const response = await ApiClient.get<MenuSection[]>('menu-sections');

    return response as MenuSection[];
  }

  public save({ id, data }: { id?: number; data: any }) {
    if (id) {
      return ApiClient.post(`menu-sections/update/${id}`, data);
    }

    return ApiClient.post('menu-sections', data);
  }

  public delete(id: number) {
    return ApiClient.delete(`menu-sections/${id}`);
  }
}

const singleton = new MenuSectionService();

export { singleton as MenuSectionService };
