import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { DeliveryPackageStatus, PaymentType } from 'enums';
import { DeliveryPackage, DeliveryPackageOrder } from 'interfaces';
import { deliveryPackageStatusToFriendly } from 'lib';
import * as React from 'react';
import { useStyles } from '../DeliveriesTab/styles';

interface Props {
  readonly?: boolean;
  deliveryPackage: DeliveryPackage;
  onEdit?: () => void;
  onDelete?: () => void;
  onAssign?: () => void;
  onRemoveOrder?: (order: DeliveryPackageOrder) => void;
}

export const DeliveryPackageCard: React.FC<Props> = ({
  readonly = false,
  deliveryPackage,
  onEdit,
  onDelete,
  onAssign,
  onRemoveOrder,
}) => {
  const classes = useStyles();

  const [expandedOrders, setExpandedOrders] = React.useState<string[]>([]);
  const [expandedPackages, setExpandedPackages] = React.useState<string[]>([]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Typography variant="h6">
            Delivery Package #{deliveryPackage.id}
          </Typography>
        }
        subheader={
          <Typography variant="subtitle2">
            {deliveryPackage.zoneName ? `${deliveryPackage.zoneName} - ` : ''}
            {deliveryPackage.deliveryPackageStatusId
              ? deliveryPackageStatusToFriendly(
                  deliveryPackage.deliveryPackageStatusId,
                )
              : ''}
          </Typography>
        }
        action={
          <IconButton
            edge="end"
            aria-label="expand"
            style={{ color: '#fff', marginRight: 8, marginTop: 8 }}
            onClick={() => {
              toggleExpandedPackages(`${deliveryPackage.id}`);
            }}
          >
            {!expandedPackages.includes(deliveryPackage.id.toString()) ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </IconButton>
        }
        style={{
          background: getColorBasedOnStatus(
            deliveryPackage.deliveryPackageStatusId,
          ),
          color: '#fff',
        }}
      />
      <Collapse
        in={expandedPackages.includes(`${deliveryPackage.id}`)}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          <List dense>
            {deliveryPackage.orders ? (
              Object.values(deliveryPackage.orders).map((order) => (
                <React.Fragment key={order.id}>
                  <ListItem button>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">
                          Order No. {order.id}
                          {' ('}
                          {order.orderNumber ?? 'N/A'}
                          {') '}
                          {order.numberOfBags
                            ? `- ${order.numberOfBags} bags`
                            : ''}
                          {order.localityName ? `- ${order.localityName}` : ''}
                        </Typography>
                      }
                      secondary={
                        <div>
                          <Typography variant="body2">
                            {order.orderItems &&
                              Object.values(order.orderItems).length}{' '}
                            menu items for &euro;{order.totalCostIncludingVat}
                          </Typography>
                          {order.paymentType &&
                          order.paymentType === PaymentType.CASH ? (
                            <Typography variant="subtitle2">
                              Cash Payment
                            </Typography>
                          ) : (
                            ''
                          )}
                        </div>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="expand"
                        onClick={() => {
                          toggleExpandedOrder(
                            `${deliveryPackage.id}-${order.id}`,
                          );
                        }}
                      >
                        {!expandedOrders.includes(
                          `${deliveryPackage.id}-${order.id}`,
                        ) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                      {Boolean(
                        deliveryPackage.deliveryPackageStatusId ===
                          DeliveryPackageStatus.WAITING_FOR_PICKUP,
                      ) && (
                        <IconButton
                          edge="end"
                          aria-label="remove"
                          onClick={() => onRemoveOrder && onRemoveOrder(order)}
                        >
                          <Tooltip title="Remove from package">
                            <RemoveCircleIcon color="error" />
                          </Tooltip>
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse
                    in={expandedOrders.includes(
                      `${deliveryPackage.id}-${order.id}`,
                    )}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {order.orderItems &&
                        Object.values(order.orderItems).map((item) => (
                          <ListItem
                            button
                            className={classes.nested}
                            key={item.id}
                          >
                            <ListItemText
                              primary={
                                <Typography variant="subtitle2">
                                  x{item.quantity || 1} {item.menuItem.title}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))
            ) : (
              <Typography align="center" variant="subtitle2">
                No orders yet
              </Typography>
            )}
          </List>
        </CardContent>
      </Collapse>
      <Divider />
      <CardActions disableSpacing>
        {!readonly && (
          <IconButton aria-label="Edit" onClick={onEdit}>
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
          </IconButton>
        )}
        {!readonly && (
          <IconButton aria-label="Delete" onClick={onDelete}>
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )}
        {!readonly && (
          <IconButton aria-label="Assign" onClick={onAssign}>
            <Tooltip title="Assign">
              <PersonAddIcon />
            </Tooltip>
          </IconButton>
        )}
        {deliveryPackage.assigneeName ? (
          <Typography variant="subtitle2" align="right">
            {deliveryPackage.assigneeName}
            {deliveryPackage.vehicleType
              ? ` (${deliveryPackage.vehicleType})`
              : ''}
          </Typography>
        ) : deliveryPackage?.userVehicleType ? (
          <Typography variant="subtitle2" align="right">
            {deliveryPackage.userVehicleType.user.name}{' '}
            {deliveryPackage.userVehicleType.user.surname}
            {deliveryPackage.userVehicleType.vehicleType
              ? ` (${deliveryPackage.userVehicleType.vehicleType.name})`
              : ''}
          </Typography>
        ) : (
          <Typography variant="subtitle2" align="right">
            Unassigned
          </Typography>
        )}
      </CardActions>
    </Card>
  );

  function getColorBasedOnStatus(statusId: number) {
    switch (statusId) {
      case DeliveryPackageStatus.ASSIGNED:
        return '#dab969';
      case DeliveryPackageStatus.OUT_FOR_DELIVERY:
        return '#01aae9';
      case DeliveryPackageStatus.DELIVERED:
        return '#4BBB67';
    }

    return '#f3333e';
  }

  function toggleExpandedOrder(key: string) {
    let newExpandedOrders = expandedOrders.slice();

    if (newExpandedOrders.includes(key)) {
      newExpandedOrders = newExpandedOrders.filter((o) => key !== o);
    } else {
      newExpandedOrders.push(key);
    }

    setExpandedOrders(newExpandedOrders);
  }

  function toggleExpandedPackages(key: string) {
    let newExpandedPackages = expandedPackages.slice();

    if (newExpandedPackages.includes(key)) {
      newExpandedPackages = newExpandedPackages.filter((o) => key !== o);
    } else {
      newExpandedPackages.push(key);
    }

    setExpandedPackages(newExpandedPackages);
  }
};
