import { Location, NameForm } from 'interfaces';
import { ApiClient } from 'ApiClient';

class LocationService {
  public async getLocations() {
    return ApiClient.get<Location[]>('locations');
  }

  public async postNewLocation(formData: NameForm) {
    return ApiClient.post('locations', formData);
  }

  public async editNewLocation(id: number, formData: NameForm) {
    return ApiClient.put(`locations/${id}`, formData);
  }

  public async deleteLocation(id: number) {
    return ApiClient.delete(`locations/${id}`);
  }

  public async getLocationJobTypesByLocation(id: number) {
    return ApiClient.get(`/job-type-locations/by-location/${id}`);
  }

  public async getLocationJobTypesByJobType(id: number) {
    return ApiClient.get(`/job-type-locations/by-job-type/${id}`);
  }

  public async deleteLocationJobType(id: number) {
    return ApiClient.delete(`job-type-locations/${id}`);
  }

  public async postNewLocationJobType(locationId: number, jobTypeId: number) {
    return ApiClient.post('job-type-locations', {
      jobTypeId: jobTypeId,
      locationId: locationId,
    });
  }
}

const singleton = new LocationService();

export { singleton as LocationService };
