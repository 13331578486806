import { Typography, Paper, Toolbar } from '@material-ui/core';
import * as React from 'react';
import { Locality, Zone } from 'interfaces';
import {
  DelayedLinearProgress,
  TableControls,
  SortableTable,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { LocalityService, ZoneService } from 'services';
import { EditLocalityDialog } from './components/EditLocalityDialog';

const columns: SortableTableHeader[] = [
  { key: 'title', label: 'Title' },
  { key: 'zone', label: 'zone' },
  { key: 'disableDeliveries', label: 'Delivery Disabled?' },
  { key: 'minimumOrder', label: 'Minimum Order' },
  { key: 'controls', sortable: false }
];

export const LocalitiesTab: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [localities, setLocalities] = React.useState<Locality[]>([]);
  const [zones, setZones] = React.useState<Zone[]>([]);
  const [editLocality, setEditLocality] = React.useState<Locality>();

  React.useEffect(() => {
    getLocalitiesAndZones();
  }, []);

  const rows = (localities ?? []).map((locality: Locality) => ({
    key: locality.id.toString(),
    cells: [
      { key: 'title', display: locality.title },
      { key: 'zone', display: locality.zone?.name ?? '' },
      { key: 'disableDeliveries', display: !locality.disableDeliveries ? 'No' : 'Yes' },
      { key: 'minimumOrder', display: (locality.minimumOrder != null ? <span>&euro;{locality.minimumOrder}</span> : '') },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={() => {
              setEditLocality(locality);
              setOpenEdit(true);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <React.Fragment>
      <DelayedLinearProgress loading={loading} />
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Localities</Typography>
        </Toolbar>
        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No Localities available yet."
        />
        {editLocality ?<EditLocalityDialog
        loading={loading}
        dialogProps={{
          open: openEdit,
          onClose: () => setOpenEdit(false),
          maxWidth: 'xs',
          fullWidth: true,
        }}
        locality={editLocality}
        zones={zones}
        refresh={() => getLocalitiesAndZones()}
       /> : null}
      </Paper>
    </React.Fragment>
  );

  async function getLocalitiesAndZones() {
    try {
      const response = await LocalityService.getAll();
      setLocalities(response);
      const zoneResponse = await ZoneService.getAll();
      setZones(zoneResponse)
    } catch (error) {
      setLocalities([]);
      setZones([]);
    } finally {
      setLoading(false);
    }
  }
};
