import * as React from 'react';
import { DialogProps, Typography, Grid, TextField } from '@material-ui/core';
import { FormDialog, SnackbarContext } from 'components';
import { Setting } from 'interfaces';
import { usePrevious } from 'hooks';
import { SettingsService } from 'services';

interface Props {
  dialogProps: DialogProps;
  loading: boolean;
  setting?: Setting;
  refresh: () => void;
}

interface SettingsForm {
  value: string;
}

export const EditSettingDialog: React.FC<Props> = ({
  dialogProps,
  loading,
  setting,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const getForm = React.useCallback(function getForm(
    setting?: Setting,
  ): SettingsForm {
    return {
      value: setting?.value ?? '',
    };
  },
  []);

  const [form, setSettingsForm] = React.useState(getForm(setting));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setSettingsForm(getForm(setting));
    }
  }, [getForm, justOpened, setting]);

  return (
    <FormDialog
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
            Edit {setting ? setting.key.replace(/_/g, " ") : 'Setting'}
          </Typography>
        </div>
      }
      submitText={`Edit ${setting ? setting.key.replace(/_/g, " ") : 'Setting'}`}
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label={getLabel(setting?.key ?? '')}
            type={getHtmlType(setting?.key ?? '')}
            placeholder="Enter value"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="value"
            value={form.value}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void {
    let key = e.currentTarget.name as keyof SettingsForm;
    let value = e.currentTarget.value;

    if (!key) {
      key = e.target.name as keyof SettingsForm;
      value = e.target.value;
    }

    setSettingsForm((form) => ({ ...form, [key]: value }));
  }

  function getHtmlType(key: string) {
    switch (key) {
      case 'batch_time':
        return 'number';
      default:
        return 'text';
    }
  }

  function getLabel(key: string) {
    switch (key) {
      case 'batch_time':
        return 'Value (In Minutes)';
      default:
        return 'Value';
    }
  }

  function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      SettingsService.editSetting(setting?.id ?? 0, form);

      refresh();
      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      snackbar.open('Setting has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
