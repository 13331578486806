import {
  DialogProps,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { SetMenu, Shop } from 'interfaces';
import * as React from 'react';
import { SetMenuService, ShopService } from 'services';

interface Props {
  dialogProps: DialogProps;
  setMenu?: SetMenu;
  refresh: () => void;
}

interface MenuSectionForm {
  name?: string;
  description?: string;
  price?: number;
  shopId?: number;
  rank?: number;
}

export const SaveSetMenuDialog: React.FC<Props> = ({
  dialogProps,
  setMenu,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [loading, setLoading] = React.useState(false);
  const [shops, setShops] = React.useState<Shop[]>([]);

  const getForm = React.useCallback(function getForm(
    setMenu?: SetMenu,
  ): MenuSectionForm {
    return {
      name: setMenu?.name ?? undefined,
      description: setMenu?.description ?? undefined,
      price: setMenu?.price ?? undefined,
      shopId: setMenu?.shopId ?? undefined,
      rank: setMenu?.rank ?? undefined,
    };
  },
  []);

  const [form, setForm] = React.useState(getForm(setMenu));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    setLoading(true);
    ShopService.getShops()
      .then((response: Shop[]) => {
        setShops(response);
      })
      .finally(() => setLoading(false));

    if (justOpened) {
      setForm(getForm(setMenu));
    }
  }, [getForm, setMenu, justOpened]);

  return (
    <FormDialog
      title={`${setMenu ? 'Edit' : 'Add'} Set Menu`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Title"
            type="text"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            type="text"
            fullWidth
            required
            multiline
            onChange={(e) => handleChange(e)}
            name="description"
            value={form.description || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            label="Price"
            value={form.price || ''}
            name="price"
            onChange={(e) => handleChange(e)}
            margin="none"
            fullWidth
            InputProps={{
              inputProps: { step: 0.01, min: 0 },
              startAdornment: (
                <InputAdornment position="start">&euro;</InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={shops.find((s) => s.id === form.shopId) || null}
            options={shops}
            getOptionLabel={(option: Shop) => option?.title ?? ''}
            onChange={(e: React.ChangeEvent<{}>, shop: Shop | null) =>
              handleAutocompleteChange(shop?.id ?? null, 'shopId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="shop"
                variant="standard"
                label="Shop"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Rank"
            type="number"
            fullWidth
            required
            onChange={(e) => handleChange(e)}
            name="rank"
            value={form.rank || ''}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      const response = await SetMenuService.save({
        id: setMenu?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refresh();
      snackbar.open('Set menu has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
