import {
  Dialog,
  DialogProps,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Customer, MenuItem, Order, Shop } from 'interfaces';
import * as React from 'react';
import {
  CustomerService,
  MenuItemService,
  OrderService,
  ShopService,
} from 'services';
import { CustomerStep } from './CustomerStep';
import { FinishStep } from './FinishStep';
import { OrderStep } from './OrderStep';
import { PaymentType, Shops } from 'enums';
import { ScheduleStep } from './ScheduleStep';
import { format } from 'date-fns';
import { DateTimeFormat } from 'config';
import { parseArgsDate } from 'lib';

interface Props {
  dialogProps: DialogProps;
  order?: Order;
  readonly?: boolean;
  refresh: () => void;
}

export interface OrderForm {
  customerId?: number;
  shopId?: number;
  address?: string;
  orderLocality?: string;
  orderAddress?: string;
  localityId?: number;
  latitude?: number;
  longitude?: number;
  items: OrderItemsForm[];
  setMenuPrice: number;
  payment_type: number;
  processNow: number;
  deliveryAt?: string;
  pickUp: number;
  pickUpComments?: string;
}

export interface OrderItemsForm {
  menuItemId?: number;
  price?: number;
  quantity?: number;
  notes?: string;
  setMenuName?: string;
  setMenuCategoryItemId?: number;
  itemCostIncludingVat?: number;
  setMenuGroupId?: number;
  setMenuGroupPrice?: number;
}

const steps = ['Customer', 'Order', 'Schedule', 'Finish'];

export const SaveOrderDialog: React.FC<Props> = ({
  dialogProps,
  order,
  readonly,
  refresh,
}) => {
  const snackbar = React.useContext(SnackbarContext);
  const [step, setStep] = React.useState(0);

  const [refreshDropdowns, setRefreshDropdowns] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [shops, setShops] = React.useState<Shop[]>([]);
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);
  const [processNow, setProcessNow] = React.useState(true);
  const [pickUp, setPickUp] = React.useState(false);
  const [scheduledDate, setScheduledDate] = React.useState(new Date());
  const [orderId, setOrderId] = React.useState<number>();
  const [a_readonly, setReadOnly] = React.useState<boolean>(false);

  const getForm = React.useCallback(function getForm(order?: Order): OrderForm {
    return {
      pickUp: order?.pickUp ?? 0,
      pickUpComments: order?.pickUpComments ?? undefined,
      orderLocality: order?.locality?.title ?? undefined,
      orderAddress: order?.address ?? undefined,
      payment_type: PaymentType.CASH,
      customerId: order?.customerId ?? undefined,
      shopId: order?.shopId ?? undefined,
      address: order?.address ?? undefined,
      localityId: order?.localityId ?? undefined,
      latitude: order?.latitude ?? undefined,
      longitude: order?.longitude ?? undefined,
      processNow: order?.processNow ?? 1,
      deliveryAt: order?.futureOrder?.deliveryAt
        ? format(
            parseArgsDate(order.futureOrder.deliveryAt),
            DateTimeFormat.LONG,
          )
        : order?.deliveryAt
        ? format(parseArgsDate(order.deliveryAt), DateTimeFormat.LONG)
        : undefined,
      setMenuPrice:
        parseFloat((order?.totalCostExcludingVat ?? 0).toString()) +
        parseFloat((order?.vat ?? 0).toString()) -
        (order?.orderItems.reduce(
          (prev, curr) =>
            (prev += curr.setMenuCategoryItemId
              ? 0
              : parseFloat((curr.menuItem.price * curr.quantity).toString())),
          0,
        ) ?? 0),
      items:
        order?.orderItems.map((item) => ({
          menuItemId: item.menuItemId,
          price: item.setMenuCategoryItemId ? 0 : item.menuItem.price,
          quantity: item.quantity,
          notes: item.notes ?? '',
          setMenuCategoryItemId: item.setMenuCategoryItemId ?? 0,
          itemCostIncludingVat: item.itemCostIncludingVat,
        })) ?? [],
    };
  }, []);

  const [form, setForm] = React.useState(getForm(order));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  const getStatusOfOrder = React.useCallback(
    async function getStatusOfOrder() {
      try {
        if (!order) {
          setReadOnly(false);
          return;
        }
        var response = OrderService.canOrderBeUpdated(order?.id);
        response.then(
          (result) => {
            setReadOnly(result);
          },
          function(error) {
            setReadOnly(false);
          },
        );
      } catch (error) {
        snackbar.error(error);
      }
    },
    [snackbar, order],
  );

  React.useEffect(() => {
    if (justOpened) {
      getStatusOfOrder();
      setForm(getForm(order));
      if (!readonly) {
        setStep(0);
      } else {
        setStep(1);
      }
    }
  }, [getForm, order, justOpened, readonly, a_readonly, getStatusOfOrder]);

  React.useEffect(() => {
    CustomerService.getAll().then((response) => {
      setCustomers(response);
    });

    ShopService.getShops().then((response) => {
      setShops(response);
    });
  }, [refreshDropdowns]);

  React.useEffect(() => {
    if (form.shopId) {
      MenuItemService.getAll().then((response) => {
        setMenuItems(
          response.filter(
            (item) => item.menuCategory?.menuSection?.shopId === form.shopId,
          ),
        );
      });
    }
  }, [form.shopId]);

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>
        <Stepper activeStep={step}>
          {steps.map((s, i) => (
            <Step key={i}>
              <StepLabel>{s}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>

      {getStep(step)}
    </Dialog>
  );

  function getStep(step: number) {
    switch (step) {
      case 0:
        return (
          <CustomerStep
            loading={loading}
            onClose={dialogProps.onClose}
            readonly={a_readonly}
            actionName="Next"
            actionProps={{
              onClick: () => {
                if (!form.shopId) {
                  snackbar.error('Please select a shop first');
                  return;
                }

                nextStep();
              },
            }}
            refreshDropdown={() => setRefreshDropdowns(!refreshDropdowns)}
            form={form}
            setForm={setForm}
            customers={customers}
            shops={shops}
          />
        );
      case 1:
        return (
          <OrderStep
            loading={loading}
            readonly={a_readonly}
            orderId={order?.id}
            actionName={'Next'}
            onClose={dialogProps.onClose}
            actionProps={{ onClick: () => nextStep() }}
            prevStep={() => prevStep()}
            menuItems={menuItems}
            form={form}
            setForm={setForm}
          />
        );
      case 2:
        return (
          <ScheduleStep
            onClose={dialogProps.onClose}
            actionProps={{ onClick: (e) => saveOrder(true) }}
            processNow={processNow}
            pickUp={pickUp}
            loading={loading}
            setDate={setScheduledDate}
            prevStep={() => prevStep()}
            actionName={a_readonly ? '' : 'Save & Print'}
            handleProcessNowChange={setProcessNow}
            handlePickUpChange={setPickUp}
            shopId={form.shopId}
            form={form}
            setForm={setForm}
            actionNameSub={a_readonly ? '' : 'Save'}
            actionPropsSub={{ onClick: (e) => saveOrder(false) }}
            openingHours={
              shops.find((shop) => shop.id === Shops.TO_GO)?.openingHours ?? []
            }
            readonly={a_readonly}
            deliveryAt={
              form.deliveryAt ? parseArgsDate(form.deliveryAt) : undefined
            }
          />
        );
      default:
        return <FinishStep dialogProps={dialogProps} orderId={orderId} />;
    }
  }

  function nextStep() {
    setStep((curStep) => curStep + 1);
  }

  function prevStep() {
    setStep((curStep) => curStep - 1);
  }

  async function saveOrder(isPrint: boolean) {
    try {
      setLoading(true);

      const totalPrice = form.items.reduce<number>(
        (prev, curr) =>
          (prev +=
            parseFloat(curr.price?.toString() ?? '0') *
            parseFloat(curr.quantity?.toString() ?? '0')),
        parseFloat(form.setMenuPrice.toString() ?? '0'),
      );

      if (form.customerId) {
        var currentCustomer = customers.find(
          (customer) => customer.id === form.customerId,
        );
        if (currentCustomer?.locality.disableDeliveries) {
          throw new Error(
            `Deliveries are disbaled at ${currentCustomer?.locality.title}`,
          );
        }
        const minimumOrder = parseFloat(
          currentCustomer?.locality.minimumOrder?.toString() ?? '30',
        );
        // TODO: In a future task we are going to introduce pickup and delivery flags. This is only to apply for delivery. To fix with that task.
        if (totalPrice < minimumOrder) {
          throw new Error(`Minimum order is €${minimumOrder}`);
        }
      }

      form.processNow = processNow ? 1 : 0;
      form.pickUp = pickUp ? 1 : 0;
      form.deliveryAt = format(scheduledDate, DateTimeFormat.INPUT);

      const response = await OrderService.save({
        id: order?.id ?? undefined,
        data: form,
      });

      if (!response) {
        return;
      }
      if (isPrint) {
        setOrderId(response.id);
      } else {
        setOrderId(undefined);
      }
      nextStep();

      refresh();
      snackbar.open('Order has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
