import { Config } from 'config';
import {
  GoogleApiWrapper,
  IProvidedProps,
  Map,
  Marker,
} from 'google-maps-react';
import * as React from 'react';
import { useStyles } from './styles';
import { ListItem, ListItemText, List } from '@material-ui/core';
import { usePrevious } from 'hooks';

export interface LatLong {
  lat: number;
  lng: number;
}

interface Props extends IProvidedProps {
  address: string;
  setAddress: (address: string) => void;
  coordinates?: LatLong;
  setCoordinates: React.Dispatch<React.SetStateAction<LatLong>>;
}

export const GoogleMapContainer: React.FC<Props> = ({
  address,
  setAddress,
  coordinates,
  setCoordinates,
  google,
}) => {
  const classes = useStyles();

  const [map, setMap] = React.useState<google.maps.Map<Element>>();
  const [places, setPlaces] = React.useState<google.maps.places.PlaceResult[]>(
    [],
  );
  const [placeSelected, setPlaceSelected] = React.useState(false);

  const prevAddress = usePrevious(address);

  React.useEffect(() => {
    if (!map || !address || prevAddress === address || placeSelected) {
      if (prevAddress?.length !== address.length) {
        setPlaceSelected(false);
      }

      return;
    }

    const service = new google.maps.places.PlacesService(map);
    service.textSearch({ query: address }, (e) => setPlaces(e));
  }, [map, google, address, prevAddress, placeSelected]);

  return (
    <div className={classes.mapContainer}>
      {places != null && places.length > 0 ? (
        <List>
          {places.slice(0, 5).map((p) => (
            <ListItem button key={p.id}>
              <ListItemText
                onClick={() => {
                  const place = places.find((pl) => pl.place_id === p.place_id);

                  if (!place || !place.geometry) {
                    return;
                  }

                  setAddress(place.name);
                  setCoordinates({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  });
                  setPlaceSelected(true);
                  setPlaces([]);
                }}
                primary={p.formatted_address}
              />
            </ListItem>
          ))}
        </List>
      ) : null}
      <Map
        google={google}
        zoom={15}
        initialCenter={coordinates}
        style={{ height: 300, position: 'relative' }}
        center={coordinates}
        onReady={(mapProps, m) => setMap(m)}
      >
        <Marker
          draggable
          position={coordinates}
          onDragend={(e, marker) => {
            const position = marker?.getPosition();
            if (position) {
              setCoordinates({
                lat: position.lat(),
                lng: position.lng(),
              });
            }
          }}
        />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({ apiKey: Config.GOOGLE_API_KEY })(
  GoogleMapContainer,
);
