import * as React from 'react';
import { AppTabs } from '../../components';
import {
  VehicleTypesTab,
  UsersTab,
  UserTypesTab,
  ZonesTab,
  SettingsTab,
  LocalitiesTab
} from './components';

interface Props {}

export enum SettingTabList {
  VEHICLE_TYPES = 'vehicle-types',
  USERS = 'users',
  USER_TYPES = 'user-types',
  ZONES = 'zones',
  SETTINGS = 'settings',
  LOCALITIES = 'localities'
}

export const Settings: React.FC<Props> = () => {
  const tabs = [
    { label: 'Vehicle Types', value: SettingTabList.VEHICLE_TYPES },
    { label: 'Users', value: SettingTabList.USERS },
    { label: 'User Types', value: SettingTabList.USER_TYPES },
    { label: 'Zones', value: SettingTabList.ZONES },
    { label: 'Settings', value: SettingTabList.SETTINGS },
    { label: 'Localities', value:SettingTabList.LOCALITIES}
  ];

  return (
    <AppTabs tabs={tabs}>
      {(activeTab) => {
        switch (activeTab) {
          case SettingTabList.VEHICLE_TYPES:
            return <VehicleTypesTab />;
          case SettingTabList.USERS:
            return <UsersTab />;
          case SettingTabList.USER_TYPES:
            return <UserTypesTab />;
          case SettingTabList.ZONES:
            return <ZonesTab />;
          case SettingTabList.SETTINGS:
            return <SettingsTab />;
          case SettingTabList.LOCALITIES:
            return <LocalitiesTab />;
          default:
        }
      }}
    </AppTabs>
  );
};
